/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

import { KeyedObject } from '../constants/misc';
import { Language } from '../constants/preferences';

/**
 * gets a value at the key of data, if not found returns the defaultValue
 * @param {KeyedObject} data
 * @param {string} key
 * @param {any} defaultValue
 * @returns {any}
 */
const getString = (data: KeyedObject, key: string, defaultValue: any): any => {
    const keyParts = key.split('.');
    let currentPosition = data;

    for (let i = 0; i < keyParts.length; i++) {
        if (currentPosition) {
            currentPosition = currentPosition[keyParts[i]];
        }
    }

    return currentPosition ? currentPosition : defaultValue;
};

/**
 * interpolates the string to replace {keys} in the text with properties in params
 * @param {string} text
 * @param {KeyedObject} [params]
 * @returns {any}
 */
export const interpolateString = (text: string, params?: KeyedObject): any => {
    if (!params) {
        return text;
    }

    const children = text.split(/({[^}]+})/g).map((child) => {
        const match = /{(.+)}/g.exec(child);
        if (match) {
            const param = params[match[1]];
            return param ? param : String(param);
        }
        return child;
    });

    return children.some((child) => child && typeof child === 'object')
        ? React.createElement('span', null, ...children)
        : children.join('');
};

/**
 * returns the interpolated key from the translation language
 * @param {KeyedObject} translations
 * @param {Language} language
 * @param {string} key
 * @param {KeyedObject} [params]
 * @returns {string}
 */
export const getTranslation = (
    translations: KeyedObject,
    language: Language,
    key: string,
    params?: KeyedObject
): string => {
    let out = key;

    if (translations) {
        const languageStrings = translations[language];

        if (languageStrings) {
            const keyString = getString(languageStrings, key, null);

            if (keyString) {
                out = interpolateString(keyString, params);
            }
        }
    }

    return out;
};
