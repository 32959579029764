/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum AppRoute {
    About = '/about',
    Admin = '/admin/users',
    AdminInvite = '/admin/users/add',
    Athlete = '/athlete/:id',
    AthleteGallery = '/athlete/:id/gallery/:mediaId',
    AthleteProfile = '/profile/athlete',
    AthleteProfileAdmin = '/profile/athlete/:id',
    AthleteProfileSupport = '/profile/athlete/support/:id',
    Credit = '/market/credits',
    Fan = '/fan/:id',
    FanGallery = '/fan/:id/gallery/:mediaId',
    FanProfile = '/profile/fan',
    FanProfileAdmin = '/profile/fan/:id',
    Index = '/',
    Invite = '/register/:token',
    Manager = '/manager/:id',
    ManagerGallery = '/manager/:id/gallery/:mediaId',
    ManagerProfile = '/profile/manager',
    ManagerProfileAdmin = '/profile/manager/:id',
    Market = '/market',
    NotFound = '/not_found',
    PaymentSuccess = '/payment/success',
    Product = '/sponsor/product/:id',
    ResetPassword = '/reset-password/:token',
    Sponsor = '/sponsor/:id',
    SponsorGallery = '/sponsor/:id/gallery/:mediaId',
    SponsorProfile = '/profile/sponsor',
    SponsorProfileAdmin = '/profile/sponsor/:id',
    Sportest = '/sportest',
    SportNetwork = '/rede-sportrack',
    SportNetworkSearch = '/rede-sportrack/:q',
    ValidateAcount = '/validate-account/:token',
}
