/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType, createContext, FC } from 'react';

import { ConnectedGeneralController, GeneralController } from './GeneralController';

export interface GeneralContext extends Omit<InstanceType<typeof GeneralController>, keyof React.Component> { }

const generalContextInstance = createContext<GeneralContext | null>(null);

export const GeneralContextProvider = generalContextInstance.Provider;
export const GeneralContextConsumer = generalContextInstance.Consumer;

export const withGeneralContext = <P, >(Component: ComponentType<P>): FC<Omit<P, keyof GeneralContext>> => (props) => (
    <ConnectedGeneralController>
        <GeneralContextConsumer>{(ctx) => <Component {...(props as P)} {...ctx} />}</GeneralContextConsumer>
    </ConnectedGeneralController>
);
