/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'admins';

/**
 * Admin invite URL
 * @returns {string}
 */
export const adminInviteURL = (): string => {
    return `${API_URL}/${api}/finalize-invitation`;
};

/**
 * Admin URL
 * @returns {string}
 */
export const adminURL = (id: React.Key): string => {
    return `${API_URL}/${api}/${id}`;
};
