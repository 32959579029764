/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../controllers/user/UserContext';
import iconPin from '../../assets/images/icon_pin.svg';
import iconShieldCheck from '../../assets/images/icon_shield_check.svg';
import iconWeight from '../../assets/images/icon_weight.svg';
import iconHeight from '../../assets/images/icon_height.svg';
import iconBirthday from '../../assets/images/icon_birthday.svg';
import badgeStar from '../../assets/images/badge_star.svg';
import defaultAvatar from '../../assets/images/avatar_default.svg';
import DrawerGallery from '../elements/DrawerGallery';
import Avatar from '../elements/Avatar';
import defaultCover from '../../assets/images/athlete_header_bg.png';
import {
    MatchParams,
    Media,
} from '../../constants/misc';
import ProfileError from '../elements/ProfileError';
import { AppRoute } from '../../constants/routes';
import {
    Badge, Club, User,
} from '../../types/user';
import { Timeline } from '../elements/Timeline';
import { Sportest } from '../../constants/sportest';
import { SportTestBadge } from '../elements/SportTestBadge';
import { Achievement } from '../../constants/achievements';
import { buildRoute, sortAchievementsByDate } from '../../utils/misc';
import iconBtnSupport from '../../assets/images/icon_btn_support.png';
import imgSupportAthlete from '../../assets/images/support_athletes.png';
import ModalAthleteSupport from '../elements/AthleteSupportModal';
import { supportsDefault } from '../../constants/support';
import { displayError } from '../../utils/notifications';
import { SponsorContext, withSponsorContext } from '../controllers/sponsor/SponsorContext';
import Badges from '../elements/Badges';
import NewAchievementForm from '../elements/NewAchievementForm';
import { SponsorAthletesSupport } from '../../constants/sponsor';
import SponsorshipManagerTable from '../elements/SponsorshipManagerTable';
import { TableActionType } from '../../utils/table';
import { WS } from '../../utils/websocket';
import { SocketEventType } from '../../types/websockets';
import AthletePendingSupportModal from '../elements/AthletePendingSupportModal';
import ProfileButtons from '../elements/ProfileButtons';

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext, UserContext, SponsorContext {}

type Props = OwnProps;

interface OwnState {
    showGallery: boolean;
    coverPhoto: Media | null;
    avatar: Media | null;
    biography: string | null;
    club: string | null;
    dateOfBirth: string | null;
    name: string;
    lastName: string | null;
    sport: string | null;
    height: number;
    address: string | null;
    mediaList: any;
    objectives: string | null;
    phrase: string | null;
    sportingEvents: any;
    weight: number;
    linkURL: string;
    error: boolean;
    achievements: Array<Achievement>;
    userData: User | null;
    lastSportest: Sportest | null;
    sponsors: Badge[];
    showModalSupport: boolean;
    athleteId: string;
    isFetching: boolean;
    showSupportBanner: boolean;
    sponsoredAthletes: Badge[];
    isFetchingGallery: boolean;
    isScrolling: boolean;
    athletesSupportList: SponsorAthletesSupport[];
    paramsAthletesSupport: Record<string, number>;
    showNewAchievementForm: boolean;
    totalAthletesSupport: number;
    paramsSupportsAthlete: Record<string, number>;
    supportsAthleteList: SponsorAthletesSupport[];
    totalSupportsAthlete: number;
}

type State = OwnState;

const initialState: State = {
    showGallery: false,
    coverPhoto: null,
    avatar: null,
    biography: null,
    club: '',
    dateOfBirth: null,
    name: '',
    lastName: '',
    sport: null,
    height: 0,
    address: '',
    mediaList: [],
    objectives: '',
    phrase: '',
    sportingEvents: [],
    weight: 0,
    linkURL: '',
    error: false,
    achievements: [],
    userData: null,
    lastSportest: null,
    sponsors: [],
    showModalSupport: false,
    athleteId: '',
    isFetching: true,
    showSupportBanner: false,
    sponsoredAthletes: [],
    isFetchingGallery: false,
    isScrolling: false,
    athletesSupportList: [],
    paramsAthletesSupport: {
        _limit: 10,
        _page: 0,
    },
    showNewAchievementForm: false,
    totalAthletesSupport: 0,
    paramsSupportsAthlete: {
        _limit: 10,
        _page: 0,
    },
    supportsAthleteList: [],
    totalSupportsAthlete: 0,
};

class AthleteScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount() {
        const { match, isAuthenticated } = this.props;

        window.scrollTo(0, 0);

        if (match && match.params && match.params.id) {
            this.prepare(match.params.id);
        }

        if (isAuthenticated) {
            WS.addHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const {
            match, user, pendingAthleteSupporters, isAuthenticated,
        } = this.props;
        const { user: prevUser, pendingAthleteSupporters: prevPendingAthleteSupporters, isAuthenticated: prevIsAuthenticated } = prevProps;
        
        if (match && match.params && typeof match.params.id === 'string') {
            if (prevProps.match.params.id !== match.params.id) {
                this.prepare(match.params.id);
            }
        }

        if (user?.availableCredits !== prevUser?.availableCredits || pendingAthleteSupporters !== prevPendingAthleteSupporters) {
            this.fetchAllSupportData();
        }

        if (isAuthenticated && !prevIsAuthenticated) {
            WS.addHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
        }
    }

    componentWillUnmount() {
        WS.removeHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
    }
    
    onAcceptSupport = () => {
        const { getUser, user, updateUserWallet } = this.props;

        this.fetchSupportsAthlete();

        if (!user) return;

        getUser(user.id).then((userData) => {
            this.setState({
                userData,
            });

            updateUserWallet(userData?.availableCredits || 0);
        });
    }

    onEditClick = () => {
        const { history } = this.props;
        
        history.push(AppRoute.AthleteProfile);
    }

    /**
     * handles gallery toggle click
     */
    onToggleGallery = (): void => {
        const { showGallery } = this.state;

        this.setState({
            showGallery: !showGallery,
        });
    };

    onPageChangeAthletesSupport = (evt: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        const { paramsAthletesSupport } = this.state;

        this.setState(
            {
                paramsAthletesSupport: { ...paramsAthletesSupport, _page: page },
            },
            this.fetchAthletesSupport,
        );
    };

    onRowsPerPageChangeAthletesSupport = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { paramsAthletesSupport } = this.state;

        this.setState(
            {
                paramsAthletesSupport: { ...paramsAthletesSupport, _limit: Number(evt.target.value) },
            },
            this.fetchAthletesSupport,
        );
    };

    onPageChangeSupportsAthlete = (evt: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        const { paramsSupportsAthlete } = this.state;

        this.setState(
            {
                paramsSupportsAthlete: { ...paramsSupportsAthlete, _page: page },
            },
            this.fetchSupportsAthlete,
        );
    };

    onRowsPerPageChangeSupportsAthlete = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { paramsSupportsAthlete } = this.state;

        this.setState(
            {
                paramsSupportsAthlete: { ...paramsSupportsAthlete, _limit: Number(evt.target.value) },
            },
            this.fetchSupportsAthlete,
        );
    };

    onShowSupportModal = () => {
        const { user, t } = this.props;
        if (!user) return displayError({ message: t('athlete.supportNotLoggedIn') });
        this.setState({ showModalSupport: true });
    }

    handleSupportUpdate = ({ payload: athleteSupportEvent }: {payload: Partial<SponsorAthletesSupport>}) => {
        const { athletesSupportList } = this.state;
        
        const supportElement = athletesSupportList.find((el) => el.id === athleteSupportEvent.id);
        
        if (!supportElement) {
            return;
        }

        const auxList = athletesSupportList.map((el) => {
            if (el.id === supportElement.id) return { ...el, ...athleteSupportEvent };

            return el;
        });

        this.setState({
            athletesSupportList: auxList,
        });
    }

    checkSupportBannerShouldBeShown = (sponsors: Badge[]) => {
        sponsors.forEach((sponsor) => {
            return Object.values(supportsDefault).forEach((supportDefaultId) => {
                if (sponsor.id === supportDefaultId) return this.setState({ showSupportBanner: true });
            });
        });
    }

    deleteAchievement = (id: number): void => {
        const { deleteAchievement } = this.props;
        deleteAchievement(id, () => { }, this.popAchievement);
    };

    fetchAthletesSupport = () => {
        const { paramsAthletesSupport } = this.state;
        const { getAthletesSupport } = this.props;

        getAthletesSupport({ ...paramsAthletesSupport }).then(({ list, total }) => {
            this.setState({
                athletesSupportList: list,
                totalAthletesSupport: total,
            });
        });
    };

    fetchSupportsAthlete = () => {
        const { paramsSupportsAthlete } = this.state;
        const { getSupportsAthlete } = this.props;

        getSupportsAthlete({ ...paramsSupportsAthlete }).then(({ list, total }) => {
            this.setState({
                supportsAthleteList: list,
                totalSupportsAthlete: total,
                isFetchingGallery: true,
            });
        });
    };

    fetchAllSupportData = () => {
        this.fetchAthletesSupport();
        this.fetchSupportsAthlete();
    };

    parseYears = (milliseconds: string): number => {
        return moment().diff(moment(milliseconds), 'years', false);
    };

    popAchievement = (id: number): void => {
        const { achievements } = this.state;
        this.setState({ achievements: [...achievements].filter((achievement) => achievement.id !== id) });
    };

    prepare = async (id: string): Promise<void> => {
        const {
            user, getSportests, getUser, getSponsoredAthletesBadges, getSponsorsBadges,
        } = this.props;

        const promises: [Promise<Sportest[]>, Promise<User | null>, Promise<Badge[]>, Promise<Badge[]>] = [
            getSportests(id),
            getUser(id),
            getSponsorsBadges(id),
            getSponsoredAthletesBadges(id),
        ];
    
        let res = [];
        res = await Promise.all(promises);
        const [sportests, userResponse, sponsorsResponse, sponsoredAthletes] = res;

        if (userResponse) {
            this.setState({ isFetching: false });
        } else {
            this.setState({
                error: true,
                isFetching: false,
            });
            return;
        }

        this.checkSupportBannerShouldBeShown(sponsorsResponse);

        if (userResponse && userResponse !== null) {
            const {
                coverPhoto, avatar, biography, club, dateOfBirth, achievements,
                name, lastName, height, address, objectives, phrase, weight,
            } = userResponse;

            this.setState({
                coverPhoto,
                avatar,
                biography,
                club: club ? club.clubCompositeKey.clubName : null,
                dateOfBirth,
                name,
                lastName,
                sport: club ? club.clubCompositeKey.sport : null,
                height,
                address,
                objectives,
                phrase,
                weight,
                achievements: achievements ? sortAchievementsByDate(achievements) : [],
                userData: userResponse,
                linkURL: buildRoute(AppRoute.Athlete, { id }),
                lastSportest: sportests.pop() || null,
                sponsors: sponsorsResponse,
                athleteId: id,
                sponsoredAthletes,
            });
        }

        if (user?.id === userResponse.id) {
            this.fetchAllSupportData();
        }
    };

    /**
     * returns the sport test badge
     */
    renderSportTestBadge(): React.ReactNode {
        const {
            t, user, openTestInfo, getSportests, match: { params: { id } },
        } = this.props;
        const { lastSportest } = this.state;

        const onSportTestFinished = (): void => {
            if (!id) return;
            getSportests(id).then((sportests) => this.setState({ lastSportest: sportests.pop() || null }));
        };

        return String(user?.id) === id ? (
            <button type="button" onClick={(): void => openTestInfo(onSportTestFinished)} aria-label={t('badge.score')}>
                <SportTestBadge sportTest={lastSportest} />
            </button>
        ) : (<SportTestBadge sportTest={lastSportest} />);
    }

    /**
     * returns the athlete stats
     */
    renderAthleteStats(): React.ReactNode {
        const { t } = this.props;
        const {
            address, club, weight, height, dateOfBirth,
        } = this.state;

        return (
            <ul className="profile-stats profile-stats--athlete">
                {address && (
                    <li>
                        <div className="icn">
                            <img src={iconPin} alt="" />
                        </div>
                        <p>{address}</p>
                    </li>
                )}
                {club && (
                    <li>
                        <div className="icn">
                            <img src={iconShieldCheck} alt="" />
                        </div>
                        {club}
                    </li>
                )}
                {weight > 0 && (
                    <li>
                        <div className="icn">
                            <img src={iconWeight} alt="" />
                        </div>
                        {weight} Kg
                    </li>
                )}
                {height > 0 && (
                    <li>
                        <div className="icn">
                            <img src={iconHeight} alt="" />
                        </div>
                        {height / 100} m
                    </li>
                )}
                {dateOfBirth && (
                    <li>
                        <div className="icn">
                            <img src={iconBirthday} alt="birthday icon" />
                        </div>
                        {this.parseYears(dateOfBirth)} {t('athlete.years')}
                    </li>
                )}
            </ul>
        );
    }
    
    renderClubs = () => {
        const { achievements } = this.state;

        const clubs = achievements.map((achievement) => (achievement.club as Club).clubCompositeKey.clubName);

        const clubsDistinct = clubs.reduce((newArray, club) => (newArray.includes(club) ? newArray : [...newArray, club]), [] as string[]);
        return clubsDistinct.map((club) => (
            <div key={club} className="profile-info__club">
                <div className="profile-info__club__logo">
                    <img src={iconShieldCheck} alt="club logo" />
                </div>
                <span>{club}</span>
            </div>
        ));
    };

    renderSupportedAthletesTable = () => {
        const { athletesSupportList, paramsAthletesSupport, totalAthletesSupport } = this.state;

        return (
            <SponsorshipManagerTable
                totalAthletesSupport={totalAthletesSupport}
                athletesSupportList={athletesSupportList}
                onPageChange={this.onPageChangeAthletesSupport}
                onRowsPerPageChange={this.onRowsPerPageChangeAthletesSupport}
                params={paramsAthletesSupport}
                fetchData={this.fetchAthletesSupport}
                actionButtons={[TableActionType.CANCEL]}
            />
        );
    }

    renderSupportersTable = () => {
        const { paramsSupportsAthlete, supportsAthleteList, totalSupportsAthlete } = this.state;

        return (
            <SponsorshipManagerTable
                totalAthletesSupport={totalSupportsAthlete}
                athletesSupportList={supportsAthleteList}
                onPageChange={this.onPageChangeSupportsAthlete}
                onRowsPerPageChange={this.onRowsPerPageChangeSupportsAthlete}
                params={paramsSupportsAthlete}
                fetchData={this.onAcceptSupport}
                actionButtons={[TableActionType.ACCEPT, TableActionType.REJECT]}
            />
        );
    }

    render(): React.ReactNode {
        const {
            avatar,
            coverPhoto,
            showGallery,
            name,
            lastName,
            sport,
            phrase,
            biography,
            objectives,
            linkURL,
            error,
            userData,
            achievements,
            sponsors,
            showModalSupport,
            athleteId,
            isFetching,
            isFetchingGallery,
            showSupportBanner,
            sponsoredAthletes,
            showNewAchievementForm,
            athletesSupportList,
            supportsAthleteList,
        } = this.state;
        const { t, user, pendingAthleteSupporters } = this.props;

        const itsMe = user?.id === userData?.id;

        const currentCover = coverPhoto?.main || defaultCover;
        const currentAvatar = avatar?.main || null;
        
        return (
            <div className="profile-screen" data-testid="athlete-screen">
                <ProfileError show={error} close={() => this.setState({ error: false })} />
                <div className="profile-screen__body">
                    <div className="profile-screen__body__left">
                        <div className="profile-screen__body__left__header">
                            <div className="cover">
                                <div className="cover__image" style={{ backgroundImage: `url(${currentCover})` }} />
                            </div>
                            <ProfileButtons
                                onEditClick={this.onEditClick}
                                linkToShare={linkURL}
                                user={userData}
                                userOwner={itsMe}
                            />
                            <Avatar image={currentAvatar || defaultAvatar} badge={badgeStar} />
                            <button
                                type="button"
                                className="btn btn--primary-dark gallery-btn"
                                onClick={this.onToggleGallery}
                                data-testid="gallery-toggle"
                            >
                                {t('athlete.galery')}
                            </button>
                        </div>
                        {!showGallery ? (
                            <div className="profile-screen__body__left__content">
                                <div className="profile-top">
                                    <div className="athlete-info">
                                        <h1>
                                            {name} {lastName}
                                        </h1>
                                        <h2>{sport ? t(`sports.${sport}`) : ''}</h2>
                                    </div>
                                    <div className="hidden-sm hidden-md hidden-lg">{this.renderSportTestBadge()}</div>
                                    <div className="hidden-xs profile-top__stats">
                                        {this.renderAthleteStats()}
                                        <div>
                                            {this.renderSportTestBadge()}
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <div className="row hidden-sm hidden-md hidden-lg">
                                        <div className="col-sm-12">{this.renderAthleteStats()}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('athlete.about')}</h3>
                                                <h4>{phrase}</h4>
                                                <p>{biography || t('general.noInfo')}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('athlete.myGoals')}</h3>
                                                <p>{objectives || t('general.noInfo')}</p>
                                            </div>
                                            <div className="content-box">
                                                <div className="content-box__with-button">
                                                    <h3 className="title">{t('athlete.supporters')}</h3>
                                                    {user?.id !== athleteId && (
                                                        <button type="button" className="btn btn--purple" onClick={this.onShowSupportModal}>
                                                            <img src={iconBtnSupport} alt="" />
                                                            {t('profile.support')}
                                                        </button>
                                                    )}
                                                </div>
                                                {!isFetching && showSupportBanner && user?.id !== athleteId && (
                                                    <div className="content-box__gradient">
                                                        <div className="content-box__gradient__info">
                                                            <h3 className="content-box__gradient__info--title">{t('profile.sponsorsSection.title')}</h3>
                                                            <h3 className="content-box__gradient__info--info">{t('profile.sponsorsSection.info')}</h3>
                                                            <button
                                                                type="button"
                                                                className="content-box__gradient__info--call-to-action"
                                                                onClick={this.onShowSupportModal}
                                                            >
                                                                {t('profile.sponsorsSection.callToAction')}
                                                            </button>
                                                        </div>
                                                        <div className="content-box__gradient__image">
                                                            <img src={imgSupportAthlete} alt="" />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="content-box__item content-box__item--sponsored">
                                                    {sponsors.map((sponsor) => (
                                                        <Badges
                                                            key={sponsor.id}
                                                            userId={sponsor.id}
                                                            userRole={sponsor.role}
                                                            badgeId={sponsor.badgeUrl.id}
                                                            badgeUrl={sponsor.badgeUrl.main}
                                                            refreshScreen={this.prepare}
                                                        />
                                                    ))}
                                                </div>
                                                {sponsoredAthletes.length > 0
                                                && (
                                                    <div className="content-box">
                                                        <div className="content-box__with-button">
                                                            <h3 className="title">{t('athlete.supportedAthletes')}</h3>
                                                        </div>
                                                        <div className="content-box__item content-box__item--sponsored">
                                                            {sponsoredAthletes.map((sponsoredAthlete) => (
                                                                <Badges
                                                                    key={sponsoredAthlete.id}
                                                                    userId={sponsoredAthlete.id}
                                                                    userRole={sponsoredAthlete.role}
                                                                    badgeId={sponsoredAthlete.badgeUrl.id}
                                                                    badgeUrl={sponsoredAthlete.badgeUrl.main}
                                                                    refreshScreen={this.prepare}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <h3 className="title">{t('profile.course')}</h3>
                                            {
                                                achievements.length > 0 ? (
                                                    <Timeline
                                                        achievements={achievements}
                                                        onAdd={itsMe ? (): void => this.setState({ showNewAchievementForm: true }) : undefined}
                                                        onDelete={itsMe ? this.deleteAchievement : undefined}
                                                    />
                                                ) : <p className="placeholder">{t('general.noContent')}</p>
                                            }
                                        </div>
                                        {itsMe && (
                                            <div className="col-lg-12">
                                                <h3 className="title">{t('profile.clubs')}</h3>
                                                {this.renderClubs()}
                                            </div>
                                        )}
                                        {(itsMe && athletesSupportList.length > 0) && (
                                            <div className="col-lg-12 col-md-12">
                                                <div className="content-box">
                                                    <h3 className="title">{t('profile.sponsorshipManager.mySupportedAthletes')}</h3>
                                                    {this.renderSupportedAthletesTable()}
                                                </div>
                                            </div>
                                        )}
                                        {(itsMe && supportsAthleteList.length > 0) && (
                                            <div className="col-lg-12 col-md-12">
                                                <div className="content-box">
                                                    <h3 className="title">{t('profile.sponsorshipManager.mySupporters')}</h3>
                                                    {this.renderSupportersTable()}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="profile-screen__body__left__gallery" data-testid="mobile-gallery">
                                <DrawerGallery
                                    galleryUser={userData}
                                    canEdit={itsMe}
                                    reFetchGallery={isFetchingGallery}
                                    onReFetchGallery={() => this.setState({ isFetchingGallery: false })}
                                />
                            </div>
                        )}
                    </div>
                    <div className="profile-screen__body__right">
                        <DrawerGallery
                            galleryUser={userData}
                            canEdit={itsMe}
                            reFetchGallery={isFetchingGallery}
                            onReFetchGallery={() => this.setState({ isFetchingGallery: false })}
                        />
                    </div>
                </div>
                {showNewAchievementForm && (
                    <NewAchievementForm
                        close={() => this.setState({ showNewAchievementForm: false })}
                        sport={sport || ''}
                        isFetching={false}
                        addToList={(newAchievement: Achievement): void => {
                            this.setState({
                                achievements: sortAchievementsByDate([...achievements, newAchievement]),
                                showNewAchievementForm: false,
                            });
                        }}
                    />
                )}
                {showModalSupport && (
                    <ModalAthleteSupport
                        fullNameAthlete={`${name} ${lastName}`}
                        avatarAthlete={currentAvatar || defaultAvatar}
                        onClose={() => this.setState({ showModalSupport: false })}
                        athleteId={athleteId}
                    />
                )}
                {!!pendingAthleteSupporters.total && (
                    <AthletePendingSupportModal />
                )}

            </div>
        );
    }
}

export default withAuthenticationContext(withSponsorContext(withUserContext(withTranslationContext(AthleteScreen))));
