/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'sportest';

/**
 * Sportests URL
 * @returns {string}
 */
export const sportestsURL = (): string => {
    return `${API_URL}/${api}`;
};

/**
 * Sportest URL
 * @returns {string}
 */
export const sportestURL = (id: string | number): string => {
    return `${API_URL}/${api}/${id}`;
};
