/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 */
interface OwnProps {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
const IconNext: FunctionComponent<Props> = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="10" viewBox="0 0 5 10">
            <path
                d="M4.646 4.646a.5.5 0 010 .707L.854 9.146A.5.5 0 010 8.793V1.207A.5.5 0 01.854.853z"
                fill="#757575"
            />
        </svg>
    );
};

export default IconNext;
