/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import { PendingAthleteSupporters } from '../constants/sponsor';
import { AthleteSupporterAction, AthleteSupporterActionTypes } from './athlete_supporter_types';

export const setPendingAthleteSupporters = (pendingSupporters: PendingAthleteSupporters): AthleteSupporterActionTypes => {
    return {
        type: AthleteSupporterAction.SET_PENDING_ATHLETE_SUPPORTERS,
        payload: pendingSupporters,
    };
};

export const resetPendingAthleteSupporters = (): AthleteSupporterActionTypes => {
    return {
        type: AthleteSupporterAction.RESET_PENDING_ATHLETE_SUPPORTERS,
    };
};
