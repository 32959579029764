/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';

import { SportestContextProvider } from './SportestContext';
import { AppState } from '../../../reducers/types';
import { sportestAnswersURL } from '../../../services/sportest_answers';
import { validateForm, ValidationType } from '../../../utils/validations';
import {
    addSportestActionCreator,
    requestCreateSportest,
    clearSportestsActionCreator,
} from '../../../actions/sportest';
import { KeyedObject } from '../../../constants/misc';
import { SportestCreate, SPORTEST_TYPES } from '../../../constants/sportest';

interface StateProps {
    pendingSportests: SportestCreate[];
    createSportestFetching: boolean;
}

interface DispatchProps {
    dispatchRequestCreateSportest: Function;
    dispatchAddSportest: Function;
    dispatchClearSportests: Function;
}

interface OwnProps {
    children: any;
}

interface OwnState {}

type Props = OwnProps & StateProps & DispatchProps;
type State = OwnState;

export class SportestController extends Component<Props, State> {
    // Requests

    getAnswers = async () => {
        try {
            const { data } = await axios.get(sportestAnswersURL());
            return data;
        } catch {
            return null;
        }
    };

    // Create sportest

    getSportestValidations = (testType: SPORTEST_TYPES): Record<string, unknown> => {
        switch (testType) {
            case SPORTEST_TYPES.ATHLETE:
                return {
                    athleteBestResultEventAnswerId: {
                        validations: [ValidationType.NotBlank],
                    },
                    athleteBestResultPositionAnswerId: {
                        validations: [ValidationType.NotBlank],
                    },
                    athleteBudgetAnswerId: {
                        validations: [ValidationType.NotBlank],
                    },
                    athleteModalityAnswerId: {
                        validations: [ValidationType.NotBlank],
                    },
                    sportestType: {
                        validations: [ValidationType.NotBlank],
                    },
                };
            case SPORTEST_TYPES.FAN:
                return {
                    fanInterest: {
                        validations: [ValidationType.NotBlank],
                    },
                    fanModalityAnswerId: {
                        validations: [ValidationType.NotBlank],
                    },
                    sportestType: {
                        validations: [ValidationType.NotBlank],
                    },
                };
            case SPORTEST_TYPES.SPONSOR:
                return {
                    sponsorEntityType: {
                        validations: [ValidationType.NotBlank],
                    },
                    sponsorModalityAnswerId: {
                        validations: [ValidationType.NotBlank],
                    },
                    sponsorSponsorshipBudgetAnswerId: {
                        validations: [ValidationType.NotBlank],
                    },
                    sponsorSponsorshipPackAnswerId: {
                        validations: [ValidationType.NotBlank],
                    },
                    sportestType: {
                        validations: [ValidationType.NotBlank],
                    },
                };
            default:
                return {};
        }
    };

    validateNewSportest = (fields: SportestCreate): KeyedObject | null => {
        const validations = this.getSportestValidations(fields.sportestType);
        const errors: KeyedObject | null = validateForm(fields, validations);

        if (!errors || Object.keys(errors).length === 0) return null;
        return errors;
    };

    submitNewSportest = (payload: SportestCreate, onSuccess: Function, onFailure: Function) => {
        const { dispatchRequestCreateSportest } = this.props;

        const filteredPayload: KeyedObject = {
            sportestType: payload.sportestType,
        };
        switch (payload.sportestType) {
            case SPORTEST_TYPES.ATHLETE:
                filteredPayload.athleteBestResultEventAnswerId = payload.athleteBestResultEventAnswerId?.value;
                filteredPayload.athleteBestResultPositionAnswerId = payload.athleteBestResultPositionAnswerId?.value;
                filteredPayload.athleteBudgetAnswerId = payload.athleteBudgetAnswerId?.value;
                filteredPayload.athleteModalityAnswerId = payload.athleteModalityAnswerId?.value;
                break;
            case SPORTEST_TYPES.FAN:
                filteredPayload.fanInterest = payload.fanInterest;
                filteredPayload.fanModalityAnswerId = payload.fanModalityAnswerId?.value;
                break;
            case SPORTEST_TYPES.SPONSOR:
                filteredPayload.sponsorEntityType = payload.sponsorEntityType?.value;
                filteredPayload.sponsorModalityAnswerId = payload.sponsorModalityAnswerId?.value;
                filteredPayload.sponsorSponsorshipBudgetAnswerId = payload.sponsorSponsorshipBudgetAnswerId?.value;
                filteredPayload.sponsorSponsorshipPackAnswerId = payload.sponsorSponsorshipPackAnswerId?.value;
                break;
        }
        dispatchRequestCreateSportest(filteredPayload, onSuccess, onFailure);
    };

    addSportest = (sportest: SportestCreate) => {
        const { dispatchAddSportest } = this.props;

        dispatchAddSportest(sportest);
    };

    submitPedingSportests = () => {
        const { pendingSportests, dispatchClearSportests } = this.props;
        pendingSportests.forEach((sportest) => this.submitNewSportest(
            sportest,
            () => {},
            () => {},
        ));
        dispatchClearSportests();
    };

    render() {
        const { children, createSportestFetching, pendingSportests } = this.props;

        return (
            <SportestContextProvider
                value={{
                    pendingSportests,
                    createSportestFetching,
                    getAnswers: this.getAnswers,
                    validateNewSportest: this.validateNewSportest,
                    submitNewSportest: this.submitNewSportest,
                    addSportest: this.addSportest,
                    submitPendingSportests: this.submitPedingSportests,
                }}
            >
                {children}
            </SportestContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        pendingSportests: state.sportest.pendingSportests,
        createSportestFetching: state.sportest.isFetching,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    dispatchRequestCreateSportest: (payload: FormData, onSuccess: Function, onFailure: Function) => dispatch(requestCreateSportest(payload, onSuccess, onFailure)),
    dispatchAddSportest: (payload: SportestCreate) => dispatch(addSportestActionCreator(payload)),
    dispatchClearSportests: () => dispatch(clearSportestsActionCreator()),
});

export const ConnectedSportestController = connect(mapStateToProps, mapDispatchToProps)(SportestController);
