/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

interface OwnProps {
    children: React.ReactNode;
    extraClasses?: string;
    isSubmit?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    ariaLabel?: string;
}

const Button: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        children,
        isSubmit,
        isLoading,
        isDisabled,
        onClick,
        extraClasses = '',
        ariaLabel,
    } = props;

    return (
        <button
            type={isSubmit ? 'submit' : 'button'}
            className={`btn ${extraClasses}`}
            disabled={isDisabled || isLoading}
            onClick={onClick}
            aria-label={ariaLabel}
        >
            {isLoading ? <span className="loader" aria-label="button loader" /> : children}
        </button>
    );
};

export { Button };
