/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { store } from 'react-notifications-component';
import 'animate.css/animate.css';
import 'react-notifications-component/dist/theme.css';

/**
 * NotificationType
 * @enum {string}
 */
export enum NotificationType {
    Success = 'success',
    Danger = 'danger',
    Warning = 'warning',
    Info = 'info',
}

/**
 * @typedef {Object} NotificationData
 * @property {string} [title]
 * @property {string} [message]
 * @property {NotificationType} [type]
 * @property {string} [date]
 */
export interface NotificationData {
    title?: string;
    message?: string;
    type?: NotificationType;
    date?: string;
}

/**
 * displays an error notification
 * @param {NotificationData} data
 */
export const displayError = (data: NotificationData) => {
    const message = (data && data.message) ? data.message : 'Unknown Error';

    displayNotification({
        type: NotificationType.Danger,
        message,
    });
};

/**
 * displays a success notification
 * @param {NotificationData} data
 */
export const displaySuccess = (data: NotificationData) => {
    const message = (data && data.message) ? data.message : '';

    displayNotification({
        type: NotificationType.Success,
        message,
    });
};

/**
 * displays a custom notification
 * @param {NotificationData} data
 */
export const displayNotification = (data: NotificationData) => {
    const notification = {
        message: data.message,
        type: data.type,
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
            duration: 3000
        }
    };

    store.addNotification({
        ...notification,
    });
};
