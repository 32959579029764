/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'sportestAnswerOption';

/**
 * Sportest Answers URL
 * @returns {string}
 */
export const sportestAnswersURL = (): string => {
    return `${API_URL}/${api}`;
};

/**
 * Sportest Answer URL
 * @returns {string}
 */
export const sportestAnswerURL = (id: string | number): string => {
    return `${API_URL}/${api}/${id}`;
};
