/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';

import Themed from './containers/Themed';
import IndexScreen from './screens/IndexScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import { AppRoute } from '../constants/routes';
import TopBar from './elements/TopBar';

export default () => (
    <div className="app-router">
        <Themed>
            <ReactNotification />
            <TopBar />
            <Switch>
                <Route exact path={AppRoute.NotFound} component={NotFoundScreen} />
                <Switch>
                    <Route path={AppRoute.Index} component={IndexScreen} />
                </Switch>
            </Switch>
        </Themed>
    </div>
);
