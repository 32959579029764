/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/logo_white.svg';
import runningImage from '../../assets/images/running.png';
import modalSlant from '../../assets/images/modal_slant.svg';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { TabType } from '../../utils/misc';
import TermsAndConditionsModal from './TermsAndConditionsModal';

interface Props {
    closeRegistration: () => void;
    agreeWithTerm: boolean;
    onCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isFetching: boolean;
    children: React.ReactNode;
}
type OwnProps = TranslationContext & Props;

const RegistrationScreenWrapper: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        onCheckChange,
        isFetching,
        agreeWithTerm,
        closeRegistration,
        children,
    } = props;

    const [showTermsAndConditions, setShowTermsAndConditions] = useState<boolean>(false);

    return (
        <div className="modal registration-screen" data-testid="registration-screen">
            <div className="modal__backdrop" />
            <div className="modal__content">
                <button
                    type="button"
                    className="modal__content__close"
                    data-testid="close-modal"
                    onClick={() => closeRegistration()}
                >
                    <IconTimes fill="#FFF" />
                </button>
                <div className="modal__content__top">
                    <div className="modal__content__top__header">
                        <img src={logo} className="logo" alt="" />
                        <img src={runningImage} className="running" alt="" />
                        <img src={modalSlant} className="slant" alt="" />
                    </div>
                    <div className="modal__content__top__subheader">
                        <h1>{t('registration.title')}</h1>
                        <p>{t('registration.description')}</p>
                    </div>
                </div>
                {children}
                <div className="modal__content__bottom">
                    <label className="control control--checkbox">
                        <input
                            name="agree"
                            type="checkbox"
                            checked={agreeWithTerm}
                            onChange={onCheckChange}
                            disabled={isFetching}
                        />
                        <div className="indicator" />
                        <p>{t('registration.read')}&nbsp;</p>
                        <button type="button" onClick={() => setShowTermsAndConditions(true)}>
                            {t('registration.terms')}
                        </button>
                    </label>
                    <p>{t('registration.link')}</p>
                </div>
                {showTermsAndConditions && (
                    <TermsAndConditionsModal
                        close={() => setShowTermsAndConditions(false)}
                        tab={TabType.TERMS_CONDITIONS}
                        inRegister
                    />
                )}
            </div>
        </div>
    );
};

export default withTranslationContext(RegistrationScreenWrapper);
