/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

import { PreferencesContextProvider } from './PreferencesContext';
import { AppState } from '../../../reducers/types';
import { Preferences, Language, Theme } from '../../../constants/preferences';
import {
    requestPreferences,
    requestPreferencesChange,
} from '../../../actions/preferences';

/**
 * @typedef {Object} StateProps
 * @property {Language} language
 * @property {Theme} theme
 */
interface StateProps {
    language: Language;
    theme: Theme;
}

/**
 * @typedef {Object} DispatchProps
 * @property {Function} requestPreferences
 * @property {Function} requestPreferencesChange
 */
interface DispatchProps {
    requestPreferences: Function;
    requestPreferencesChange: Function;
}

/**
 * @typedef {Object} OwnProps
 * @property {any} children
 */
interface OwnProps {
    children: any;
}

/**
 * @typedef {Object} Props
 */
type Props = StateProps & DispatchProps & OwnProps;

/**
 * @typedef {Object} OwnState
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * provides store properties and actions access to its consumers
 * @extends {Component<Props, State>}
 */
export class PreferencesController extends Component<Props, State> {

    /**
     * change language
     * @param {Language} language
     */
    changeLanguage = (language: Language) => {
        const {
            theme,
            requestPreferencesChange,
        } = this.props;
        requestPreferencesChange({
            language: language,
            theme: theme,
        });
    };

    /**
     * change theme
     * @param {Theme} theme
     */
    changeTheme = (theme: Theme) => {
        const {
            language,
            requestPreferencesChange,
        } = this.props;
        requestPreferencesChange({
            language: language,
            theme: theme,
        });
    };

    /**
     * requests preferences
     */
    getPreferences = () => {
        const {
            requestPreferences,
        } = this.props;
        requestPreferences(() => {});
    };

    render() {
        const {
            children,
            language,
            theme,
        } = this.props;

        return (
            <PreferencesContextProvider
                value={{
                    language: language,
                    theme: theme,
                    changeLanguage: this.changeLanguage,
                    changeTheme: this.changeTheme,
                    getPreferences: this.getPreferences,
                }}
            >
                {children}
            </PreferencesContextProvider>
        )
    }

}

/**
 * mapStateToProps
 * @param {AppState} state
 * @returns {StateProps}
 */
const mapStateToProps = (state: AppState): StateProps => {
    return {
        language: state.preferences.language,
        theme: state.preferences.theme,
    };
};

/**
 * mapDispatchToProps
 * @param {Dispatch} dispatch
 * @returns {DispatchProps}
 */
export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    requestPreferences: (onSuccess: Function) => dispatch(requestPreferences(onSuccess)),
    requestPreferencesChange: (prefs: Preferences, onSuccess: Function) => dispatch(requestPreferencesChange(prefs, onSuccess)),
});

export const ConnectedPreferencesController = connect(mapStateToProps, mapDispatchToProps)(PreferencesController);
