/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPendingAthleteSupporters, setPendingAthleteSupporters } from '../../../actions/athlete_supporter';
import {
    ApiError, KeyedObject, List, TOTAL_ITEMS,
} from '../../../constants/misc';
import {
    PendingAthleteSupporters,
    SponsorAthletesSupport,
    SponsorProduct,
    SponsorProductsRequest,
} from '../../../constants/sponsor';
import { Badge, Sponsor } from '../../../types/user';
import { AppState } from '../../../reducers/types';
import { athleteSponsorsURL, supporterAthletesURL } from '../../../services/athletes';
import {
    acceptSupportURL,
    athletesSupportsURL,
    rejectSupportURL,
    sponsorProductsURL,
    sponsorProductURL, supportAthleteURL,
    supportsAthleteURL,
    cancelSupportURL,
} from '../../../services/sponsors';
import { displayError, displaySuccess } from '../../../utils/notifications';
import { TranslationContext, withTranslationContext } from '../translation/TranslationContext';
import { UserContext, withUserContext } from '../user/UserContext';
import { SponsorContextProvider } from './SponsorContext';
import { SUPPORT_STATUS } from '../../../constants/support';
import { AppDispatch } from '../../../store';

interface DispatchProps {
    dispatchSetPendingAthleteSupporters: (athleteSupporter: PendingAthleteSupporters) => void;
    dispatchResetPendingAthleteSupporters: () => void;
}

interface StateProps {
    pendingAthleteSupporters: PendingAthleteSupporters;
    showAthleteSupporters: boolean;
}

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps & DispatchProps & StateProps & UserContext & TranslationContext;

export class SponsorsController extends Component<Props> {
    submitProduct = async (data: SponsorProductsRequest, onSuccess: () => void, onFailure: (errorData?: ApiError, errorMessage?: number) => void): Promise<void> => {
        let headers = { 'Content-Type': 'application/json' };
        let payload: Record<string, unknown> | FormData = { ...data };
        
        if (data.file) {
            const { file, ...info } = data;
            headers = { 'Content-Type': 'multipart/form-data' };
            payload = new FormData();
            payload.append('info', new Blob([JSON.stringify(info)], { type: 'application/json' }));
            payload.append('file', file);
        }
        try {
            if (data.id) {
                await axios.put(sponsorProductURL(data.id), payload, { headers });
            } else {
                await axios.post(sponsorProductsURL(), payload, { headers });
            }
            onSuccess();
        } catch (error) {
            if (axios.isAxiosError(error)) {
                onFailure(error?.response?.data, error?.response?.status);
                return;
            }
            onFailure();
        }
    };

    getSponsoredAthletesBadges = async (sponsorId: string): Promise<Badge[]> => {
        try {
            const { data } = await axios.get(supporterAthletesURL(sponsorId));
            return data;
        } catch {
            return [];
        }
    }

    getSponsorsBadges = async (athleteId: string): Promise<Badge[]> => {
        try {
            const { data } = await axios.get(athleteSponsorsURL(athleteId));
            return data;
        } catch {
            return [];
        }
    }

    getSponsorProducts = async (params: Record<string, number | string>): Promise<List<SponsorProduct>> => {
        try {
            const { data, headers } = await axios.get(sponsorProductsURL(params));
            return { list: data, total: headers[TOTAL_ITEMS] || 0 };
        } catch (e) {
            return { list: [], total: 0 };
        }
    }

    getSponsorProduct = async (id: number): Promise<SponsorProduct | null> => {
        const { handleAPIErrors } = this.props;
        try {
            const response = await axios.get(sponsorProductURL(id));
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            handleAPIErrors(err.response?.data, true);
            return null;
        }
    }

    getSponsor = async (sponsorId: number): Promise<Sponsor | null> => {
        const { getUser } = this.props;
        return getUser(sponsorId) as Promise<Sponsor | null>;
    }

    deleteSponsorProduct = async (productId: number): Promise<void> => {
        const { handleAPIErrors, t } = this.props;

        try {
            if (productId < 0) throw new Error();

            await axios.delete(sponsorProductURL(productId));
            displaySuccess({ message: t('general.successDelete') });
        } catch (error) {
            const err = error as AxiosError;
            if (err?.response?.status === 403) {
                displayError({ message: t('profile.uploadRoleError') });
            } else {
                handleAPIErrors(err.response?.data, true);
            }
        }
    }

    getAthleteSupport = async (supportId: number): Promise<SponsorAthletesSupport | null> => {
        try {
            const { data } = await axios.get(supportAthleteURL(supportId));

            return data;
        } catch {
            return null;
        }
    }

    fetchAthletePendingSupports = async (): Promise<void> => {
        const { dispatchSetPendingAthleteSupporters, dispatchResetPendingAthleteSupporters } = this.props;

        try {
            const { data, headers } = await axios.get(supportsAthleteURL({ _limit: 3, _pendingStatus: true }));

            dispatchSetPendingAthleteSupporters({
                list: data,
                total: Number(headers[TOTAL_ITEMS]),
            });
        } catch {
            dispatchResetPendingAthleteSupporters();
        }
    }

    resetAthletePendingSupports = (): void => {
        const { dispatchResetPendingAthleteSupporters } = this.props;

        dispatchResetPendingAthleteSupporters();
    }

    getAthletesSupport = async (params: Record<string, number>): Promise<List<SponsorAthletesSupport>> => {
        try {
            const { data, headers } = await axios.get(athletesSupportsURL(params));
            return { list: data, total: headers[TOTAL_ITEMS] || 0 };
        } catch {
            return { list: [], total: 0 };
        }
    }

    getSupportsAthlete = async (params: Record<string, number>): Promise<List<SponsorAthletesSupport>> => {
        try {
            const { data, headers } = await axios.get(supportsAthleteURL(params));
            return { list: data, total: headers[TOTAL_ITEMS] || 0 };
        } catch {
            return { list: [], total: 0 };
        }
    }

    patchAcceptSupport = async (supportId: number, onSuccess: () => void, onFailure: (error: KeyedObject) => void): Promise<void> => {
        try {
            await axios.patch(acceptSupportURL(supportId));

            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data?.errors[0].message);
        }
    };

    patchRejectSupport = async (supportId: number, onSuccess: () => void, onFailure: (error: KeyedObject) => void): Promise<void> => {
        try {
            await axios.patch(rejectSupportURL(supportId));

            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data?.errors[0].message);
        }
    };
    
    patchCancelSupport = async (supportId: number, status: SUPPORT_STATUS, onSuccess: () => void, onFailure: (error: KeyedObject) => void): Promise<void> => {
        try {
            await axios.patch(cancelSupportURL(supportId, { status }));
            onSuccess();
        } catch (error) {
            const { t } = this.props;
            const err = error as AxiosError;

            const message = err.response?.data?.errors ? err.response?.data?.errors[0]?.message ?? t('errors.general') : t('errors.general');

            onFailure(message);
        }
    };

    render(): React.ReactNode {
        const {
            children,
            pendingAthleteSupporters,
            showAthleteSupporters,
        } = this.props;

        return (
            <SponsorContextProvider
                value={{
                    pendingAthleteSupporters,
                    showAthleteSupporters,
                    submitProduct: this.submitProduct,
                    getSponsoredAthletesBadges: this.getSponsoredAthletesBadges,
                    getSponsorsBadges: this.getSponsorsBadges,
                    getSponsorProducts: this.getSponsorProducts,
                    getSponsorProduct: this.getSponsorProduct,
                    getSponsor: this.getSponsor,
                    deleteSponsorProduct: this.deleteSponsorProduct,
                    getAthleteSupport: this.getAthleteSupport,
                    fetchAthletePendingSupports: this.fetchAthletePendingSupports,
                    resetAthletePendingSupports: this.resetAthletePendingSupports,
                    getAthletesSupport: this.getAthletesSupport,
                    getSupportsAthlete: this.getSupportsAthlete,
                    patchAcceptSupport: this.patchAcceptSupport,
                    patchRejectSupport: this.patchRejectSupport,
                    patchCancelSupport: this.patchCancelSupport,
                }}
            >
                {children}
            </SponsorContextProvider>
        );
    }
}

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
    dispatchSetPendingAthleteSupporters: (athleteSupporter: PendingAthleteSupporters) => dispatch(setPendingAthleteSupporters(athleteSupporter)),
    dispatchResetPendingAthleteSupporters: () => dispatch(resetPendingAthleteSupporters()),
});

export const mapStateToProps = (state: AppState): StateProps => ({
    pendingAthleteSupporters: state.athleteSupporter.pendingAthleteSupporters,
    showAthleteSupporters: state.athleteSupporter.show,
});

export const ConnectedSponsorsController = connect(mapStateToProps, mapDispatchToProps)(withTranslationContext(withUserContext(SponsorsController)));
