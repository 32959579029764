/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 * @property {string} fill
 * @property {Function} onClick
 */
interface OwnProps {
    onClick?: Function;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
const IconCheckCircle: FunctionComponent<Props> = (props: Props) => {
    const { onClick } = props;

    return (
        <div className="icon-check-circle" onClick={(): void => (onClick ? onClick() : {})}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 330 330"
            >
                <path d="M165 0C74.019 0 0 74.019 0 165s74.019 165 165 165 165-74.019 165-165S255.981 0 165 0zm0 300c-74.44 0-135-60.561-135-135S90.56 30 165 30s135 60.561 135 135-60.561 135-135 135z" />
                <path d="M226.872 106.664l-84.854 84.853-38.89-38.891c-5.857-5.857-15.355-5.858-21.213-.001-5.858 5.858-5.858 15.355 0 21.213l49.496 49.498c2.813 2.813 6.628 4.394 10.606 4.394h.001c3.978 0 7.793-1.581 10.606-4.393l95.461-95.459c5.858-5.858 5.858-15.355 0-21.213-5.858-5.858-15.355-5.859-21.213-.001z" />
            </svg>
        </div>

    );
};

export default IconCheckCircle;
