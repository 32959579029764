/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios from 'axios';

import {
    PreferencesAction,
    PreferencesActionTypes,
} from './preferences_types';
import {
    Language,
    languageEnumToName,
    Theme,
    Preferences,
} from '../constants/preferences';
import { preferencesURL } from '../services/preferences';

/**
 * sets the current language
 * @param {Language} language
 * @returns {PreferencesActionTypes}
 */
export const setLanguageActionCreator = (language: Language): PreferencesActionTypes => {
    return {
        type: PreferencesAction.SET_LANGUAGE,
        payload: language,
    };
};

/**
 * sets the current theme
 * @param {Theme} theme
 * @returns {PreferencesActionTypes}
 */
export const setThemeActionCreator = (theme: Theme): PreferencesActionTypes => ({
    type: PreferencesAction.SET_THEME,
    payload: theme,
});

/**
 * signals the preferences request is being made
 * @returns {PreferencesActionTypes}
 */
export const preferencesRequestActionCreator = (): PreferencesActionTypes => {
    return {
        type: PreferencesAction.PREFERENCES_REQUEST,
    };
};

/**
 * received preferences success
 * @param {Preferences} preferences
 * @returns {PreferencesActionTypes}
 */
export const preferencesSuccessActionCreator = (preferences: Preferences): PreferencesActionTypes => {
    return {
        type: PreferencesAction.PREFERENCES_SUCCESS,
        payload: preferences,
    };
};

/**
 * received preferences failure
 * @param {object} formErrors
 * @returns {PreferencesActionTypes}
 */
export const preferencesFailureActionCreator = (formErrors: object): PreferencesActionTypes => {
    return {
        type: PreferencesAction.PREFERENCES_FAILURE,
        payload: formErrors,
    };
};

/**
 * signals the preferences change request is being made
 * @returns {PreferencesActionTypes}
 */
export const preferencesChangeRequestActionCreator = (): PreferencesActionTypes => {
    return {
        type: PreferencesAction.PREFERENCES_CHANGE_REQUEST,
    };
};

/**
 * received preferences change success
 * @param {Preferences} preferences
 * @returns {PreferencesActionTypes}
 */
export const preferencesChangeSuccessActionCreator = (preferences: Preferences): PreferencesActionTypes => {
    return {
        type: PreferencesAction.PREFERENCES_CHANGE_SUCCESS,
        payload: preferences,
    };
};

/**
 * received preferences change failure
 * @param {object} formErrors
 * @returns {PreferencesActionTypes}
 */
export const preferencesChangeFailureActionCreator = (formErrors: object): PreferencesActionTypes => {
    return {
        type: PreferencesAction.PREFERENCES_CHANGE_FAILURE,
        payload: formErrors,
    };
};

/**
 * makes the preferences request and sets the necessary data in the store
 * @param {Function} onSuccess
 */
export const requestPreferences = (onSuccess: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(preferencesRequestActionCreator());
        try {
            const { status, data } = await axios.get(preferencesURL());

            if (status === 200) {
                const prefs: Preferences = {
                    language: data.language,
                    theme: data.theme,
                };

                dispatch(preferencesSuccessActionCreator(prefs));
                onSuccess(data);
            }
        } catch (error) {
            dispatch(preferencesFailureActionCreator({}));
        }
    };
};

/**
 * makes the preferences change request and sets the necessary data in the store
 * @param {Preferences} prefs
 * @param {Function} onSuccess
 */
export const requestPreferencesChange = (prefs: Preferences, onSuccess: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(preferencesChangeRequestActionCreator());
        try {
            const { status, data } = await axios.put(preferencesURL(), {
                language: languageEnumToName(prefs.language),
                theme: prefs.theme,
            });

            if (status === 200) {
                const prefs: Preferences = {
                    language: data.language,
                    theme: data.theme,
                };

                dispatch(preferencesChangeSuccessActionCreator(prefs));
                onSuccess(data);
            }
        } catch (error) {
            dispatch(preferencesChangeFailureActionCreator({}));
        }
    };
};
