/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { API_URL } from '../settings';
import { ListParams } from '../constants/misc';
import { objectToQueryParams } from '../types/misc';

const api = 'users';

export const usersURL = (params = {}): string => {
    const queryParams = objectToQueryParams(params);
    return `${API_URL}/${api}${queryParams}`;
};

export const usersSearchURL = (params?: Partial<ListParams>): string => {
    const queryParams = objectToQueryParams(params || {});
    return `${API_URL}/${api}/search${queryParams}`;
};

export const adminUsersURL = (params = {}): string => {
    const queryParams = objectToQueryParams(params);
    return `${API_URL}/${api}/auth${queryParams}`;
};

export const adminUsersSearchURL = (params?: Partial<ListParams>): string => {
    const queryParams = objectToQueryParams(params || {});
    return `${API_URL}/${api}/search/auth${queryParams}`;
};

export const userURL = (id: number | string): string => {
    return `${API_URL}/${api}/${id}`;
};

export const toggleVisibilityURL = (id: any): string => {
    return `${API_URL}/${api}/${id}/profile-state`;
};

export const userSportestsURL = (id: React.Key): string => {
    return `${API_URL}/${api}/${id}/sportests`;
};
