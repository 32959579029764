/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'clubs';

/**
 * Clubs URL
 * @returns {string}
 */
export const clubsURL = (): string => {
    return `${API_URL}/${api}`;
};

/**
 * Clubs modality URL
 * @returns {string}
 */
export const clubsModalitiesURL = (sport: string): string => {
    return `${API_URL}/${api}/modalities?modality=${sport}`;
};

export const paginatedClubsURL = (pageIndex = 0, pageSize = 5): string => {
    return `${API_URL}/${api}/paginated?pageIndex=${pageIndex}&pageSize=${pageSize}`;
};
