/*
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Location } from 'history';

interface OwnProps {
    navigate: (pathName: string) => void;
    when: boolean;
    title: string;
    shouldBlockNavigation: (path: string) => boolean;
    cancelText: string;
    confirmText: string;
}

type Props = OwnProps

export const RouteLeavingGuard: React.FC<OwnProps> = (props: Props) => {
    const {
        title, when, navigate, shouldBlockNavigation, cancelText, confirmText,
    } = props;

    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location<unknown> | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const showModal = (location: Location<unknown>) => {
        setModalVisible(true);
        setLastLocation(location);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation: Location<unknown>) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation.pathname)) {
            showModal(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        closeModal();

        if (lastLocation) setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
            setConfirmedNavigation(false);
        }
    }, [confirmedNavigation, lastLocation, navigate]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            {modalVisible && (
                <div className="modal" aria-label="leaving-guard">
                    <div className="modal__test" />
                    <div className="confirmation-modal">
                        <div className="confirmation-modal__content">
                            <p className="confirmation-modal__content__title">{title}</p>
                            <div className="confirmation-modal__content__modal-buttons">
                                <button
                                    type="button"
                                    className="btn btn--warning-outline"
                                    onClick={() => closeModal()}
                                    data-testid="cancel-button"
                                >
                                    {cancelText}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn--primary-inverse"
                                    onClick={handleConfirmNavigationClick}
                                    data-testid="confirm-button"
                                >
                                    {confirmText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RouteLeavingGuard;
