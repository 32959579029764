/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import FormField from '../elements/FormField';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/logo_white.svg';
import { ResendActivationFormFields } from '../../constants/authentication';
import { KeyedObject } from '../../constants/misc';
import { validateForm, ValidationType } from '../../utils/validations';
import { displayError, displaySuccess } from '../../utils/notifications';
import { ErrorCode } from '../../constants/errors';
import { AppRoute } from '../../constants/routes';

interface MatchParams {
    token: string;
}

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext {}

type Props = OwnProps;

interface OwnState {
    validated: boolean | null;
    fields: ResendActivationFormFields;
    errors: KeyedObject | null;
}

type State = OwnState;

const initialState: State = {
    validated: false,
    fields: {
        email: '',
    },
    errors: null,
};

class ValidateAccountScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount(): void {
        const {
            accountValidate,
            match: {
                params: { token },
            },
            t,
            openLogin,
        } = this.props;

        accountValidate(
            { token },
            () => {
                openLogin();
                displaySuccess({
                    message: t('activate.success'),
                });
            },
            () => {
                this.setState({
                    validated: false,
                });
                this.onValidationFailure();
            },
        );
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { resendActivation } = this.props;
        const { fields } = this.state;

        const errors = validateForm(this.state.fields, {
            email: {
                validations: [ValidationType.NotBlank],
            },
        });

        this.setState(
            {
                ...this.state,
                errors,
            },
            () => {
                if (!errors) {
                    resendActivation(fields, this.onSuccess, this.onFailure);
                }
            },
        );
    };

    onSuccess = () => {
        const { t } = this.props;
        this.setState(
            {
                ...this.state,
                fields: initialState.fields,
                errors: null,
            },
            () => {
                displaySuccess({
                    message: t('activate.message'),
                });
            },
        );
    };

    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;
        if (errors.fields) {
            this.setState({
                ...this.state,
                errors: errors.fields,
            });
        }
        if (errors.errors) {
            errors.errors.forEach((error: any) => {
                displayError({
                    message: t(`errors.${ErrorCode[error.errorCode]}`),
                });
            });
        }
    };

    onValidationFailure = () => {
        const { t, openLogin } = this.props;

        openLogin();
        displayError({
            message: t('errors.validationToken'),
        });
    }

    onCloseClick = (e: React.MouseEvent) => {
        const { history } = this.props;

        history.push(AppRoute.Index);
    };

    renderMessage = (): string => {
        const { t } = this.props;
        return t('activate.error');
    };

    render() {
        const { isFetching, t } = this.props;
        const { validated, fields, errors } = this.state;

        if (validated !== false || isFetching) return null;

        return (
            <div className="validate-account-screen" data-testid="validate-account-screen">
                <button type="button" onClick={this.onCloseClick} aria-label={t('activate.close')}>
                    <IconTimes />
                </button>
                <div>
                    <img src={logo} alt="" />
                    <h1>{t('activate.title')}</h1>
                    <p>{this.renderMessage()}</p>
                    <form onSubmit={this.onSubmit}>
                        <FormField
                            type="text"
                            name="email"
                            value={fields.email}
                            placeholder={t('activate.email')}
                            onChange={this.onInputChange}
                            disabled={isFetching}
                            errors={errors}
                        />
                        <button type="submit" className="btn btn--primary-inverse" disabled={isFetching}>
                            {t('activate.submit')}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(ValidateAccountScreen));
