/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import InputError from './FormFieldError';
import iconCloseEye from '../../assets/images/icon_close_eye.svg';
import iconOpenEye from '../../assets/images/icon_open_eye.svg';
import { getErrorsForField, hasAnyErrors } from '../../utils/validations';
import { KeyedObject } from '../../constants/misc';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface Props {
    label?: string;
    type?: string;
    name: string;
    value: unknown | undefined;
    onChange: (e: any) => void;
    placeholder?: string;
    disabled?: boolean;
    multiline?: boolean;
    cssClass?: string;
    errors?: KeyedObject | null;
    rows?: number;
}

type OwnProps = TranslationContext & Props;

const FormField: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        onChange,
        label,
        type,
        name,
        value,
        placeholder,
        disabled,
        multiline,
        cssClass,
        errors,
        rows,
    } = props;

    const [showPassword, setShowPassword] = useState<boolean>(false);
    /**
     * handles toggle password
     */
    const onTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    /**
     * returns the textarea
     */
    const renderTextArea = () => {
        return (
            <TextareaAutosize
                id={name}
                name={name}
                minRows={rows || 1}
                value={value ? String(value) : ''}
                maxRows={rows}
                rows={rows}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
                disabled={disabled}
                style={{ fontSize: '14px', minHeight: `${(rows || 1) * 21}px` }}
                
            />
        );
    };

    /**
     * returns the input
     */
    const renderInput = () => {
        return (
            <input
                type={inputType}
                id={name}
                name={name}
                value={value ? String(value) : ''}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
                disabled={disabled}
            />
        );
    };

    const getInputType = (inputType?: string) => {
        if (inputType === 'password' && !showPassword) {
            return 'password';
        } if (inputType === 'number') {
            return 'number';
        }
        return 'text';
    };

    const fieldErrors = getErrorsForField(name, errors);
    const hasErrors = hasAnyErrors(fieldErrors);
    const inputType = getInputType(type);

    return (
        <div className="form-field">
            <div className={`input-container ${hasErrors ? 'has-error' : ''} ${cssClass}`}>
                {multiline ? renderTextArea() : renderInput()}
                {type === 'password' && (
                    <button type="button" onClick={onTogglePassword} aria-label={!showPassword ? t('password.see') : t('password.hide')}>
                        <img src={!showPassword ? iconCloseEye : iconOpenEye} alt="" />
                    </button>
                )}
            </div>
            {!!label && label.length > 0 && <label htmlFor={name}>{label}</label>}
            {hasErrors && <InputError errors={fieldErrors} field={name} />}
        </div>
    );
};

export default withTranslationContext(FormField);
