/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Popover } from '@material-ui/core';
import { User } from '../../types/user';
import { withTranslationContext, TranslationContext } from '../controllers/translation/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { withUserContext, UserContext } from '../controllers/user/UserContext';
import { withWalletContext, WalletContext } from '../controllers/wallet/WalletContext';
import { IconCreditCard } from '../assets/IconCreditCard';
import { WalletTransactions } from '../../types/credit';
import { AppRoute } from '../../constants/routes';

interface OwnProps {
    userPageOwner: User | null;
}

type Props = OwnProps & TranslationContext & AuthenticationContext & UserContext & WalletContext;

const UserWallet: FunctionComponent<Props> = ({
    user,
    userPageOwner,
    isAuthenticated,
    getWalletTransactions,
    getUser,
    t,
}: Props) => {
    const [walletTransactionsAnchorEl, setWalletTransactionsAnchorEl] = useState<HTMLElement | null>(null);
    const [walletTransactions, setWalletTransactions] = useState<WalletTransactions[] | null>([]);
    const [availableCredits, setAvaliableCredits] = useState(0);

    const getUserData = useCallback(async () => {
        const userData = await getUser(user?.id);
        if (userData && userData.availableCredits) setAvaliableCredits(userData.availableCredits);
    }, [getUser, user]);

    const prepare = useCallback(async () => {
        setWalletTransactions(await getWalletTransactions({
            _limit: 10,
            _page: 0,
        }));
        getUserData();
    }, [getWalletTransactions, getUserData]);

    useEffect(() => {
        if (isAuthenticated) {
            prepare();
        }
    }, [prepare, user, isAuthenticated]);

    const renderTransaction = (): React.ReactNode => {
        if (walletTransactions && walletTransactions.length > 0) {
            return walletTransactions.map((transaction) => (
                <div
                    key={transaction.id}
                    className={transaction.creditsNumber > 0 ? 'give-credit' : 'take-credit'}
                >
                    <span className="created-date">{ format(new Date(transaction.createdDate), 'dd.MM.yyyy') }</span>
                    <div className="row">
                        <span>{transaction.description}</span>
                        <span className="credit-number">
                            {`${transaction.creditsNumber > 0 ? '+' : ''}${transaction.creditsNumber} ${t('market.credits')}`}
                        </span>
                    </div>
                </div>
            ));
        }

        return (
            <div className="no-info" aria-label={t('general.noInfo')}>
                <span>{t('general.noInfo')}</span>
            </div>
        );
    };
    
    return (
        <>
            {user?.id === userPageOwner?.id && (
                <button type="button" className="user-wallet" onClick={(e) => setWalletTransactionsAnchorEl(e.currentTarget)} aria-label={t('market.wallet')}>
                    <IconCreditCard />
                    <span className="user-wallet__credit-amount">{availableCredits}</span>
                    <span className="user-wallet__credit-label">{t('market.credits')}</span>
                </button>
            )}
            <Popover
                open={!!walletTransactionsAnchorEl}
                anchorEl={walletTransactionsAnchorEl}
                onClose={() => setWalletTransactionsAnchorEl(null)}
                anchorOrigin={{
                    vertical: 40,
                    horizontal: -200,
                }}
                disableScrollLock
            >
                <div className="transactions-modal">
                    {renderTransaction()}
                </div>
                <div className="buy-credits">
                    <Link to={AppRoute.Credit}>
                        <div className="buy-credits__btn">
                            {t('market.buyCredits')}
                        </div>
                    </Link>
                </div>
            </Popover>
        </>
    );
};

export default withAuthenticationContext(
    withTranslationContext(
        withUserContext(
            withWalletContext(
                UserWallet,
            ),
        ),
    ),
);
