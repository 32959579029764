/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { DropdownOption } from '../constants/misc';

export interface CreditEditFields {
    actionType: DropdownOption | null;
    creditsNumber: string;
    description: string;
}

export interface CreditRequest {
    userId: number;
    creditsNumber: number;
    description: string;
}

export enum CREDIT_ACTION_TYPE {
    TAKE_CREDITS = 'TAKE_CREDITS',
    GIVE_CREDITS = 'GIVE_CREDITS',
}

export interface WalletTransactions {
    id: number;
    creditsNumber: number;
    description: string;
    createdDate: string;
}

export interface PaymentPostData {
    creditAmount: number;
    successCallbackUrl: string;
    cancelCallbackUrl: string;
    failureCallbackUrl: string;
}

export enum CREDIT_RANGE_MAX_MIN {
    MIN = 50,
    MAX = 1200,
}

export enum PHONE_NUMBER_LENGHT {
    MAX = 999999999,
}
