/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

export enum TableActionType {
    ACCEPT = 'ACCEPT',
    REJECT = 'REJECT',
    CANCEL = 'CANCEL'
}
