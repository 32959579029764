/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ReactSVG } from 'react-svg';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { MatchParams } from '../../constants/misc';
import { SponsorProduct } from '../../constants/sponsor';
import { SponsorContext, withSponsorContext } from '../controllers/sponsor/SponsorContext';
import BuyProductModal from '../elements/BuyProductModal';
import { Sponsor } from '../../types/user';
import { AppRoute } from '../../constants/routes';
import loader from '../../assets/images/loader.svg';
import { buildRoute } from '../../utils/misc';

 interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext, SponsorContext {}

 type Props = OwnProps;

const ProductScreen: FunctionComponent<Props> = ({
    history,
    match,
    getSponsorProduct,
    getSponsor,
}: Props) => {
    const [sponsor, setSponsor] = useState<Sponsor | null>(null);
    const [productData, setProductData] = useState<SponsorProduct | null>(null);
    const [isFetching, setIsFetching] = useState(false);

    const prepare = useCallback(async (id: string) => {
        setIsFetching(true);
        const [productData] = await Promise.all([
            getSponsorProduct(+id),
        ]);
        if (!productData) {
            history.push(`${AppRoute.NotFound}`);
            return;
        }
        
        setProductData(productData);
        setSponsor(await getSponsor(productData.sponsorID));
        setIsFetching(false);
    }, [getSponsorProduct, getSponsor, history]);
    
    useEffect(() => {
        window.scrollTo(0, 0);
        if (match && match.params && match.params.id) {
            prepare(match.params.id);
        }
    }, [match, prepare]);

    return (
        <div className="product-screen" data-testid="product-screen">
            { isFetching && (
                <div className="loader" data-testid="loader">
                    <ReactSVG wrapper="span" src={loader} />
                </div>
            )}
            { productData && sponsor && (
                <BuyProductModal
                    product={productData}
                    sponsor={sponsor}
                    onClose={(): void => history.push(buildRoute(AppRoute.Sponsor, { id: sponsor.id }))}
                />
            )}
        </div>
    );
};

export default withSponsorContext(
    withTranslationContext(
        withRouter(
            ProductScreen,
        ),
    ),
);
