import React, { useEffect, FunctionComponent, useCallback } from 'react';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import {
    useMap, MapContainer, TileLayer,
} from 'react-leaflet';

import 'leaflet-geosearch/assets/css/leaflet.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';
import { Control, LeafletEvent } from 'leaflet';

import debounce from 'lodash.debounce';
import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';

/**
 * @typedef {Object} MapOwnProps
 */
interface MapOwnProps {
    location?: string;
    search?: boolean;
}

/**
 * @typedef {Object} MapProps
 */
type MapProps = MapOwnProps;

/**
 * @typedef {Object} SearchFieldOwnProps
 */
interface SearchFieldOwnProps {
    location?: string;
    searchControl: Control;
}
 
/**
 * @typedef {Object} SearchFieldProps
 */
type SearchFieldProps = SearchFieldOwnProps;

interface EventSearchResult extends LeafletEvent{
    location: SearchResult;
}

const Map: FunctionComponent<MapProps> = (props: MapProps) => {
    const provider = new OpenStreetMapProvider();
    const searchControl = GeoSearchControl({
        provider,
    });
    const { location, search } = props;
    
    const displayLocation = async (query: string) => {
        const result = await provider.search({ query });
        if (result.length > 0) {
            const firstResult = result[0];
            searchControl.addMarker(firstResult, { query: '' });
            searchControl.centerMap(firstResult);
        }
    };

    const throttledSearch = useCallback(debounce((loc: string) => displayLocation(loc), 500), []);
    
    useEffect(() => {
        if (location) {
            throttledSearch(location);
        }
    }, [location, throttledSearch]);

    return (
        <div className="map">
            <MapContainer center={[39.399872, -8.224454]} zoom={6} scrollWheelZoom>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {search
                    ? <SearchField searchControl={searchControl} location={location} />
                    : <InitialSearch searchControl={searchControl} location={location} />}
            </MapContainer>
        </div>
    );
};

const SearchField: FunctionComponent<SearchFieldProps> = (props: SearchFieldProps) => {
    const map = useMap();
    
    useEffect(() => {
        const { searchControl} = props;
 
        map.addControl(searchControl);
        return () => {
            map.removeControl(searchControl);
        };
    });

    return null;
};

const InitialSearch: FunctionComponent<SearchFieldProps> = (props: SearchFieldProps) => {
    const { searchControl } = props;
    const map = useMap();
    useEffect(() => {
        map.addControl(searchControl);
        return () => {
            map.removeControl(searchControl);
        };
    });
    return null;
};

export default Map;
