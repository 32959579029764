/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps {
    open: boolean;
    options: { label: string; onClick: () => void }[];
}

type Props = OwnProps & TranslationContext;

const MenuOptions: FunctionComponent<Props> = (props: Props) => {
    const { options, open } = props;
    return (
        <div className={`menu-options ${open ? 'menu-options--opened' : ''}`}>
            {
                options.map((option) => (
                    <React.Fragment key={option.label}>
                        <hr />
                        <button onClick={option.onClick} type="button">{option.label}</button>
                    </React.Fragment>
                ))
            }
        </div>
    );
};

export default withTranslationContext(MenuOptions);
