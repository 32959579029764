/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 */
interface OwnProps {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
const IconDropdown: FunctionComponent<Props> = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="5"
            viewBox="0 0 10 5"
        >
            <path
                d="M5.354 4.646a.5.5 0 01-.707 0L.854.854A.5.5 0 011.207 0h7.586a.5.5 0 01.354.854z"
                fill="#757575"/>
        </svg>
    );
};

export default IconDropdown;
