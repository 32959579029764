/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

/**
 * Language
 * @enum {string}
 */
export enum Language {
    EN = 'en',
    PT = 'pt',
}

/**
 * converts the language name to the corresponding value in the enum
 * @param {string} language
 * @returns {Language}
 */
export const languageNameToEnum = (language: string): Language => {
    if (language.toLowerCase() === 'en') return Language.EN;
    return Language.PT;
};

/**
 * converts the language name to the corresponding value in the enum
 * @param {Language} language
 * @returns {string}
 */
export const languageEnumToName = (language: Language): string => {
    if (language === Language.EN) return 'en';
    return 'pt';
};

/**
 * Theme
 * @enum {string}
 */
export enum Theme {
    Light = 'LIGHT',
    Dark = 'DARK',
}

/**
 * @typedef {Object} Preferences
 * @property {Language} language
 * @property {Theme} theme
 */
export interface Preferences {
    language: Language;
    theme: Theme;
}

