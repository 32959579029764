/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import { AnyAction } from 'redux';
import { AthleteSupporterAction } from '../actions/athlete_supporter_types';
import { AuthenticationAction } from '../actions/authentication_types';
import { AthleteSupporterState } from './types';

/**
 * @typedef {AthleteSupporterState} athleteSupporterInitialState
 */
export const athleteSupporterInitialState: AthleteSupporterState = {
    pendingAthleteSupporters: {
        list: [],
        total: 0,
    },
    show: false,
};

export default function (state = athleteSupporterInitialState, action: AnyAction): AthleteSupporterState {
    switch (action.type) {
        case AthleteSupporterAction.SET_PENDING_ATHLETE_SUPPORTERS: {
            return {
                ...state,
                pendingAthleteSupporters: {
                    ...action.payload,
                },
                show: action.payload.list.length > 0,
            };
        }
        case AuthenticationAction.RESET_AUTHENTICATION:
        case AthleteSupporterAction.RESET_PENDING_ATHLETE_SUPPORTERS:
            return {
                ...athleteSupporterInitialState,
            };
        default:
            return state;
    }
}
