/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, ReactNode } from 'react';

import {
    PreferencesContext,
    withPreferencesContext,
} from '../controllers/preferences/PreferencesContext';
import { Theme } from '../../constants/preferences';

interface OwnProps extends PreferencesContext {
    children: ReactNode;
}

type Props = OwnProps;

/**
 * provides theme class to the children components
 * @param {Props} props
 */
const Themed: FunctionComponent<Props> = ({ theme, children }: Props) => {
    const themeClass = `theme-${(theme === Theme.Dark) ? Theme.Dark : Theme.Light}`;

    return (
        <div className={`theme-container ${themeClass.toLowerCase()}`}>
            {children}
        </div>
    );
};

export default withPreferencesContext(Themed);
