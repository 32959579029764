/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { DropdownOption } from '../constants/misc';
import { enumToString } from './format';

/**
 * returns a list of dropdown options from an enum array
 * @param {enum[]} arr
 * @param {boolean} sort
 * @returns {DropdownOption[]}
 */
export const enumToOptions = (arr?: string[], sort?: boolean, normalize = true): DropdownOption[] => {
    if (!arr) return [];
    if (sort) {
        return [...arr].sort((a, b) => a.localeCompare(b)).map(((e) => {
            return { label: enumToString(e), value: e };
        }));
    }
    if (normalize) {
        return arr.map((e) => {
            return { label: enumToString(e), value: e };
        });
    }
    return arr.map((e) => {
        return { label: e, value: e };
    });
};

/**
 * returns an option by its value
 * @param {any} value
 * @param {DropdownOption[]} options
 * @returns {DropdownOption | null}
 */
export const getOptionByValue = (value: any, options: DropdownOption[]): DropdownOption | null => {
    const res = options.filter((option) => {
        return option.value === value;
    });
    if (res && res.length > 0) return res[0];
    return null;
};
