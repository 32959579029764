/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 */
interface OwnProps {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
export const IconPlaneDelivery: FunctionComponent<Props> = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.032" height="15.032" viewBox="0 0 15.032 15.032">
            <path d="M0,0H15.032V15.032H0Z" fill="none"/>
            <path d="M7.11,8.8,4.286,4.366,5.5,4.042,9.849,8.063l3.3-.883A.94.94,0,1,1,13.631,9L4.253,11.507,3.767,9.691l.151-.041,1.545,1.531-1.645.441a.626.626,0,0,1-.71-.3L1.466,8.362l.908-.243L3.918,9.651,7.11,8.8ZM3.053,13.411H13.074v1.253H3.053Z" transform="translate(-0.548 -1.51)" fill="#5d5d5d"/>
        </svg>
    );
};
