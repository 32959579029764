/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = '/direct-message';

/**
 * Users URL
 * @param {Object} params
 * @returns {string}
 */
export const directMessageURL = (): string => {
    return `${API_URL}/${api}`;
};
