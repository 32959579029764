/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'payments';

export const paymentsURL = (): string => {
    return `${API_URL}/${api}`;
};

export const creditCardPaymentURL = (): string => {
    return `${paymentsURL()}/credit-card`;
};

export const athleteSupportURL = (athleteId: string, credits: number): string => {
    return `${API_URL}/support/athlete/${athleteId}?credits=${credits}`;
};
