/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import FormField from '../elements/FormField';
import { RegistrationInviteFormFields, RegistrationInvitePostData } from '../../constants/authentication';
import { KeyedObject } from '../../constants/misc';
import { displayError, displaySuccess } from '../../utils/notifications';
import { termsURL } from '../../services/terms';
import { Terms } from '../../constants/terms';
import { ErrorCode } from '../../constants/errors';
import { UserRoles, UserTypes } from '../../types/user';
import RegistrationScreenWrapper from '../elements/RegistrationScreenWrapper';

interface MatchParams {
    token: string;
}

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {
    isFetching: boolean;
    close: () => void;
    submit: Function;
    validate: Function;
    openLogin(): void;
}

type Props = OwnProps;

interface OwnState {
    agree: boolean;
    fields: RegistrationInviteFormFields;
    errors: KeyedObject | null;
    terms: Terms | null;
}

const initialState: OwnState = {
    agree: false,
    fields: {
        name: '',
        lastName: '',
        password: '',
        cpassword: '',
    },
    errors: null,
    terms: null,
};

type State = OwnState;

class RegistrationScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount() {
        this.prepare();
    }

    prepare = async () => {
        try {
            const { data } = await axios.get(termsURL());
            this.setState({
                ...this.state,
                terms: data,
            });
        } catch {}
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onCheckChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.checked,
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const {
            submit,
            validate,
            t,
            match: {
                params: { token },
            },
        } = this.props;
        const { fields, agree, terms } = this.state;

        if (agree) {
            const errors = validate(fields, token && token.includes(UserTypes.Sponsor));

            this.setState(
                {
                    ...this.state,
                    errors,
                },
                () => {
                    if (!errors) {
                        const formData: RegistrationInvitePostData = {
                            name: fields.name,
                            lastName: fields.lastName,
                            password: fields.password,
                            token,
                            acceptedTermsOfUse: terms ? terms.version : '',
                        };
                        submit(formData, this.onSuccess, this.onFailure);
                    }
                },
            );
        } else {
            displayError({
                message: t('registration.accept'),
            });
        }
    };

    onSuccess = (role: UserRoles) => {
        const {
            close, openLogin, t,
        } = this.props;
        this.setState(
            {
                ...this.state,
                fields: initialState.fields,
                errors: null,
                agree: false,
            },
            () => {
                close();
                openLogin();
                displaySuccess({ message: t('registration.successMessage') });
            },
        );
    };

    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors.fields) {
            this.setState({
                ...this.state,
                errors: errors.fields,
            });
        }

        if (errors.errors) {
            errors.errors.forEach((error: any) => {
                displayError({
                    message: t(`errors.${ErrorCode[error.errorCode]}`),
                });
            });
        }
    };

    render() {
        const {
            close,
            isFetching,
            t,
            match: {
                params: { token },
            },
        } = this.props;
        const {
            agree, fields, errors,
        } = this.state;

        return (
            <React.Fragment>
                <RegistrationScreenWrapper
                    closeRegistration={close}
                    agreeWithTerm={agree}
                    onCheckChange={this.onCheckChange}
                    isFetching={isFetching}
                >
                    <form onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <FormField
                                    label={t('registration.name')}
                                    type="text"
                                    name="name"
                                    value={fields.name}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                {(!token || !token.includes(UserTypes.Sponsor)) && (
                                    <FormField
                                        label={t('registration.lastName')}
                                        type="text"
                                        name="lastName"
                                        value={fields.lastName}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <FormField
                                    label={t('registration.password')}
                                    type="password"
                                    name="password"
                                    value={fields.password}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <FormField
                                    label={t('registration.repeat')}
                                    type="password"
                                    name="cpassword"
                                    value={fields.cpassword}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="register-button-container">
                                <button
                                    type="submit"
                                    className="btn btn--primary-inverse btn--block"
                                    disabled={isFetching}
                                >
                                    {t('registration.send')}
                                </button>
                            </div>
                        </div>
                    </form>
                </RegistrationScreenWrapper>
            </React.Fragment>
        );
    }
}

export default withTranslationContext(withRouter(RegistrationScreen));
