/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL, WEB_SOCKET_URL } from '../settings';

const api = 'authentication';

/**
 * Authentication URL
 * @returns {string}
 */
export const loginURL = (): string => {
    return `${API_URL}/${api}/login`;
};

/**
 * Account validation URL
 * @returns {string}
 */
export const validateAccountURL = (): string => {
    return `${API_URL}/${api}/validate-account`;
};

/**
 * Resend activation email URL
 * @returns {string}
 */
export const resendActivationURL = (): string => {
    return `${API_URL}/${api}/resend-activation-email`;
};

/**
 * Logout URL
 * @returns {string}
 */
export const logoutURL = (): string => {
    return `${API_URL}/${api}/logout`;
};

/**
 * Recover password URL
 * @returns {string}
 */
export const recoverPasswordURL = (): string => {
    return `${API_URL}/${api}/recover-password`;
};

/**
 * Reset password URL
 * @returns {string}
 */
export const resetPasswordURL = (): string => {
    return `${API_URL}/${api}/reset-password`;
};

/**
 * Invite user URL
 * @returns {string}
 */
export const inviteUserURL = (): string => {
    return `${API_URL}/${api}/invite-user`;
};

/**
 * Get WebSocket token URL
 * @returns {string}
 */
export const webSocketTokenURL = (): string => {
    return `${API_URL}/websockets/token`;
};

/**
 * Get WebSocket URL
 * @returns {string}
 */
export const websocketURL = (token: string): string => {
    return `${WEB_SOCKET_URL}/websocket/user/${token}`;
};
