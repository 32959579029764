/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedUserController, UserController } from './UserController';

export interface UserContext extends Omit<InstanceType<typeof UserController>, keyof React.Component> { }

const userContext = createContext<UserContext | null>(null);

// Export Provider and Consumer
export const UserContextProvider = userContext.Provider;
export const UserContextConsumer = userContext.Consumer;

/**
 * provides a context HOC that components can use to subscribe as consumers
 * @param Component
 * @returns {React.FC}
 */
export const withUserContext = <P, >(Component: ComponentType<P>): FC<Omit<P, keyof UserContext>> => (props) => (
    <ConnectedUserController>
        <UserContextConsumer>{(ctx) => <Component {...(props as P)} {...ctx} />}</UserContextConsumer>
    </ConnectedUserController>
);
