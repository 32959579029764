/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TranslationContextProvider } from './TranslationContext';

import { AppState } from '../../../reducers/types';
import { Language } from '../../../constants/preferences';
import { KeyedObject, ApiError } from '../../../constants/misc';
import { getTranslation } from '../../../utils/translation';

import en from '../../../locales/en/translation.json';
import pt from '../../../locales/pt/translation.json';
import { ErrorCode } from '../../../constants/errors';
import { displayError } from '../../../utils/notifications';
import { FormValidatorError } from '../../../utils/validations';

interface StateProps {
    language: Language;
}

interface OwnProps extends StateProps {
    children: any;
    translations: KeyedObject;
}

type Props = StateProps & OwnProps;

export class TranslationController extends Component<Props> {
    static defaultProps = {
        translations: { en, pt },
    };

    /**
     * translate to string in language
     */
    t = (literal: string, params?: KeyedObject): string => {
        const { translations, language } = this.props;
        return getTranslation(translations, language, literal, params);
    };

    /**
     * handle API Errors
     */
    handleAPIErrors = (errors?: ApiError | null, generalErrorFallback = false): { fields: Record<string, FormValidatorError[]> } | null => {
        if (errors?.fields) {
            return { fields: errors.fields };
        }
        
        if (errors?.errors) {
            errors.errors.forEach((error) => displayError({
                message: this.t(`errors.${ErrorCode[error.errorCode]}`),
            }));
        } else {
            if (generalErrorFallback) {
                displayError({
                    message: this.t('errors.general'),
                });
            }
        }

        // returning an empty fields to signal that an error occurred
        return errors?.errors ? { fields: {} } : null;
    };

    render() {
        const {
            children,
            language,
        } = this.props;

        return (
            <TranslationContextProvider
                value={{
                    language,
                    t: this.t,
                    handleAPIErrors: this.handleAPIErrors,
                }}
            >
                {children}
            </TranslationContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        language: state.preferences.language,
    };
};

export const ConnectedTranslationController = connect(mapStateToProps)(TranslationController);
