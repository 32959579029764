/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

export const IconHome: FunctionComponent<Props> = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19.648" viewBox="0 0 22 19.648">
            <path d="M19,21H5a1,1,0,0,1-1-1V11H1L11.327,1.612a1,1,0,0,1,1.346,0L23,11H20v9A1,1,0,0,1,19,21ZM6,19H18V9.157L12,3.7,6,9.157Z" transform="translate(-1 -1.352)" fill="#278982"/>
        </svg>
    );
};
