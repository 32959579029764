/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { GeneralState } from './types';

/**
 * @typedef {GeneralState} generalInitialState
 */
export const generalInitialState: GeneralState = {

};

/**
 * general reducer
 * @param {GeneralState} state
 * @param {any} action
 * @returns {GeneralState}
 */
export default function (state = generalInitialState, action: any): GeneralState {
    return state;
}
