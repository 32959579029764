/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/logo_white.svg';
import runningImage from '../../assets/images/running.png';
import modalSlant from '../../assets/images/modal_slant.svg';
import { withTranslationContext, TranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps extends TranslationContext {
    show: boolean;
    close: () => void;
}

type Props = OwnProps;

const ProfileError: FunctionComponent<Props> = ({ show, close, t }: Props) => {
    if (!show) {
        return <React.Fragment />;
    }

    return (
        <div className="modal registration-screen profile-error" data-testid="registration-screen">
            <div className="modal__backdrop" />
            <div className="modal__content">
                <button type="button" className="modal__content__close" data-testid="close-modal" onClick={close}>
                    <IconTimes fill="#FFF" />
                </button>
                <div className="modal__content__top">
                    <div className="modal__content__top__header">
                        <img src={logo} className="logo" alt="" />
                        <img src={runningImage} className="running" alt="" />
                        <img src={modalSlant} className="slant" alt="" />
                    </div>
                    <div className="modal__content__top__subheader">
                        <h1>{t('profile.error')}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslationContext(ProfileError);
