/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 * @property {string} fill
 * @property {Function} onClick
 */
interface OwnProps {
    fill: string;
    onClick?: Function;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
const IconAdd: FunctionComponent<Props> = (props: Props) => {
    const { onClick, fill } = props;

    return (
        <div className="icon-add__icon" onClick={(): void => (onClick ? onClick() : {})}>
            <svg
                className="icon-add__svg"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    data-name="Path 926"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                    fill={fill}
                />
            </svg>
        </div>

    );
};

export default IconAdd;
