/**
*
* @Copyright 2023 VOID SOFTWARE, S.A.
*
*/

export enum PaymentType {
    MBWAY = 'MBWAY',
    CREDIT_CARD ='CREDIT_CARD'
}

export interface CCPayment {
    code: string | null;
    redirectUrl: string;
    reference: string;
    text: string;
    transactionID: string;
    transactionStatus: string;
}
