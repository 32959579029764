/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';

import { ReactSVG } from 'react-svg';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { DebouncedFunc, isEqual } from 'lodash';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../../controllers/user/UserContext';
import Avatar from '../../elements/Avatar';
import iconPin from '../../../assets/images/icon_pin.svg';
import FormField from '../../elements/FormField';
import { userURL } from '../../../services/users';
import { ManagerProfileFormFields, User, UserRoles } from '../../../types/user';
import { DropdownOption, KeyedObject, MatchParams } from '../../../constants/misc';
import { AppRoute } from '../../../constants/routes';
import { displayError, displaySuccess } from '../../../utils/notifications';
import { ErrorCode } from '../../../constants/errors';
import loader from '../../../assets/images/loader.svg';
import { SportestContext, withSportestContext } from '../../controllers/sportest/SportestContext';
import { Sportest } from '../../../constants/sportest';
import IconPhone from '../../assets/IconPhone';
import Map from '../../elements/Map';
import { enumToString } from '../../../utils/format';
import CoverImage from '../../elements/CoverImage';
import { IconBank } from '../../assets/IconBank';
import { IconGlobe } from '../../assets/IconGlobe';
import { IconHome } from '../../assets/IconHome';
import SelectCountry from '../../elements/SelectCountry';
import { SponsorContext, withSponsorContext } from '../../controllers/sponsor/SponsorContext';
import { SponsorAthletesSupport } from '../../../constants/sponsor';
import RouteLeavingGuard from '../RouteLeavingGuard';
import ScrollWrapper from '../../elements/ScrollWrapper';
import { buildRoute, validatePostalCode } from '../../../utils/misc';
import { CountryCode, Region } from '../../../types/country';
import { GeneralContext, withGeneralContext } from '../../controllers/general/GeneralContext';

interface OwnProps
    extends RouteComponentProps<MatchParams>,
        AuthenticationContext,
        TranslationContext,
        UserContext,
        SponsorContext,
        SportestContext,
        GeneralContext {}

type Props = OwnProps;

interface OwnState {
    changesMade: boolean;
    cover: any;
    avatar: any;
    userData: User | null;
    fields: ManagerProfileFormFields;
    errors: KeyedObject | null;
    isFetching: boolean;
    isFetchingAvatar: boolean;
    lastSportest: Sportest | null;
    provider: OpenStreetMapProvider | null;
    athletesSupportList: SponsorAthletesSupport[];
    totalAthletesSupport: number;
    paramsSponsorshipManager: Record<string, number>;
    isPostalCodeValid: boolean;
}

type State = OwnState;

const initialState: State = {
    changesMade: false,
    cover: null,
    avatar: null,
    userData: null,
    fields: {
        name: '',
        countryCode: null,
        postalCode: '',
        fiscalCode: '',
        address: '',
        phrase: '',
        biography: '',
        avatarId: null,
        coverId: null,
        lastName: '',
        objectives: '',
        sport: '',
        phone: '',
    },
    errors: null,
    isFetching: false,
    isFetchingAvatar: false,
    lastSportest: null,
    provider: null,
    athletesSupportList: [],
    totalAthletesSupport: 0,
    paramsSponsorshipManager: {
        _limit: 10,
        _page: 0,
    },
    isPostalCodeValid: false,
};

class ManagerProfileScreen extends Component<Props, State> {
    debouncedPostalCodeChange: DebouncedFunc<(countryCode: string, postalCode: string) => void>;

    private readonly avatarRef = React.createRef<HTMLInputElement>();

    private readonly coverRef = React.createRef<HTMLInputElement>();

    constructor(props: Props) {
        super(props);
        
        const { getRegion } = this.props;

        this.state = initialState;
        this.debouncedPostalCodeChange = validatePostalCode(getRegion, this.onPostalCodeValidation);
    }

    componentDidMount() {
        const { getRegion } = this.props;

        this.debouncedPostalCodeChange = validatePostalCode(getRegion, this.onPostalCodeValidation);

        this.prepare();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        const { fields } = this.state;
        const { fields: prevFields } = prevState;

        if (isEqual(prevFields, initialState.fields)
            || isEqual(fields, initialState.fields)
            || isEqual(prevFields, fields)
        ) return;

        this.setState({ changesMade: true });
    }
    /**
     * handlers
     */

    onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        const { fields } = this.state;

        this.setState((prevState) => ({
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    
        if (name === 'postalCode') {
            const { countryCode } = fields;
            let countryCodeValue = countryCode;
            
            if (countryCode && typeof countryCode === 'object' && 'value' in countryCode) {
                countryCodeValue = countryCode.value;
            }
        
            if (countryCodeValue === CountryCode.PT || countryCodeValue === CountryCode.ES) {
                this.debouncedPostalCodeChange(countryCodeValue, value);
            }
        }
    };

    onPostalCodeValidation = (region: Region | null) => {
        this.setState((prevState) => ({
            fields: {
                ...prevState.fields,
                address: region ? region.region : prevState.fields.address,
            },
            isPostalCodeValid: !!region,
        }));
    };

    onDropdownChange = (option: DropdownOption | null, name: string): void => {
        const { fields } = this.state;

        this.setState({
            fields: {
                ...fields,
                [name]: option,
            },
        });
    };

    onSubmit = (e: React.MouseEvent) => {
        e.preventDefault();

        const {
            validateManagerProfileUpdate, managerProfileUpdate, user, match: { params: { id } },
        } = this.props;
        const { fields } = this.state;

        const errors = validateManagerProfileUpdate(fields);

        this.setState({
            errors,
        }, () => {
            if (!errors) {
                managerProfileUpdate(fields, this.onSuccess, this.onFailure, user?.role === UserRoles.Admin ? id : undefined);
            }
        });
    };

    onSuccess = () => {
        const { t, user, history } = this.props;
        this.setState(
            {
                changesMade: false,
                fields: initialState.fields,
                errors: null,
            },
            () => {
                this.prepare();
                if (user?.role === UserRoles.Admin) {
                    displaySuccess({
                        message: t('admin.users.updated'),
                    });
                    return;
                }
                displaySuccess({
                    message: t('profile.updated'),
                });

                history.push(buildRoute(AppRoute.Manager, { id: user?.id }));
            },
        );
    };

    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors) {
            if (errors.fields) {
                this.setState({
                    errors: errors.fields,
                });
            }

            if (errors.errors) {
                errors.errors.forEach((error: any) => {
                    displayError({
                        message: t(`errors.${ErrorCode[error.errorCode]}`),
                    });
                });
            }
        }
    };

    onCoverFileDialog = () => {
        if (this.coverRef.current) {
            this.coverRef.current.click();
        }
    };

    onCoverSelected = (files: FileList | null) => {
        const { t } = this.props;
        if (!files) return;
        const { coverUpdate } = this.props;

        const reader = new FileReader();
        const file = files[0];

        reader.onloadend = () => {
            this.setState(
                {
                    cover: reader.result,
                    isFetching: true,
                },
                () => {
                    coverUpdate(
                        file,
                        (response: any) => {
                            this.setState((prevState: State) => ({
                                fields: {
                                    ...prevState.fields,
                                    coverId: response.data.id,
                                },
                                isFetching: false,
                            }));
                        },
                        () => {
                            this.setState({
                                isFetching: false,
                            });
                            displayError({
                                message: t('profile.uploadError'),
                            });
                        },
                    );
                },
            );
        };

        reader.readAsDataURL(file);
    };

    onAvatarFileDialog = () => {
        if (this.avatarRef.current) {
            this.avatarRef.current.click();
        }
    };

    onAvatarSelected = (files: FileList | null) => {
        const { t } = this.props;
        if (!files) return;
        const { avatarUpdate } = this.props;

        const reader = new FileReader();
        const file = files[0];

        reader.onloadend = () => {
            this.setState(
                {
                    avatar: reader.result,
                    isFetchingAvatar: true,
                },
                () => {
                    avatarUpdate(
                        file,
                        (response: any) => {
                            this.setState((prevState: State) => ({
                                fields: {
                                    ...prevState.fields,
                                    avatarId: response.data.id,
                                },
                                isFetchingAvatar: false,
                            }));
                        },
                        () => {
                            this.setState({
                                isFetchingAvatar: false,
                            });
                            displayError({
                                message: t('profile.uploadError'),
                            });
                        },
                    );
                },
            );
        };

        reader.readAsDataURL(file);
    };

    /**
     * requests & actions
     */

    prepare = async () => {
        const { user, history, match } = this.props;

        const userId = match?.params?.id || user?.id || '';
        const promises: [Promise<AxiosResponse>] = [axios.get(userURL(userId))];

        let res = [];

        try {
            res = await Promise.all(promises);
        } catch (error) {
            history.push(AppRoute.Index);
            return;
        }

        const [userResponse] = res;
        this.setState((prevState: State) => ({
            userData: userResponse.data,
            fields: {
                ...prevState.fields,
                name: userResponse.data.name || initialState.fields.name,
                lastName: userResponse.data.lastName || initialState.fields.lastName,
                countryCode: userResponse.data.countryCode || initialState.fields.countryCode,
                postalCode: userResponse.data.postalCode || initialState.fields.postalCode,
                fiscalCode: userResponse.data.fiscalCode || initialState.fields.fiscalCode,
                address: userResponse.data.address || initialState.fields.address,
                phrase: userResponse.data.phrase || initialState.fields.phrase,
                biography: userResponse.data.biography || initialState.fields.biography,
                phone: userResponse.data.phone || initialState.fields.phone,
                avatarId: userResponse.data.avatar?.id || initialState.fields.avatarId,
                coverId: userResponse.data.coverPhoto?.id || initialState.fields.coverId,
                sport: userResponse.data.modality || initialState.fields.sport,
            },
        }));
    };

    removeCoverImage = () => {
        const { fields, userData } = this.state;
        if (userData) {
            this.setState({
                cover: undefined,
                userData: {
                    ...userData,
                    coverPhoto: null,
                },
                fields: {
                    ...fields,
                    coverId: 0,
                },
            });
        }
    }

    removeUserImage = () => {
        const { fields, userData } = this.state;
        if (userData) {
            this.setState({
                avatar: undefined,
                userData: {
                    ...userData,
                    avatar: null,
                },
                fields: {
                    ...fields,
                    avatarId: 0,
                },
            });
        }
    }

    /**
     * renders
     */

    renderInputsFiles = () => {
        return (
            <React.Fragment>
                <input
                    id="cover-input"
                    type="file"
                    ref={this.coverRef}
                    data-testid="manager-cover-input"
                    onChange={(e) => this.onCoverSelected(e.target.files)}
                />
                <input
                    id="avatar-input"
                    type="file"
                    ref={this.avatarRef}
                    data-testid="manager-avatar-input"
                    onChange={(e) => this.onAvatarSelected(e.target.files)}
                />
            </React.Fragment>
        );
    };

    renderHeaderContent(): React.ReactNode {
        const {
            t,
        } = this.props;
        const {
            cover,
            avatar,
            userData,
            isFetchingAvatar,
        } = this.state;

        if (!userData) return null;

        const currentCover = userData.coverPhoto ? userData.coverPhoto.main : null;
        const currentAvatar = userData.avatar ? userData.avatar.main : null;

        return (
            <React.Fragment>
                <CoverImage image={cover || currentCover} onEdit={this.onCoverFileDialog} onDelete={this.removeCoverImage} tooltip={t('profile.coverTooltip')} />
                <ScrollWrapper>
                    {(isScrolling) => (
                        <div className={`buttons__profile ${isScrolling ? ' buttons__profile--scroll' : ''}`} data-testid={isScrolling ? ' buttons-scroll' : 'buttons'}>
                            <div className="buttons">
                                <button type="button" className="btn btn--primary-inverse" onClick={this.onSubmit}>
                                    {t('profile.save')}
                                </button>
                            </div>
                        </div>
                    )}
                </ScrollWrapper>
                {isFetchingAvatar && (
                    <div className="fetching fetching--avatar">
                        <ReactSVG wrapper="span" src={loader} />
                    </div>
                )}
                <Avatar image={avatar || currentAvatar} editable onEdit={this.onAvatarFileDialog} onDelete={this.removeUserImage} />
            </React.Fragment>
        );
    }

    renderManagerInfo(): React.ReactNode {
        const {
            t,
        } = this.props;
        const {
            errors,
            fields,
            isFetching,
        } = this.state;

        return (
            <div className="row athlete-info">
                <div className="col-xl-3 col-sm-6 col-xs-12">
                    <FormField
                        label={t('profile.name')}
                        type="text"
                        name="name"
                        value={fields.name}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className="col-xl-3 col-sm-6 col-xs-12">
                    <FormField
                        label={t('profile.surname')}
                        type="text"
                        name="lastName"
                        value={fields.lastName}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className="col-xl-3 col-sm-6 col-xs-12">
                    <h2>{fields.sport ? enumToString(fields.sport) : ''}</h2>
                </div>
            </div>
        );
    }

    renderManagerStats(): React.ReactNode {
        const {
            t,
        } = this.props;
        const {
            errors,
            fields,
            isFetching,
            isPostalCodeValid,
        } = this.state;

        const colClass = 'col-xl-3 col-lg-4 col-sm-6 col-xs-12 with-icon';

        return (
            <div className="row athlete-stats">
                <div className={colClass}>
                    <div className="icn">
                        <IconGlobe />
                    </div>
                    <SelectCountry
                        name="countryCode"
                        label={t('profile.country')}
                        value={fields.countryCode}
                        onChange={this.onDropdownChange}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <IconHome />
                    </div>
                    <FormField
                        label={t('profile.postalCode')}
                        type="text"
                        name="postalCode"
                        value={fields.postalCode}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <img src={iconPin} alt="" />
                    </div>
                    <FormField
                        label={t('profile.address')}
                        type="text"
                        name="address"
                        value={fields.address}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching || isPostalCodeValid}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn icn--phone">
                        <IconBank />
                    </div>
                    <FormField
                        label={t('profile.fiscalCode')}
                        type="text"
                        name="fiscalCode"
                        value={fields.fiscalCode}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <IconPhone />
                    <FormField
                        label={t('profile.phone')}
                        type="text"
                        name="phone"
                        value={fields.phone}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
            </div>
        );
    }

    renderProfileInfo = (): React.ReactNode => {
        const { t } = this.props;
        const { fields, errors, isFetching } = this.state;

        return (
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="content-box">
                        <h3 className="title">{t('profile.location')}</h3>
                        <Map location={fields.address} search />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <h3 className="title">{t('profile.aboutMe')}</h3>
                    <div className="content-box">
                        <FormField
                            label={t('profile.definition')}
                            type="text"
                            name="phrase"
                            value={fields.phrase}
                            onChange={this.onInputChange}
                            placeholder=""
                            disabled={isFetching}
                            multiline
                            errors={errors}
                        />
                        <FormField
                            label={t('profile.about')}
                            type="text"
                            name="biography"
                            value={fields.biography}
                            onChange={this.onInputChange}
                            placeholder=""
                            disabled={isFetching}
                            multiline
                            errors={errors}
                        />
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const {
            t,
            history,
        } = this.props;
        const {
            changesMade,
            userData,
            isFetching,
        } = this.state;

        if (!userData) return null;

        return (
            <React.Fragment>
                <RouteLeavingGuard
                    when={changesMade}
                    navigate={(pathName: string) => {
                        history.push(pathName);
                    }}
                    shouldBlockNavigation={() => true}
                    title={t('profile.noChangesPersistedConfirmation')}
                    confirmText={t('general.confirm')}
                    cancelText={t('general.cancel')}
                />
                <div className="profile-screen" data-testid="profile-screen">
                    <div className="profile-screen__body">
                        <div className="profile-screen__body__left with-overlay">
                            <div className="profile-screen__body__left__header">
                                {this.renderInputsFiles()}
                                {isFetching && (
                                    <div className="fetching">
                                        <ReactSVG wrapper="span" src={loader} />
                                    </div>
                                )}
                                {this.renderHeaderContent()}
                            </div>
                            <div className="profile-screen__body__left__content profile-screen__body__left__content__profile">
                                <div className="profile-top">
                                    <div className="profile-top__left">
                                        {this.renderManagerInfo()}
                                        <div className="hidden-xs stats-container">
                                            {this.renderManagerStats()}
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <div className="row hidden-sm hidden-md hidden-lg">
                                        <div className="col-sm-12">{this.renderManagerStats()}</div>
                                    </div>
                                    {this.renderProfileInfo()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withAuthenticationContext(
    withSponsorContext(withUserContext(withTranslationContext(withSportestContext(withGeneralContext(ManagerProfileScreen))))),
);
