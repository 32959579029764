/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { DebouncedFunc } from 'lodash';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import FormField from '../elements/FormField';
import { RegistrationFormFields, RegistrationPostData } from '../../constants/authentication';
import { DropdownOption, KeyedObject } from '../../constants/misc';
import { displayError, displaySuccess } from '../../utils/notifications';
import { termsURL } from '../../services/terms';
import { Terms } from '../../constants/terms';
import { ErrorCode } from '../../constants/errors';
import Dropdown from '../elements/Dropdown';
import { enumToOptions } from '../../utils/dropdown';
import { sportsURL } from '../../services/utils';
import { UserTypes } from '../../types/user';
import { AppRoute } from '../../constants/routes';
import { RECAPTCHA_KEY } from '../../settings';
import SelectCountry from '../elements/SelectCountry';
import RegistritionScreenWrapper from '../elements/RegistrationScreenWrapper';
import { CountryCode, Region } from '../../types/country';
import { GeneralContext, withGeneralContext } from '../controllers/general/GeneralContext';
import { validatePostalCode } from '../../utils/misc';

interface OwnProps extends RouteComponentProps, TranslationContext, GeneralContext {
    isFetching: boolean;
    close: () => void;
    submit: Function;
    validate: Function;
    userTypeSelected?: UserTypes | null;
}

type Props = OwnProps;

interface OwnState {
    agree: boolean;
    fields: RegistrationFormFields;
    errors: KeyedObject | null;
    terms: Terms | null;
    userType: DropdownOption | null;
    modality: DropdownOption | null;
    userTypeOptions: DropdownOption[];
    sportsOptions: DropdownOption[];
    hasReCaptchaError: boolean;
    recaptchaResponse: string;
    isPostalCodeValid: boolean;
}

const initialState: OwnState = {
    agree: false,
    userType: null,
    modality: null,
    fields: {
        name: '',
        lastName: '',
        countryCode: null,
        fiscalCode: '',
        postalCode: '',
        address: '',
        password: '',
        cpassword: '',
        email: '',
        modality: '',
        recaptcha: '',
    },
    errors: null,
    terms: null,
    userTypeOptions: [],
    sportsOptions: [],
    hasReCaptchaError: false,
    recaptchaResponse: '',
    isPostalCodeValid: false,
};

type State = OwnState;

class RegistrationScreen extends Component<Props, State> {
    debouncedPostalCodeChange: DebouncedFunc<(countryCode: string, postalCode: string) => void>;
    
    constructor(props: Props) {
        super(props);
        
        const { getRegion } = this.props;

        this.state = initialState;
        this.debouncedPostalCodeChange = validatePostalCode(getRegion, this.onPostalCodeValidation);
    }

    componentDidMount() {
        const { t, getRegion, userTypeSelected } = this.props;

        const userTypeOptions = [
            { label: t(`registration.${UserTypes.Athlete}`), value: UserTypes.Athlete },
            { label: t(`registration.${UserTypes.Fan}`), value: UserTypes.Fan },
            { label: t(`registration.${UserTypes.Sponsor}`), value: UserTypes.Sponsor },
            { label: t(`registration.${UserTypes.Manager}`), value: UserTypes.Manager },
        ];

        const userType = userTypeSelected ? userTypeOptions.find((opt) => opt.value === userTypeSelected) : null;

        this.setState({
            userTypeOptions,
            userType: userType || null,
        });

        this.debouncedPostalCodeChange = validatePostalCode(getRegion, this.onPostalCodeValidation);

        this.prepare();
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { fields } = this.state;
        
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });

        if (e.currentTarget.name === 'postalCode') {
            if (fields.countryCode?.value === CountryCode.PT || fields.countryCode?.value === CountryCode.ES) {
                this.debouncedPostalCodeChange(fields.countryCode?.value, e.currentTarget.value);
            }
        }
    };

    onPostalCodeValidation = (region: Region | null) => {
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                address: region ? region.region : prevState.fields.address,
            },
            isPostalCodeValid: !!region,
        }));
    };

    onCheckChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.checked,
        });
    };

    onDropDownChange = (option: DropdownOption, name: string): void => {
        const { fields } = this.state;

        if (name === 'modality') {
            this.setState({
                modality: option,
                fields: {
                    ...fields,
                    modality: option.value,
                },
            });
            return;
        }
        
        if (name === 'userType') {
            this.setState({
                userType: option,
            });
            return;
        }

        this.setState({
            fields: {
                ...fields,
                [name]: option,
            },
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { submit, validate, t } = this.props;
        const {
            fields, agree, terms, userType, modality, recaptchaResponse,
        } = this.state;

        if (!recaptchaResponse) {
            this.setState({
                hasReCaptchaError: true,
            });
            return;
        }

        if (agree) {
            const errors = validate(fields, userType?.value);
            
            this.setState(
                { errors },
                () => {
                    if (!errors && userType) {
                        const formData: RegistrationPostData = {
                            name: fields.name,
                            lastName: fields.lastName,
                            countryCode: fields.countryCode?.value,
                            fiscalCode: fields.fiscalCode,
                            postalCode: fields.postalCode,
                            address: fields.address,
                            password: fields.password,
                            email: fields.email,
                            acceptedTermsOfUse: terms ? terms.version : '',
                            modality: modality ? String(modality?.value) : '',
                            recaptcha: recaptchaResponse,
                        };
                        submit(formData, userType.value, this.onSuccess, this.onFailure);
                    }
                },
            );
        } else {
            displayError({
                message: t('registration.accept'),
            });
        }
    };

    onSuccess = () => {
        const { t, history, close } = this.props;

        this.setState({
            fields: initialState.fields,
            errors: null,
            agree: false,
        }, () => {
            displaySuccess({
                message: t('registration.link'),
            });
            close();
            history.push(AppRoute.Credit, { from: AppRoute.ValidateAcount });
        });
    };

    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors.fields) {
            this.setState({
                errors: errors.fields,
            });
        }

        if (errors.errors) {
            errors.errors.forEach((error: any) => {
                displayError({
                    message: t(`errors.${ErrorCode[error.errorCode]}`),
                });
            });
        }
    };

    onRecaptchaVerify = (response: string | null) => {
        if (response) {
            this.setState({
                ...this.state,
                hasReCaptchaError: false,
                recaptchaResponse: response,
            });
        } else {
            this.setState({
                ...this.state,
                hasReCaptchaError: true,
            });
        }
    }

    prepare = async () => {
        try {
            const { data } = await axios.get(termsURL());
            const sports = await axios.get(sportsURL());
            const sportsOptions = enumToOptions(sports.data, true);
                            
            this.setState({
                terms: data,
                sportsOptions,
            });
        } catch {}
    };

    render() {
        const {
            close, isFetching, t, userTypeSelected,
        } = this.props;
        const {
            agree, fields, errors, userType, userTypeOptions, sportsOptions, modality, hasReCaptchaError, isPostalCodeValid,
        } = this.state;

        return (
            <React.Fragment>
                <RegistritionScreenWrapper
                    closeRegistration={close}
                    agreeWithTerm={agree}
                    onCheckChange={this.onCheckChange}
                    isFetching={isFetching}
                >
                    <form onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <Dropdown
                                    label={t('registration.userType')}
                                    name="userType"
                                    options={userTypeOptions}
                                    value={userType}
                                    onChange={this.onDropDownChange}
                                    disabled={isFetching || !!userTypeSelected}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <FormField
                                    label={t('registration.email')}
                                    type="text"
                                    name="email"
                                    value={fields.email}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <FormField
                                    label={t('registration.name')}
                                    type="text"
                                    name="name"
                                    value={fields.name}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                {(userType?.value === UserTypes.Athlete || userType?.value === UserTypes.Manager) && (
                                    <FormField
                                        label={t('registration.lastName')}
                                        type="text"
                                        name="lastName"
                                        value={fields.lastName}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <SelectCountry
                                    name="countryCode"
                                    label={t('registration.country')}
                                    value={fields.countryCode}
                                    onChange={this.onDropDownChange}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <FormField
                                    label={t('registration.postalCode')}
                                    type="text"
                                    name="postalCode"
                                    value={fields.postalCode}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <FormField
                                    label={t('registration.address')}
                                    type="text"
                                    name="address"
                                    value={fields.address}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching || isPostalCodeValid}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <FormField
                                    label={t('registration.fiscalCode')}
                                    type="text"
                                    name="fiscalCode"
                                    value={fields.fiscalCode}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <FormField
                                    label={t('registration.password')}
                                    type="password"
                                    name="password"
                                    value={fields.password}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <FormField
                                    label={t('registration.repeat')}
                                    type="password"
                                    name="cpassword"
                                    value={fields.cpassword}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        {(userType?.value === UserTypes.Manager || userType?.value === UserTypes.Athlete) && (
                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <Dropdown
                                        label={t('profile.sport')}
                                        name="modality"
                                        options={sportsOptions}
                                        value={modality}
                                        onChange={this.onDropDownChange}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="recaptcha">
                            <ReCAPTCHA
                                sitekey={String(RECAPTCHA_KEY)}
                                onChange={this.onRecaptchaVerify}
                            />
                            <p className={hasReCaptchaError ? 'recaptcha-error' : ''}>
                                {t('registration.reCaptchaError')}
                            </p>

                        </div>
                        <div className="row">
                            <div className="register-button-container">
                                <button
                                    type="submit"
                                    className="btn btn--primary-inverse btn--block"
                                    disabled={isFetching}
                                >
                                    {t('registration.send')}
                                </button>
                            </div>
                        </div>
                    </form>
                </RegistritionScreenWrapper>
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslationContext(withGeneralContext(RegistrationScreen)));
