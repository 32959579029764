/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import FormField from '../elements/FormField';

import logo from '../../assets/images/logo_white.svg';
import runningImage from '../../assets/images/running.png';
import modalSlant from '../../assets/images/modal_slant.svg';
import { ResetPasswordFormFields, ResetPasswordPostData } from '../../constants/authentication';
import { KeyedObject } from '../../constants/misc';
import { validateForm, ValidationType, FormValidatorErrorType } from '../../utils/validations';
import { displayError, displaySuccess } from '../../utils/notifications';
import { ErrorCode } from '../../constants/errors';
import { AppRoute } from '../../constants/routes';

interface MatchParams {
    token: string;
}

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext {}

type Props = OwnProps;

interface OwnState {
    validated: boolean | null;
    fields: ResetPasswordFormFields;
    errors: KeyedObject | null;
}

type State = OwnState;

const initialState: State = {
    validated: false,
    fields: {
        newPassword: '',
        newPasswordConfirmation: '',
    },
    errors: null,
};

class ResetPasswordScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount(): void {
        const { history, match } = this.props;

        if (!match || !match.params || !match.params.token) history.push(AppRoute.Index);
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { resetPassword, match } = this.props;
        const { fields } = this.state;

        let errors = validateForm(this.state.fields, {
            newPassword: {
                validations: [ValidationType.NotBlank, ValidationType.PasswordStrength],
            },
            newPasswordConfirmation: {
                validations: [ValidationType.NotBlank, ValidationType.PasswordStrength],
            },
        });

        if (fields.newPassword !== fields.newPasswordConfirmation) {
            if (errors === null) errors = {};
            if (errors.newPasswordConfirmation === null || errors.newPasswordConfirmation === undefined) {
                errors.newPasswordConfirmation = [{ typeOfViolation: FormValidatorErrorType.PasswordsDontMatch }];
            }
        }

        this.setState(
            {
                ...this.state,
                errors,
            },
            () => {
                if (!errors) {
                    const formData: ResetPasswordPostData = {
                        newPassword: fields.newPassword,
                        token: match.params.token,
                    };
                    resetPassword(formData, this.onSuccess, this.onFailure);
                }
            },
        );
    };

    onSuccess = () => {
        const { openLogin, t } = this.props;

        this.setState(
            {
                ...this.state,
                fields: initialState.fields,
                errors: null,
            },
            () => {
                displaySuccess({
                    message: t('reset.success'),
                });
                openLogin();
            },
        );
    };

    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors.fields) {
            this.setState({
                ...this.state,
                errors: errors.fields,
            });
        }

        if (errors.errors) {
            errors.errors.forEach((error: any) => {
                displayError({
                    message: t(`errors.${ErrorCode[error.errorCode]}`),
                });
            });
        }
    };

    render() {
        const { isFetching, t } = this.props;
        const { validated, fields, errors } = this.state;

        if (validated !== false) return null;

        return (
            <div className="modal registration-screen" data-testid="login-screen">
                <div className="modal__backdrop" />
                <div className="modal__content">
                    <div className="modal__content__top">
                        <div className="modal__content__top__header">
                            <img src={logo} className="logo" alt="" />
                            <img src={runningImage} className="running" alt="" />
                            <img src={modalSlant} className="slant" alt="" />
                        </div>
                        <div className="modal__content__top__subheader">
                            <h1>{t('reset.title')}</h1>
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-xs-10 offset-xs-1">
                                <FormField
                                    label={t('reset.password')}
                                    type="password"
                                    name="newPassword"
                                    value={fields.newPassword}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-xs-10 offset-xs-1">
                                <FormField
                                    label={t('reset.confirmation')}
                                    type="password"
                                    name="newPasswordConfirmation"
                                    value={fields.newPasswordConfirmation}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-xs-10 offset-xs-1">
                                <button
                                    type="submit"
                                    className="btn btn--primary-inverse btn--block"
                                    disabled={isFetching}
                                >
                                    {t('reset.submit')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withRouter(ResetPasswordScreen)));
