/**
*
* @Copyright 2023 VOID SOFTWARE, S.A.
*
*/

import React, { FunctionComponent, useState } from 'react';
import { ReactSVG } from 'react-svg';

import {
    Step, StepLabel, Stepper, Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import logo from '../../assets/images/horizontal_logo.svg';
import IconTimes from '../assets/IconTimes';
import { Pack } from '../../constants/market';
import FormField from './FormField';
import { DropdownOption, KeyedObject } from '../../constants/misc';
import { REGEX, validateForm, ValidationType } from '../../utils/validations';
import { WalletContext, withWalletContext } from '../controllers/wallet/WalletContext';
import IconMBWay from '../assets/IconMBWay';
import loader from '../../assets/images/loader.svg';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { PHONE_NUMBER_LENGHT } from '../../types/credit';
import { PaymentType } from '../../types/payments';
import { IS_CC_PAYMENTS_ENABLED } from '../../settings';
import { IconCreditCard } from '../assets/IconCreditCard';
import { InvoiceInfo } from '../../types/invoice';
import { UserRoles } from '../../types/user';
import { AppRoute } from '../../constants/routes';
import { IconCheckCircleFilled } from '../assets/IconCheckCircleFilled';
import { buildRoute, TabType, validateNIF } from '../../utils/misc';
import InvoiceForm from './InvoiceForm';
import { CountryCode } from '../../types/country';
import TermsAndConditionsModal from './TermsAndConditionsModal';

interface OwnProps extends TranslationContext, WalletContext, AuthenticationContext {
    onClose: () => void;
    pack: Pack;
}

const CreditsPaymentModal: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, onClose, submitPayment, submitCreditCardPaymentRequest, user, pack,
    } = props;

    const history = useHistory();

    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
    const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [errors, setErrors] = useState<KeyedObject>({});
    const [invoiceInfo, setInvoiceInfo] = useState<InvoiceInfo>();
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentType>(PaymentType.MBWAY);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState<boolean>(false);

    const steps = [t('market.paymentSteps.step1'), t('market.paymentSteps.step2'), t('market.paymentSteps.step3')];

    const onRedirectSuccess = () => {
        setIsRedirecting(false);
    };

    const onSubmit = () => {
        if (selectedPaymentMethod === PaymentType.MBWAY) {
            submitMbWayPayment();
        } else if (selectedPaymentMethod === PaymentType.CREDIT_CARD) {
            submitCreditCardPayment();
        }
    };

    const onSubmitFailure = (formErrors: KeyedObject) => {
        setIsSuccessful(false);
        setIsFetching(false);
        setErrors(formErrors);
    };

    const onSubmitSuccess = () => {
        setIsFetching(false);
        setIsSuccessful(true);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const handleDropdownChange = (field: keyof InvoiceInfo) => (selectedOption: DropdownOption | null) => {
        if (!selectedOption) return;
        
        setInvoiceInfo((prevInfo) => ({
            ...prevInfo,
            [field]: { code: selectedOption.value, name: selectedOption.label },
        } as InvoiceInfo));
    };

    const handleInputChange = (field: keyof InvoiceInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        
        setInvoiceInfo((prevInfo) => ({
            ...prevInfo,
            [field]: value,
        } as InvoiceInfo));
    };

    const handleNext = () => {
        let validationErrors: KeyedObject = {};

        if (activeStep === 0) {
            if (selectedPaymentMethod === PaymentType.MBWAY) {
                validationErrors = validatePhoneNumber();
            }
        } else if (activeStep === 1) {
            validationErrors = validateInvoiceDetails();
        }
    
        if (Object.keys(validationErrors).length === 0) {
            setErrors({});

            if (activeStep === 2) {
                onSubmit();
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setErrors(validationErrors);
        }
    };
    
    const handlePaymentMethodChange = (newPaymentMethod: PaymentType) => {
        setErrors({});
        setSelectedPaymentMethod(newPaymentMethod);
    };

    const submitCreditCardPayment = () => {
        if (!invoiceInfo) return;

        setIsRedirecting(true);
        submitCreditCardPaymentRequest(pack, invoiceInfo, onRedirectSuccess);
    };

    const submitMbWayPayment = () => {
        if (!invoiceInfo) return;

        setIsFetching(true);
        submitPayment({
            phoneNumber,
            creditAmount: pack.credits,
            invoice: invoiceInfo,
        }, onSubmitSuccess, onSubmitFailure);
    };
    
    const redirectProfileRoute = () => {
        switch (user?.role) {
            case UserRoles.Athlete:
                history.push(buildRoute(AppRoute.Athlete, { id: user.id }));
                break;
            case UserRoles.Fan:
                history.push(buildRoute(AppRoute.Fan, { id: user.id }));
                break;
            case UserRoles.Manager:
                history.push(buildRoute(AppRoute.Manager, { id: user.id }));
                break;
            case UserRoles.Sponsor:
                history.push(buildRoute(AppRoute.Sponsor, { id: user.id }));
                break;
            default:
        }
    };
    
    const validatePhoneNumber = () => {
        const inputError = validateForm({ phoneNumber }, {
            phoneNumber: {
                validations: [ValidationType.NotBlank, ValidationType.Regex, ValidationType.MaxDigits],
                regex: REGEX.PHONE_NUMBER,
                max: PHONE_NUMBER_LENGHT.MAX,
            },
        }) || {};

        return inputError;
    };

    const validateInvoiceDetails = () => {
        const isCountryPT = invoiceInfo?.country?.code === CountryCode.PT;

        const validatePostalCode: KeyedObject = {
            validations: [ValidationType.NotBlank, ValidationType.Length, ...(isCountryPT ? [ValidationType.Regex] : [])],
            length: {
                lowerLimit: 0,
                upperLimit: 20,
            },
            ...(isCountryPT && { regex: REGEX.POSTAL_CODE }),
        };
        
        const formErrors = validateForm({ ...invoiceInfo }, {
            name: {
                validations: [ValidationType.NotBlank, ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 100,
                },
            },
            email: {
                validations: [ValidationType.NotBlank, ValidationType.Regex, ValidationType.Length],
                regex: REGEX.EMAIL,
                length: {
                    lowerLimit: 0,
                    upperLimit: 100,
                },
            },
            tin: {
                validations: [ValidationType.NotBlank, ValidationType.Regex, ValidationType.Length],
                regex: REGEX.TIN,
                length: {
                    lowerLimit: 0,
                    upperLimit: 20,
                },
            },
            country: { validations: [ValidationType.NotBlank] },
            city: {
                validations: [ValidationType.NotBlank, ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 50,
                },
            },
            address: {
                validations: [ValidationType.NotBlank, ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 100,
                },
            },
            postalCode: validatePostalCode,
        }) || {};

        if (invoiceInfo?.tin && (invoiceInfo?.country?.code === CountryCode.PT)) {
            const validNif = validateNIF(invoiceInfo.tin.trim());

            if (!validNif) {
                formErrors.tin = [{ typeOfViolation: 'InvalidNif' }];
            }
        }

        return formErrors;
    };

    const renderInvoiceDetails = () => {
        return (
            <div className="payment-modal__content__body__steps__content">
                <InvoiceForm
                    invoice={invoiceInfo}
                    onChangeDropdown={handleDropdownChange}
                    onChangeInput={handleInputChange}
                    inputErrors={errors}
                />
            </div>
        );
    };

    const renderPaymentTabs = () => {
        return (
            <div className="payment-modal__content__body__steps__content">
                <div className="payment-modal__content__body__tabs">
                    <button
                        type="button"
                        className={`custom-button ${selectedPaymentMethod === PaymentType.MBWAY ? 'active' : ''}`}
                        onClick={() => handlePaymentMethodChange(PaymentType.MBWAY)}
                        aria-label={t('market.payWithMbWay')}
                    >
                        <IconMBWay />
                        <p>{t('market.payWithMbWay')}</p>
                    </button>
                    {IS_CC_PAYMENTS_ENABLED && (
                        <button
                            type="button"
                            className={`custom-button-credit ${selectedPaymentMethod === PaymentType.CREDIT_CARD ? 'active' : ''}`}
                            onClick={() => handlePaymentMethodChange(PaymentType.CREDIT_CARD)}
                            aria-label={t('market.payWithCC')}
                        >
                            <IconCreditCard />
                            <p>{t('market.payWithCC')}</p>
                        </button>
                    )}
                </div>
                {selectedPaymentMethod === PaymentType.MBWAY && (
                    <div className="payment-modal__content__body__form">
                        <FormField
                            label={t('market.phoneNumber')}
                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={({ currentTarget: { value } }): void => setPhoneNumber(value)}
                            placeholder=""
                            errors={errors}
                            disabled={isFetching}
                        />
                    </div>
                )}
            </div>
        );
    };

    const renderReviewAndConfirm = () => {
        return (
            <div className="payment-modal__content__body__steps__content">
                <div className="payment-modal__content__body__credits-payment-info">
                    <span>{t('market.paymentInformationMessage')}</span>
                    <span className="bold">{` ${pack.credits} ${t('market.credits')} `}</span>
                    <span>{t('market.for')}</span>
                    <span className="bold">{` ${pack.price}€`}</span>
                </div>
                <div className="payment-modal__content__body__review">
                    <span className="bold">{t('market.paymentMethod')}</span>
                    <span className="details">{selectedPaymentMethod === PaymentType.MBWAY ? t('market.mbWay', { phoneNumber: decodeURIComponent(phoneNumber) }) : t('market.creditCard')}</span>
                </div>
                <div className="payment-modal__content__body__review__invoice">
                    <span className="bold">{t('invoice.invoice')}</span>
                    <span className="details">{`${t('invoice.name')}: ${invoiceInfo?.name}`}</span>
                    <span className="details">{`${t('invoice.email')}: ${invoiceInfo?.email}`}</span>
                    <span className="details">{`${t('invoice.tin')}: ${invoiceInfo?.tin}`}</span>
                    <span className="details">{`${t('invoice.country')}: ${invoiceInfo?.country.name}`}</span>
                    <span className="details">{`${t('invoice.city')}: ${invoiceInfo?.city}`}</span>
                    <span className="details">{`${t('invoice.address')}: ${invoiceInfo?.address}`}</span>
                    <span className="details">{`${t('invoice.postalCode')}: ${invoiceInfo?.postalCode}`}</span>
                </div>
                <div className="payment-modal__content__body__review__terms">
                    <p>{t('invoice.terms.terms_1')}</p>
                    <button type="button" onClick={() => setShowTermsAndConditions(true)}>
                        {t('invoice.terms.termsService')}
                    </button>
                    <p>{t('invoice.terms.terms_2')}</p>
                </div>
            </div>
        );
    };
    
    const renderStepContent = (step: number) => {
        switch (step) {
            case 0:
                return renderPaymentTabs();
            case 1:
                return renderInvoiceDetails();
            case 2:
                return renderReviewAndConfirm();
            default:
        }
    };

    return (
        <React.Fragment>
            <div className="payment-modal" data-testid="credits-payment-modal">
                {(isFetching || isRedirecting) && (
                    <div className="loader-backdrop">
                        <div className="loader" data-testid="loader">
                            <ReactSVG wrapper="span" src={loader} />
                        </div>
                    </div>
                )}
                <div className="payment-modal__backdrop" />
                <form className="payment-modal__content payment-modal__container">
                    <div className="payment-modal__content__header">
                        <img src={logo} alt="logo" />
                        <button type="button" onClick={onClose} aria-label={t('general.close')}>
                            <IconTimes />
                        </button>
                    </div>
                    {isSuccessful ? (
                        <div className="payment-modal__content__body__success">
                            <IconCheckCircleFilled />
                            <p>{t('market.mbWaySuccessPaymentInfo1')}</p>
                            <p>{t('market.mbWaySuccessPaymentInfo2')}</p>
                        </div>
                    ) : (
                        <div className="payment-modal__content__body__steps">
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <Typography className="instructions">
                                {renderStepContent(activeStep)}
                            </Typography>
                        </div>
                    )}
                    {isSuccessful ? (
                        <div className="payment-modal__content__footer__success">
                            <button
                                className="btn btn--primary-inverse"
                                type="button"
                                onClick={redirectProfileRoute}
                            >
                                {t('general.ok')}
                            </button>
                        </div>
                    ) : (
                        <div className="payment-modal__content__footer">
                            {!!activeStep && (
                                <button
                                    className="btn btn--primary"
                                    type="button"
                                    onClick={handleBack}
                                >
                                    {t('general.back')}
                                </button>
                            )}
                            <button
                                className="btn btn--primary-inverse"
                                type="button"
                                onClick={handleNext}
                            >
                                {activeStep === steps.length - 1 ? t('general.proceed') : t('general.confirm')}
                            </button>
                        </div>
                    )}
                </form>
            </div>
            {showTermsAndConditions && (
                <TermsAndConditionsModal
                    close={() => setShowTermsAndConditions(false)}
                    tab={TabType.PRIVACY_POLICY}
                    inRegister={false}
                />
            )}
        </React.Fragment>
    );
};

export default withTranslationContext(withWalletContext(withAuthenticationContext(CreditsPaymentModal)));
