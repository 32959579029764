/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { ReactSVG } from 'react-svg';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/horizontal_logo.svg';
import defaultAvatar from '../../assets/images/avatar_default.svg';
import { withTranslationContext, TranslationContext } from '../controllers/translation/TranslationContext';
import FormField from './FormField';
import { ApiError, DropdownOption, KeyedObject } from '../../constants/misc';
import loader from '../../assets/images/loader.svg';
import { CreditEditFields, CreditRequest, CREDIT_ACTION_TYPE } from '../../types/credit';
import { User } from '../../types/user';
import { withUserContext, UserContext } from '../controllers/user/UserContext';
import { withWalletContext, WalletContext } from '../controllers/wallet/WalletContext';
import Avatar from './Avatar';
import { fileURL } from '../../services/files';
import Dropdown from './Dropdown';
import { validateForm, ValidationType } from '../../utils/validations';
import { displaySuccess } from '../../utils/notifications';

interface OwnProps {
    user: User | null;
    onClose: () => void;
}

type Props = OwnProps & TranslationContext & UserContext & WalletContext;

const CreditEditModal: FunctionComponent<Props> = ({
    t,
    onClose,
    submitWalletTransfer,
    user,
    handleAPIErrors,
}: Props) => {
    const [isFetching, setIsFetching] = useState(false);
    const [fields, setFields] = useState<CreditEditFields | null>(null);
    const [amountResult, setAmountResult] = useState<number>(0);
    const [errors, setErrors] = useState<KeyedObject>({});
    const [charCount, setCharCount] = useState<number>(0);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const currentAvatar = user?.avatar ? fileURL(user?.avatar.id) : null;

    const userTypeOptions: DropdownOption[] = [
        { label: t('admin.creditManagement.actionType.giveCredits'), value: CREDIT_ACTION_TYPE.GIVE_CREDITS },
        { label: t('admin.creditManagement.actionType.takeCredits'), value: CREDIT_ACTION_TYPE.TAKE_CREDITS },
    ];

    const onSuccess = (): void => {
        setIsFetching(false);
        displaySuccess({ message: t('admin.creditManagement.transferSuccess') });
        onClose();
    };

    const onFailure = (newErrors?: ApiError): void => {
        setErrors(handleAPIErrors(newErrors, true)?.fields || {});
        setIsFetching(false);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        const formErrors = validateForm(fields || {}, {
            actionType: {
                validations: [ValidationType.NotBlank],
            },
            creditsNumber: {
                validations: [ValidationType.NotBlank, ValidationType.Number],
            },
            description: {
                validations: [ValidationType.NotBlank],
            },
        });
        
        if (formErrors) {
            setErrors(formErrors);
            return;
        }

        if (!fields) return;

        const payload: CreditRequest = {
            userId: user?.id,
            creditsNumber: fields.actionType?.value === userTypeOptions[0].value ? +fields.creditsNumber : -fields.creditsNumber,
            description: fields.description,
        };

        setIsFetching(true);
        submitWalletTransfer(payload, onSuccess, onFailure);
    };

    const onChangeDescription = (value: string): void => {
        const shouldSetValue = value.length <= 40;
        if (shouldSetValue) {
            setFields({ ...fields, description: value } as CreditEditFields);
            setCharCount(value.length);
        }
    };
    
    const calcAmountResult = useCallback(async (value: number) => {
        if (fields) {
            if (fields.actionType?.value === userTypeOptions[0].value) {
                setAmountResult((user?.availableCredits ? user?.availableCredits : 0) + value);
            } else if (fields.actionType?.value === userTypeOptions[1].value) {
                setAmountResult((user?.availableCredits ? user?.availableCredits : 0) - value);
            }
        }
    }, [fields, user, userTypeOptions]);

    useEffect(() => {
        calcAmountResult(fields?.creditsNumber ? +fields?.creditsNumber : 0);
        if (amountResult < 0 || (fields?.creditsNumber && +fields?.creditsNumber === 0)) {
            setCanSubmit(false);
        } else {
            setCanSubmit(true);
        }
    }, [fields, amountResult, calcAmountResult]);
    
    return (
        <div className="modal" data-testid="credit-edit-modal" aria-label="Edit credit modal">
            <div className="modal__backdrop" />
            { isFetching && (
                <div className="loader">
                    <ReactSVG wrapper="span" src={loader} />
                </div>
            )}
            <div className="modal--secondary modal__content">
                <div className="modal--secondary__header">
                    <img src={logo} alt="logo" />
                    <button type="button" onClick={onClose} aria-label="close">
                        <IconTimes fill="#278982" />
                    </button>
                </div>
                <div className="modal--secondary__title">
                    <span>{t('admin.creditManagement.title')}</span>
                    <i className="title title--alternative" />
                </div>

                <div className="credit-edit-modal__user-details">
                    <Avatar image={currentAvatar || defaultAvatar} />
                    {user?.name}
                    <span className="credit-edit-modal__amount">
                        {`${user?.availableCredits} ${t('market.creditsAbbreviation')}`}
                    </span>
                    <span className="credit-edit-modal__label">
                        {t('admin.creditManagement.creditBefore')}
                    </span>
                </div>

                <form className="modal--secondary__form credit-edit-modal__form" onSubmit={onSubmit}>
                    <Dropdown
                        label={t('admin.creditManagement.actionType.label')}
                        name="actionType"
                        options={userTypeOptions}
                        value={fields?.actionType || null}
                        onChange={(option: DropdownOption): void => setFields({ ...fields, actionType: option } as CreditEditFields)}
                        errors={errors}
                    />
                    <FormField
                        label={
                            `${t('admin.creditManagement.creditsNumber')}
                             ${fields?.actionType?.value === userTypeOptions[0].value
                                ? t('admin.creditManagement.giveTo')
                                : t('admin.creditManagement.takeFrom')
                             }
                             ${user?.name}`
                        }
                        type="number"
                        name="creditsNumber"
                        value={fields?.creditsNumber}
                        onChange={({ currentTarget: { value } }): void => setFields({ ...fields, creditsNumber: value } as CreditEditFields)}
                        placeholder=""
                        errors={errors}
                    />
                    <FormField
                        label={`${t('admin.creditManagement.description')} (${charCount}/40)`}
                        type="text"
                        name="description"
                        value={fields?.description}
                        onChange={({ currentTarget: { value } }): void => onChangeDescription(value)}
                        placeholder=""
                        errors={errors}
                    />
                    <button type="submit" hidden />
                </form>
                <div className="credit-edit-modal__result">
                    <span className={`credit-edit-modal${canSubmit ? '__amount' : '__amount-negative'}`}>
                        {`${amountResult} ${t('market.creditsAbbreviation')}`}
                    </span>
                    <span className="credit-edit-modal__label">
                        {t('admin.creditManagement.creditAfter')}
                    </span>
                </div>
                <div className="modal--secondary__actions credit-edit-modal__actions">
                    <button
                        className="btn btn--primary"
                        type="button"
                        onClick={onClose}
                        data-testid="credit-edit-modal-cancel"
                    >
                        {t('general.cancel')}
                    </button>
                    <button
                        className={`btn btn--primary-inverse ${canSubmit ? 'enabled' : 'disabled'}`}
                        type="button"
                        onClick={onSubmit}
                        disabled={!canSubmit}
                        data-testid="credit-edit-modal-submit"
                    >
                        {t('general.confirm')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default withUserContext(
    withTranslationContext(
        withWalletContext(
            CreditEditModal,
        ),
    ),
);
