/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { KeyedObject } from '../../../constants/misc';
import { SportestCreate } from '../../../constants/sportest';
import { ConnectedSportestController } from './SportestController';

export interface SportestContext {
    pendingSportests: SportestCreate[];
    createSportestFetching: boolean;
    getAnswers(): Promise<any>;
    validateNewSportest(fields: SportestCreate): KeyedObject | null;
    submitNewSportest(payload: SportestCreate, onSuccess: Function, onFailure: Function): void;
    addSportest(payload: SportestCreate): void;
    submitPendingSportests(): void;
}

export const sportestContextDefaultValue = {
    pendingSportests: [],
    createSportestFetching: false,
    getAnswers: () => {
        return new Promise(() => {});
    },
    validateNewSportest: () => {
        return null;
    },
    submitNewSportest: () => {},
    addSportest: () => {},
    submitPendingSportests: () => {},
};

const SportestContext = createContext<SportestContext | null>(sportestContextDefaultValue);

export const SportestContextProvider = SportestContext.Provider;
export const SportestContextConsumer = SportestContext.Consumer;

export const withSportestContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof SportestContext>> => props => (
    <ConnectedSportestController>
        <SportestContextConsumer>{ctx => <Component {...(props as P)} {...ctx} />}</SportestContextConsumer>
    </ConnectedSportestController>
    );
