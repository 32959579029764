/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedTranslationController } from './TranslationController';

import { Language } from '../../../constants/preferences';
import { FormValidatorError } from '../../../utils/validations';
import { ApiError } from '../../../constants/misc';

export interface TranslationContext {
    language: Language;
    t(literal: string, params?: object): string;
    handleAPIErrors(errors?: ApiError | null, generalErrorFallback?: boolean): { fields: Record<string, FormValidatorError[]> } | null ;
}

// Create the context

export const translationContextDefaultValue = {
    language: Language.EN,
    t: () => '',
    handleAPIErrors: (): null => null,
};

const TranslationContext = createContext<TranslationContext | null>(translationContextDefaultValue);

// Export Provider and Consumer

export const TranslationContextProvider = TranslationContext.Provider;
export const TranslationContextConsumer = TranslationContext.Consumer;

/**
 * provides a context HOC that components can use to get translations
 */
export const withTranslationContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof TranslationContext>> => (props) => (
    <ConnectedTranslationController>
        <TranslationContextConsumer>{(ctx) => <Component {...(props as P)} {...ctx} />}</TranslationContextConsumer>
    </ConnectedTranslationController>
    );
