/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'fans';

/**
 * Fans URL
 * @returns {string}
 */
export const fansURL = (): string => {
    return `${API_URL}/${api}`;
};

/**
 * Fan URL
 * @param {number | string} [id]
 * @returns {string}
 */
export const fanURL = (id: number | string): string => {
    return `${API_URL}/${api}/${id}`;
};

/**
 * Fan Media URL
 * @param {number | string} [id]
 * @returns {string}
 */
export const fanMediaURL = (id: number | string): string => {
    return `${API_URL}/${api}/${id}/media`;
};

/**
 * Fan delete Media URL
 * @param {number | string} [mediaId]
 * @returns {string}
 */
export const fanDeleteMediaURL = (mediaId: number | string): string => {
    return `${API_URL}/${api}/media/${mediaId}`;
};
