/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'managers';

/**
 * Managers URL
 * @returns {string}
 */
export const managersURL = (): string => {
    return `${API_URL}/${api}`;
};

/**
 * Manager URL
 * @returns {string}
 */
export const managerURL = (id: React.Key): string => {
    return `${API_URL}/${api}/${id}`;
};

/**
 * Managers invite URL
 * @returns {string}
 */
export const managerInviteURL = (): string => {
    return `${API_URL}/${api}/finalize-invitation`;
};

export const managerMediaURL = (id: number | string): string => {
    return `${API_URL}/${api}/${id}/media`;
};

export const managerDeleteMediaURL = (mediaId: number | string): string => {
    return `${API_URL}/${api}/media/${mediaId}`;
};
