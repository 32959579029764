/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'locations';

export const regionURL = (code: string, postalCode: string): string => {
    return `${API_URL}/${api}/country/${code}/postal-code/${postalCode}`;
};

export const regionsURL = (code: string): string => {
    return `${API_URL}/${api}/country/${code}`;
};
