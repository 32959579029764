/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedPreferencesController } from './PreferencesController';

import { Language, Theme } from '../../../constants/preferences';

/**
 * @typedef {Object} PreferencesContext
 * @property {Language} language
 * @property {Theme} theme
 * @property {Function} changeLanguage
 * @property {Function} changeTheme
 * @property {Function} getPreferences
 */
export interface PreferencesContext {
    language: Language;
    theme: Theme;
    changeLanguage(language: Language): void;
    changeTheme(theme: Theme): void;
    getPreferences(): void;
}

// Create the context

export const preferencesContextDefaultValue = {
    language: Language.EN,
    theme: Theme.Light,
    changeLanguage: () => {},
    changeTheme: () => {},
    getPreferences: () => {},
};

const PreferencesContext = createContext<PreferencesContext | null>(preferencesContextDefaultValue);

// Export Provider and Consumer

export const PreferencesContextProvider = PreferencesContext.Provider;
export const PreferencesContextConsumer = PreferencesContext.Consumer;

/**
 * provides a context HOC that components can use to subscribe as consumers
 * @param Component
 * @returns {React.FC}
 */
export const withPreferencesContext = <P extends object>(
    Component: ComponentType<P>
): FC<Omit<P, keyof PreferencesContext>> => (props) => (
    <ConnectedPreferencesController>
        <PreferencesContextConsumer>{(ctx) => <Component {...(props as P)} {...ctx} />}</PreferencesContextConsumer>
    </ConnectedPreferencesController>
);
