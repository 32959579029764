/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 * @property {string} fill
 * @property {Function} onClick
 */
interface OwnProps {
    fill: string;
    onClick: Function;
    testId: string;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
const IconCheckedBox: FunctionComponent<Props> = (props: Props) => {
    const { onClick, fill, testId } = props;

    return (
        <div className="icon-checkedbox__icon" onClick={() => onClick()} data-testid={testId}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                    fill={fill}
                    d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                />
            </svg>
        </div>
    );
};

export default IconCheckedBox;
