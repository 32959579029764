/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { RolePermission } from '../../../constants/authorization';

import { ConnectedAuthorizationController } from './AuthorizationController';

/**
 * @typedef {Object} AuthorizationContext
 * @property {RolePermission[]} permissions
 * @property {Function} getPermissions
 */
export interface AuthorizationContext {
    permissions: RolePermission[];
    getPermissions(): void;
}

// Create the context

export const authorizationContextDefaultValue = {
    permissions: [],
    getPermissions: () => {},
};

const AuthorizationContext = createContext<AuthorizationContext | null>(authorizationContextDefaultValue);

// Export Provider and Consumer

export const AuthorizationContextProvider = AuthorizationContext.Provider;
export const AuthorizationContextConsumer = AuthorizationContext.Consumer;

/**
 * provides a context HOC that components can use to subscribe as consumers
 * @param Component
 * @returns {React.FC}
 */
export const withAuthorizationContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof AuthorizationContext>> => props => (
    <ConnectedAuthorizationController>
        <AuthorizationContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </AuthorizationContextConsumer>
    </ConnectedAuthorizationController>
);
