/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 */
interface OwnProps {
    size?: string;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
export const IconCheckCircleFilled: FunctionComponent<Props> = (props: Props) => {
    const { size } = props;
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size || '13.362'} height={size || '13.362'} viewBox="0 0 13.362 13.362">
            <path d="M8.931,2.25a6.681,6.681,0,1,0,6.681,6.681A6.681,6.681,0,0,0,8.931,2.25ZM7.976,11.6,5.59,9.213l.759-.759,1.627,1.627,3.536-3.536.762.757Z" transform="translate(-2.25 -2.25)" fill="#278982"/>
        </svg>
    );
};
