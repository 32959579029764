/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { useState, useRef, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/horizontal_logo.svg';
import { withTranslationContext, TranslationContext } from '../controllers/translation/TranslationContext';
import FormField from './FormField';
import camera from '../../assets/images/icon_camera.svg';
import Switch from './Switch';
import { SponsorProduct, SponsorProductsRequest } from '../../constants/sponsor';
import { withSponsorContext, SponsorContext } from '../controllers/sponsor/SponsorContext';
import { ApiError, KeyedObject, PRODUCT_DESCRIPTION_MAX_LENGTH } from '../../constants/misc';
import deleteIcon from '../../assets/images/delete.svg';
import { FormValidatorErrorType, validateForm, ValidationType } from '../../utils/validations';
import InputError from './FormFieldError';
import loader from '../../assets/images/loader.svg';
import { withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { displayError } from '../../utils/notifications';

interface OwnProps {
    product: SponsorProduct | null;
    onClose: () => void;
    onSave: () => void;
}

const descriptionError = { typeOfViolation: FormValidatorErrorType.Size, max: 2000 };

type Props = OwnProps & TranslationContext & SponsorContext;

const ProductModal: React.FC<Props> = (props: Props) => {
    const {
        product, t, onClose, submitProduct, onSave, handleAPIErrors,
    } = props;

    const [isFetching, setIsFetching] = useState(false);
    const [fields, setFields] = useState<SponsorProductsRequest | null>(product ? { ...product, file: null } : null);
    const [imagePreviewURL, setImagePreviewURL] = useState(product?.imagePath?.thumbnail);
    const [errors, setErrors] = useState<KeyedObject>({});

    const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if ((fields?.description?.length ?? 0) > PRODUCT_DESCRIPTION_MAX_LENGTH) {
            setErrors((prevErrors) => ({ ...prevErrors, description: [descriptionError] }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, description: undefined }));
        }
    }, [fields]);
    
    const onChange = (name: string, value: unknown): void => setFields({ ...fields, [name]: value } as SponsorProductsRequest);

    const success = (): void => {
        setIsFetching(false);
        onClose();
        onSave();
    };

    const failure = (newErrors?: ApiError, errorStatus?: number): void => {
        if (errorStatus === 403) {
            displayError({ message: t('profile.uploadRoleError') });
        } else {
            setErrors(handleAPIErrors(newErrors, true)?.fields || {});
        }
        setIsFetching(false);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        const formErrors = validateForm(fields || {}, {
            title: {
                validations: [ValidationType.NotBlank],
            },
            description: {
                validations: [ValidationType.NotBlank],
            },
            credits: {
                validations: [ValidationType.NotBlank, ValidationType.Number, ValidationType.MaxDigits],
                max: 6,
            },
            file: {
                // if editing no need to check if file is present
                validations: product ? [ValidationType.FileSize] : [ValidationType.NotBlank, ValidationType.FileSize],
                max: 2,
            },
        });
        
        if (formErrors) {
            setErrors(formErrors);
            return;
        }
        
        if (!fields) return;
        
        setIsFetching(true);
        submitProduct({ ...fields }, success, failure);
    };

    const onImageChange = (e?: React.ChangeEvent<HTMLInputElement>): void => {
        const file = e?.currentTarget.files?.[0];

        onChange('file', file || null);
        setImagePreviewURL(file ? URL.createObjectURL(file) : '');

        if (fileRef.current) {
            fileRef.current.value = '';
        }
    };
    
    return (
        <div className="modal">
            <div className="modal__backdrop" />
            {
                isFetching && (
                    <div className="loader">
                        <ReactSVG wrapper="span" src={loader} />
                    </div>
                )
            }
            <div className="product-modal modal__content">
                <div className="product-modal__header">
                    <img src={logo} alt="logo" />
                    <button type="button" onClick={onClose}>
                        <IconTimes fill="#278982" />
                    </button>
                </div>
                <div className="product-modal__title">
                    <span>{product ? t('productModal.editing') : t('productModal.creating')}</span>
                    <i className="title title--alternative" />
                </div>
                <form className="product-modal__form" onSubmit={onSubmit}>
                    <div className="product-modal__form__left">
                        <input ref={fileRef} type="file" name="image" hidden accept="image/*" onChange={onImageChange} />
                        <div>
                            <button type="button" className="product-modal__form__left__image" onClick={(): void => (imagePreviewURL ? onImageChange() : fileRef.current?.click())}>
                                {!imagePreviewURL ? (
                                    <React.Fragment>
                                        <img key="image-placeholder" src={camera} alt="camera" />
                                        <span>{t('productModal.form.addImage')}</span>
                                        <span>{t('productModal.form.imageHint')}</span>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <img key="image-preview" className="product-modal__form__left__image__preview" src={imagePreviewURL} alt="preview product" />
                                        <ReactSVG wrapper="span" className="delete" src={deleteIcon} />
                                    </React.Fragment>
                                )}
                            </button>
                            <InputError errors={errors.file} field="file" />
                        </div>
                        <FormField
                            name="credits"
                            onChange={({ currentTarget: { name, value } }): void => onChange(name, value)}
                            value={fields?.credits}
                            label={t('productModal.form.salePrice')}
                            cssClass="product-modal__form__left__input"
                            errors={errors}
                        />
                    </div>
                    <div className="product-modal__form__right">
                        <FormField
                            name="title"
                            onChange={({ currentTarget: { name, value } }): void => onChange(name, value)}
                            value={fields?.title}
                            label={t('productModal.form.title')}
                            cssClass="product-modal__form__right__input"
                            errors={errors}
                        />
                        <FormField
                            name="description"
                            multiline
                            rows={6}
                            onChange={({ currentTarget: { name, value } }): void => onChange(name, value)}
                            value={fields?.description}
                            label={`
                                ${t('productModal.form.description.label')} 
                                ${((fields?.description?.length ?? 0) <= PRODUCT_DESCRIPTION_MAX_LENGTH)
                                ? t('productModal.form.description.charsLeft', { chars: PRODUCT_DESCRIPTION_MAX_LENGTH - (fields?.description?.length ?? 0) })
                                : ''
                            }`}
                            cssClass="product-modal__form__right__input product-modal__form__right__textarea"
                            errors={errors}
                        />
                    </div>
                    <button type="submit" hidden />
                </form>
                <span className="product-modal__delivery__info">
                    {t('productModal.aboutDeliveryCost')}
                </span>
                <div className="product-modal__bottom__actions">
                    <Switch name="active" onChange={onChange} value={fields?.active} label={t('productModal.switch.label')} status="productModal.switch" />
                    <button className="btn btn--primary-inverse" type="button" onClick={onSubmit}>{t('general.ok')}</button>
                </div>
            </div>
        </div>
        
    );
};

export default withAuthenticationContext(withSponsorContext(withTranslationContext(ProductModal)));
