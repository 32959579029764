/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import logo from '../../assets/images/logo_center.svg';
import closeIcon from '../../assets/images/icon_close_green.svg';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { DropdownOption, KeyedObject, MatchParams } from '../../constants/misc';
import { displayError } from '../../utils/notifications';
import { ErrorCode } from '../../constants/errors';
import {
    AchievementFields, AGE_GROUP, COMPETITION_LEVEL, Achievement, AchievementRequest,
} from '../../constants/achievements';
import Dropdown from './Dropdown';
import { enumToOptions } from '../../utils/dropdown';
import { withUserContext, UserContext } from '../controllers/user/UserContext';
import Datepicker from './Datepicker';
import { throttle } from '../../utils/misc';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext}
 * @extends {UserContext}
 * @property {boolean} isFetching
 * @property {string} sport
 * @property {Function} close
 * @property {addToList} addToList
 */
interface OwnProps extends TranslationContext, RouteComponentProps<MatchParams>, UserContext {
    isFetching: boolean;
    sport: string;
    close: Function;
    addToList: (achievement: Achievement) => void;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @property {DropdownOption[]} [clubsOptions]
 */
interface OwnState {
    fields: AchievementFields;
    errors: KeyedObject | null;
    clubsOptions: DropdownOption[];
}

const initialState: OwnState = {
    fields: {
        ageGroup: null,
        club: null,
        competitionLevel: null,
        date: new Date(),
        result: null,
    },
    errors: null,
    clubsOptions: [],
};

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * shows the new achievement form
 * @extends {Component<Props, State>}
 */
class NewAchievementFormScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount(): void {
        this.onInputDropDownChange('');
    }

    /**
     * handles change on dropdown
     * @param {DropdownOption} [option]
     */
    onInputChange = (option: DropdownOption, name: string): void => {
        const { fields } = this.state;

        this.setState({
            fields: {
                ...fields,
                [name]: option,
            },
        });
    };

    /**
     * handles form submit
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        const { addNewAchievement, sport } = this.props;
        const { fields } = this.state;
        e.preventDefault();
        
        const payload: AchievementRequest = {
            ageGroup: fields.ageGroup?.value,
            club: fields.club ? { clubCompositeKey: { clubName: fields.club?.value, sport } } : null,
            competitionLevel: fields.competitionLevel?.value,
            date: fields.date,
            result: fields.result?.value,
        };

        addNewAchievement(payload, this.onFailure, this.onFormSubmitSuccess);
    };

    onFormSubmitSuccess = (payload: Achievement): void => {
        const { addToList } = this.props;
        addToList(payload);
    };

    onInputDropDownChange = (newValue: string): void => {
        const { getClubsBySport, sport } = this.props;

        getClubsBySport(sport).then((clubs) => {
            this.setState({
                clubsOptions: [...enumToOptions(clubs), { label: newValue, value: newValue }],
            });
        });
    };

    /**
     * handles save failure
     * @param {KeyedObject} errors
     */
    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors) {
            if (errors.fields) {
                this.setState({
                    errors: errors.fields,
                });
            }

            if (errors.errors) {
                errors.errors.forEach((error: any) => {
                    displayError({
                        message: t(`errors.${ErrorCode[error.errorCode]}`),
                    });
                });
            }
        }
    };

    onClose = (): void => {
        const { close } = this.props;
        close();
    };

    render(): React.ReactNode {
        const { t } = this.props;

        const {
            fields, clubsOptions, errors,
        } = this.state;

        return (
            <div className="modal" data-testid="new-achievement">
                <div className="modal__test" />
                <div className="modal__content__test test">
                    <div className="test-screen">
                        <div className="test-screen__content">
                            <span className="green-title">{t('achievements.title')}</span>
                            <img
                                src={closeIcon}
                                alt=""
                                className="close"
                                onClick={this.onClose}
                                data-testid="close-modal"
                            />
                            <img src={logo} alt="" className="logo" />
                            <form onSubmit={this.onSubmit}>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                        <h3>{t('achievements.date')}</h3>
                                        <Datepicker
                                            name="date"
                                            value={fields.date}
                                            onChange={(date: any) => {
                                                this.setState({
                                                    fields: {
                                                        ...fields,
                                                        date,
                                                    },
                                                });
                                            }}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        <h3>{t('achievements.competitionLevel.label')}</h3>
                                        <Dropdown
                                            name="competitionLevel"
                                            options={enumToOptions(Object.keys(COMPETITION_LEVEL))}
                                            value={fields.competitionLevel}
                                            onChange={this.onInputChange}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                        <h3>{t('achievements.club')}</h3>
                                        <Dropdown
                                            name="club"
                                            options={clubsOptions}
                                            value={fields.club}
                                            onChange={this.onInputChange}
                                            onInputChange={throttle(this.onInputDropDownChange, 1000)}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        <h3>{t('achievements.ageGroup.label')}</h3>
                                        <Dropdown
                                            name="ageGroup"
                                            options={enumToOptions(Object.keys(AGE_GROUP))}
                                            value={fields.ageGroup}
                                            onChange={this.onInputChange}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-sm-6 col-xs-12">
                                        <h3>{t('achievements.result')}</h3>
                                        <Dropdown
                                            name="result"
                                            options={[...Array(50)].map((_, i) => ({ label: t(`achievements.position.${i + 1}`), value: i + 1 }))}
                                            value={fields.result}
                                            onChange={this.onInputChange}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <button type="submit" className="green-btn">
                                        {t('achievements.submit')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withUserContext(withRouter(NewAchievementFormScreen)));
