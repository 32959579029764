/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import { ReactNode } from 'react';

type QueryParamsRecord = string | number | boolean | null | undefined | string[];
export type QueryParams = Record<string, QueryParamsRecord>;

export interface SelectOption<T = string | number>{
    label: ReactNode;
    value: T;
}

const addParam = (previousParams: string, paramName: string, value: string) => {
    const newParam = value.trim() === '' ? paramName : `${paramName}=${encodeURIComponent(value)}`;

    return previousParams === '' ? newParam : `${previousParams}&${newParam}`;
};

export const objectToQueryParams = (obj?: Record<string, unknown>, useQ = true): string => {
    if (!obj) return '';

    let params = '';

    Object.keys(obj).forEach((paramName) => {
        const value = obj[paramName];

        if (value === undefined || value === null) return;

        if (Array.isArray(value)) {
            if (value.length === 0) return;

            value.forEach((v) => {
                if (v !== undefined && v !== null && typeof v !== 'object') {
                    params = addParam(params, paramName, String(v));
                }
            });

            return;
        }

        params = addParam(params, paramName, String(value));
    });

    return useQ && params ? `?${params}` : params;
};
