/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, useState, useEffect,
} from 'react';
import { ReactSVG } from 'react-svg';
import { format } from 'date-fns';
import bigPinIcon from '../../assets/images/icon_pin_big.svg';
import trashIcon from '../../assets/images/delete.svg';
import IconAdd from '../assets/IconAdd';
import { Achievement } from '../../constants/achievements';
import { withTranslationContext, TranslationContext } from '../controllers/translation/TranslationContext';
import { withUserContext } from '../controllers/user/UserContext';
import { IconLeftArrow } from '../assets/IconLeftArrow';
import { IconRightArrow } from '../assets/IconRightArrow';
import { ConfirmationModal } from './ConfirmationModal';
import { Direction } from '../../constants/misc';

interface OwnProps {
    onAdd?: () => void;
    onDelete?: (id: number) => void;
    achievements: Achievement[];
}

interface AchievementWithRef extends Achievement {
    ref: React.RefObject<HTMLDivElement>;
}

type Props = OwnProps & TranslationContext;

const SCROLL_WIDTH = 150;

export const Timeline: FunctionComponent<OwnProps> = withUserContext(
    withTranslationContext((props: Props) => {
        const {
            onAdd, onDelete, t,
        } = props;

        const achievements: AchievementWithRef[] = props.achievements.map((achievement) => ({ ...achievement, ref: React.createRef() }));

        const timelineElement = React.createRef<HTMLDivElement>();
        const [showButtons, setShowButtons] = useState(false);
        const [deleteConfirmation, setDeleteConfirmation] = useState(false);
        const [selectedAchievement, setSelectedAchievement] = useState<AchievementWithRef | null>(null);
        const [iconsCount, setIconsCount] = useState(0);
        
        useEffect(() => {
            const timelineContainer = timelineElement.current;
            if (!timelineContainer) return;
            
            timelineContainer.scrollLeft = timelineContainer.scrollWidth;
            // eslint-disable-next-line
        }, [iconsCount]);
        
        useEffect(() => {
            const timelineContainer = timelineElement.current;
            if (!timelineContainer) return;
            
            setSelectedAchievement(null);
            setShowButtons(timelineContainer.scrollWidth > timelineContainer.clientWidth);
            // eslint-disable-next-line
        }, [achievements.length]);
        
        useEffect(() => {
            setIconsCount((timelineElement.current?.scrollWidth || 0) / 26);
            // eslint-disable-next-line
        }, [timelineElement.current?.scrollWidth]);

        const renderTooltip = (achievement: AchievementWithRef): React.ReactNode => {
            const domPosition = achievement.ref.current?.getBoundingClientRect();
            const position = t(`achievements.position.${achievement.result}`);

            const achievementAgeGroup = t(`achievements.ageGroup.${achievement.ageGroup}`);
            const achievementCompetitionLevel = t(`achievements.competitionLevel.${achievement.competitionLevel}`);

            return (
                <div className="timeline__body__tooltip" style={{ left: domPosition?.left }}>
                    <span>{`${t('achievements.date')}: ${achievement.date}`}</span>
                    <span>{`${t('achievements.competitionLevel.label')}: ${achievementCompetitionLevel}`}</span>
                    <span>{`${t('achievements.club')}: ${achievement.club.clubCompositeKey.clubName}`}</span>
                    <span>{`${t('achievements.ageGroup.label')}: ${achievementAgeGroup}`}</span>
                    <span>{`${t('achievements.result')}: ${position}`}</span>
                </div>
            );
        };

        const timelineEntry = (achievement: AchievementWithRef): React.ReactNode => {
            return (
                <div className="timeline__body__entry" key={achievement.id} data-testid="timeline-entry">
                    <div
                        className="timeline__body__entry__pin"
                        onMouseEnter={() => setSelectedAchievement(achievement)}
                        onMouseLeave={() => setSelectedAchievement(null)}
                        ref={achievement.ref}
                    >
                        <ReactSVG className="timeline__body__entry__pin" wrapper="span" src={bigPinIcon} />
                        {
                            onDelete && (
                                <ReactSVG
                                    data-testid="delete-icon"
                                    onClick={(): void => {
                                        setDeleteConfirmation(true);
                                    }}
                                    className="timeline__body__entry__pin__delete"
                                    wrapper="span"
                                    src={trashIcon}
                                />
                            )
                        }
                        <span className="timeline__body__entry__pin__text">{t(`achievements.position.${achievement.result}`)}</span>
                    </div>
                    <i className="timeline__body__entry__dot" />
                    <span className="timeline__body__entry__date">
                        { format(new Date(achievement.date), 'MMM, yyyy') }
                    </span>
                </div>
            );
        };

        const scroll = (direction: Direction): void => {
            if (!timelineElement.current) {
                return;
            }

            const amountToScroll = direction === Direction.LEFT ? -SCROLL_WIDTH : SCROLL_WIDTH;

            timelineElement.current.scrollLeft += amountToScroll;
        };

        const renderTrail = () => {
            return (
                <div className="timeline__body__trail">
                    {Array.from({ length: iconsCount }).map((_, i) => <i key={`icon-timeline-${i}`} className="timeline__body__trail__icon" />)}
                </div>
            );
        };

        return (
            <React.Fragment>
                {deleteConfirmation && selectedAchievement && (
                    <ConfirmationModal
                        titleTranslationKey="achievements.confirmation"
                        cancelTranslationKey="general.cancel"
                        deleteTranslationKey="general.delete"
                        onClose={(): void => {
                            setDeleteConfirmation(false);
                            setSelectedAchievement(null);
                        }}
                        onAccept={(): void => {
                            if (onDelete) {
                                onDelete(selectedAchievement.id);
                                setDeleteConfirmation(false);
                                setSelectedAchievement(null);
                            }
                        }}
                    />
                )}
                {selectedAchievement && !deleteConfirmation && (
                    <div className="timeline--tooltips-container">
                        { renderTooltip(selectedAchievement) }
                    </div>
                )}
                <div className="timeline" data-testid="timeline">
                    {
                        showButtons && (
                            <button
                                type="button"
                                onClick={(): void => scroll(Direction.LEFT)}
                                className="timeline__controls"
                            >
                                <IconLeftArrow fill="#000" />
                            </button>
                        )
                    }
                    <div className="timeline__body" data-testid="timeline-body" ref={timelineElement}>
                        { achievements.map(timelineEntry) }
                        { renderTrail() }
                    </div>
                    {
                        showButtons && (
                            <button
                                type="button"
                                onClick={(): void => scroll(Direction.RIGHT)}
                                className="timeline__controls"
                            >
                                <IconRightArrow fill="#000" />
                            </button>
                        )
                    }
                </div>
                {
                    onAdd && (
                        <button className="timeline__add-icon" type="button" data-testid="timeline-add" onClick={onAdd}>
                            <IconAdd fill="white" />
                        </button>
                    )
                }
            </React.Fragment>
        );
    }),
);
