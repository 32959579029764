/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

/**
 * @typedef {Object} RolePermission
 * @property {string} name
 * @property {number} id
 * @property {string} key
 * @property {RolePermission[]} dependencies
 */
export interface RolePermission {
    name: string;
    id: number;
    key: string;
    dependencies: RolePermission[];
}

/**
 * PermissionKey
 * @enum {string}
 */
export enum PermissionKey {
    CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
    CHANGE_THEME = 'CHANGE_THEME',
    SPONSOR = 'SPONSOR',
    ATHLETE = 'ATHLETE',
    ADMIN = 'ADMIN',
    FAN = 'FAN',
    MANAGER = 'MANAGER',
}

/**
 * returns Sponsor permissions
 */
export const SponsorPermissions: RolePermission[] = [
    {
        name: 'Sponsor',
        id: 1,
        key: PermissionKey.SPONSOR,
        dependencies: [],
    },
];

/**
 * returns Athlete permissions
 */
export const AthletePermissions: RolePermission[] = [
    {
        name: 'Athlete',
        id: 2,
        key: PermissionKey.ATHLETE,
        dependencies: [],
    },
];

/**
 * returns Admin permissions
 */
export const AdminPermissions: RolePermission[] = [
    {
        name: 'Admin',
        id: 3,
        key: PermissionKey.ADMIN,
        dependencies: [],
    },
];

/**
 * returns Fan permissions
 */
export const FanPermissions: RolePermission[] = [
    {
        name: 'Fan',
        id: 3,
        key: PermissionKey.FAN,
        dependencies: [],
    },
];

/**
 * returns Manager permissions
 */
export const ManagerPermissions: RolePermission[] = [
    {
        name: 'Manager',
        id: 4,
        key: PermissionKey.MANAGER,
        dependencies: [],
    },
];
