/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import pt from 'date-fns/locale/pt';

import InputError from './FormFieldError';
import { getErrorsForField, hasAnyErrors } from '../../utils/validations';
import { KeyedObject } from '../../constants/misc';
import IconPrevious from '../assets/IconPrevious';
import IconNext from '../assets/IconNext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

registerLocale('pt', pt);

interface DatePickerHeaderParams {
    date: Date;
    changeYear: Function;
    changeMonth: Function;
    decreaseMonth: Function;
    increaseMonth: Function;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
    decreaseYear: Function;
    increaseYear: Function;
    prevYearButtonDisabled: boolean;
    nextYearButtonDisabled: boolean;
}

/**
 * @typedef {Object} OwnProps
 * @property {string} [label]
 * @property {string} name
 * @property {any} value
 * @property {string} [tabIndex]
 * @property {Function} onChange
 * @property {string} placeholder
 * @property {boolean} [disabled]
 * @property {KeyedObject} [errors]
 */
interface OwnProps extends TranslationContext {
    label?: string;
    name: string;
    value: any;
    tabIndex?: string;
    onChange: Function;
    placeholder?: string;
    disabled?: boolean;
    errors?: KeyedObject | null;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {boolean} showPassword
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * Component that shows a custom input
 * @param {Props} props
 * @returns {FunctionComponent<Props>}
 */
const Datepicker: FunctionComponent<Props> = ({
    label = '',
    name,
    value,
    onChange,
    placeholder = '',
    disabled = false,
    errors,
    t,
}: Props) => {
    const years: number[] = [];
    for (let i = 1; i < moment().year(); i++) {
        years.push(i);
    }
    const months = [
        t('months.jan'),
        t('months.feb'),
        t('months.mar'),
        t('months.apr'),
        t('months.may'),
        t('months.jun'),
        t('months.jul'),
        t('months.aug'),
        t('months.sep'),
        t('months.oct'),
        t('months.nov'),
        t('months.dec'),
    ];

    const fieldErrors = getErrorsForField(name, errors);
    const hasErrors = hasAnyErrors(fieldErrors);

    /**
     * creates a custom header
     * @param {DatePickerHeaderParams} params
     * @returns {React.ReactNode}
     */
    const customHeader = (params: DatePickerHeaderParams): React.ReactNode => {
        const date = moment(params.date).isValid() ? moment(params.date) : moment();
        return (
            <div className="react-datepicker__header-nav">
                <button
                    onClick={() => params.decreaseMonth()}
                    disabled={params.prevMonthButtonDisabled}
                    data-testid="datepicker-month-decrease"
                >
                    <IconPrevious />
                </button>
                <select
                    value={date.year()}
                    onChange={({ target: { value } }) => params.changeYear(value)}
                    data-testid="datepicker-year-select"
                >
                    {years.map((option: number) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <select
                    value={months[date.month()]}
                    onChange={({ target: { value } }) => params.changeMonth(months.indexOf(value))}
                    data-testid="datepicker-month-select"
                >
                    {months.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <button
                    onClick={() => params.increaseMonth()}
                    disabled={params.nextMonthButtonDisabled}
                    data-testid="datepicker-month-increase"
                >
                    <IconNext />
                </button>
            </div>
        );
    };

    return (
        <div className="form-field">
            <div className={`input-container ${hasErrors ? 'has-error' : ''}`}>
                <DatePicker
                    selected={value}
                    onChange={(date: Date) => {
                        try {
                            if (moment(date).isValid()) {
                                onChange(date);
                            }
                        } catch (e) {
                            const date = new Date('2000/01/01');
                            if (moment(date).isValid()) {
                                onChange(date);
                            }
                        }
                    }}
                    onChangeRaw={(event) => {
                        try {
                            const val = event.target.value;
                            const valParts = val.split('-');

                            if (Number(valParts[2]) <= 0) {
                                return;
                            }

                            const date = moment(val, 'DD-MM-YYYY', true);
                            if (moment(date).isValid()) {
                                onChange(date);
                            }
                        } catch (e) {}
                    }}
                    onFocus={() => {
                        if (!value || value === '') {
                            const date = new Date('2000/01/01');
                            if (moment(date).isValid()) {
                                onChange(date);
                            }
                        }
                    }}
                    dateFormat="dd-MM-yyyy"
                    locale="en"
                    disabled={disabled}
                    placeholderText={placeholder}
                    renderCustomHeader={customHeader}
                />
            </div>
            {label.length > 0 && <label>{label}</label>}
            {hasErrors && <InputError errors={fieldErrors} field={name} />}
        </div>
    );
};

export default withTranslationContext(Datepicker);
