/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const VERSION = process.env.REACT_APP_COMMIT;
export const API_URL = process.env.REACT_APP_API_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENV;
export const WEB_URL = window.location.origin;
export const WEB_SOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_KEY;
export const IS_TEST_ENVIRONMENT = process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'test';
export const IS_CC_PAYMENTS_ENABLED = process.env.REACT_APP_CC_PAYMENTS_ENABLED === 'true';
