/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent,
    useMemo,
    useState,
} from 'react';
import {
    Menu, MenuItem, TablePagination,
} from '@material-ui/core';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Lottie from 'lottie-react';
import { SponsorContext, withSponsorContext } from '../controllers/sponsor/SponsorContext';
import { SponsorAthletesSupport } from '../../constants/sponsor';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import IconThreeDotsVertical from '../../assets/images/icon_three_dots_vertical.svg';
import IconAccept from '../../assets/images/icon_accept.svg';
import IconReject from '../../assets/images/icon_reject.svg';
import { KeyedObject } from '../../constants/misc';
import { displayError, displaySuccess } from '../../utils/notifications';
import { getProfileUrl } from '../../utils/misc';
import { SUPPORT_STATUS } from '../../constants/support';
import { ConfirmationModal } from './ConfirmationModal';
import partyConfetti from '../../assets/animations/party-confetti.json';
import { TableActionType } from '../../utils/table';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';

interface OwnProps {
    athletesSupportList: SponsorAthletesSupport[];
    totalAthletesSupport: number;
    params: Record<string, number>;
    onPageChange: (evt: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    onRowsPerPageChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    fetchData: () => void;
    actionButtons?: TableActionType[];
}

type Props = OwnProps & SponsorContext & AuthenticationContext & TranslationContext & RouteComponentProps;

const SponsorshipManagerTable: FunctionComponent<Props> = ({
    fetchData,
    athletesSupportList,
    totalAthletesSupport,
    onPageChange,
    onRowsPerPageChange,
    t,
    params,
    patchAcceptSupport,
    patchRejectSupport,
    patchCancelSupport,
    actionButtons = [],
}: Props) => {
    const { _limit: limit, _page: page } = params;

    const [anchorElement, setAnchorElement] = useState<null | HTMLButtonElement>(null);
    const [supportId, setSupportId] = useState(0);
    const [support, setSupport] = useState<SponsorAthletesSupport | undefined>(undefined);
    const [showConfirmationModalReject, setShowConfirmationModalReject] = useState(false);
    const [showConfirmationModalAccept, setShowConfirmationModalAccept] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showConfettiAnimation, setShowConfettiAnimation] = useState<boolean>(false);

    const hasActionButtons = useMemo(() => actionButtons.length > 0, [actionButtons]);

    const canShowActionButtons = (athleteSupport: SponsorAthletesSupport) => {
        return (athleteSupport.status === SUPPORT_STATUS.PENDING)
        || (athleteSupport.status === SUPPORT_STATUS.ACTIVE && actionButtons.includes(TableActionType.CANCEL));
    };

    const setSupportById = (id: number) => {
        const supportFound = athletesSupportList.find((athletesSupport) => athletesSupport.id === id);
        if (supportFound !== undefined) setSupport(supportFound);
    };

    const activateMenu = (evt: React.MouseEvent<HTMLButtonElement>, id: number) => {
        evt.stopPropagation();

        setSupportId(id);
        setSupportById(id);

        setAnchorElement(evt.currentTarget);
    };

    const resetMenu = () => {
        setAnchorElement(null);
    };

    const success = (message: string): void => {
        fetchData();
        resetMenu();
        displaySuccess({ message });
        closeModals();
    };

    const failure = (error: KeyedObject): void => {
        displayError({ message: String(error) });
        closeModals();
    };

    const fetchAcceptSupport = () => {
        patchAcceptSupport(supportId, () => {
            setShowConfettiAnimation(true);
            success(t('profile.sponsorshipManager.acceptSuccess'));
        }, failure);
    };

    const onClickRejectSupport = (id: number) => {
        setSupportId(id);
        setSupportById(id);

        setShowConfirmationModalReject(true);
    };

    const onClickCancelSupport = (id: number) => {
        setSupportId(id);
        setSupportById(id);
        resetMenu();

        setShowCancelModal(true);
    };

    const onClickAcceptSupport = (id: number) => {
        setSupportId(id);
        setSupportById(id);

        setShowConfirmationModalAccept(true);
    };

    const fetchRejectSupport = () => {
        patchRejectSupport(supportId, () => success(t('profile.sponsorshipManager.rejectSuccess')), failure);
    };
    
    const onCancelSuccess = () => {
        if (support !== undefined && support.status === SUPPORT_STATUS.ACTIVE) {
            success(t('profile.sponsorshipManager.cancelActiveSuccess'));
        } else {
            success(t('profile.sponsorshipManager.cancelSuccess'));
        }
    };
    
    const fetchCancelSupport = () => {
        const status = support !== undefined && support.status === SUPPORT_STATUS.ACTIVE
            ? SUPPORT_STATUS.ACTIVE
            : SUPPORT_STATUS.PENDING;

        patchCancelSupport(supportId, status, onCancelSuccess, failure);
    };

    const closeModals = () => {
        setShowConfirmationModalAccept(false);
        setShowConfirmationModalReject(false);
        setShowCancelModal(false);
    };

    const animationEnded = () => {
        setShowConfettiAnimation(false);
    };

    const renderActionMenu = () => {
        return (
            <Menu
                className="area-menu-items"
                anchorEl={anchorElement}
                keepMounted
                open={!!anchorElement}
                onClose={resetMenu}
            >
                {actionButtons.includes(TableActionType.ACCEPT) && (
                    <MenuItem data-testid="accept-credit-btn" onClick={() => onClickAcceptSupport(supportId)}>
                        {t('profile.sponsorshipManager.accept')}
                    </MenuItem>
                )}
                
                {actionButtons.includes(TableActionType.REJECT) && (
                    <MenuItem data-testid="reject-credit-btn" onClick={() => onClickRejectSupport(supportId)}>
                        {t('profile.sponsorshipManager.reject')}
                    </MenuItem>
                )}
                
                {actionButtons.includes(TableActionType.CANCEL) && (
                    <MenuItem data-testid="cancel-credit-btn" onClick={() => onClickCancelSupport(supportId)}>
                        {t('profile.sponsorshipManager.cancel')}
                    </MenuItem>
                )}
            </Menu>
        );
    };
    
    return (
        <>
            <table className="sponsorship-manager-table" data-testid="sponsorship-manager-table">
                <thead>
                    <tr>
                        {hasActionButtons && <th />}
                        <th className="sponsorship-manager-table__column-30">{hasActionButtons ? t('profile.sponsorshipManager.supporter') : t('profile.sponsorshipManager.athlete')}</th>
                        <th className="sponsorship-manager-table__column-20">{t('profile.sponsorshipManager.status')}</th>
                        {hasActionButtons && (
                            <th className="sponsorship-manager-table__column-20">{t('profile.sponsorshipManager.actions')}</th>
                        )}
                        <th className="sponsorship-manager-table__column-30">{t('profile.sponsorshipManager.periodExpiry')}</th>
                        <th className="sponsorship-manager-table__column-10">{t('profile.sponsorshipManager.total')}</th>
                    </tr>
                </thead>
                <tbody>
                    {athletesSupportList.map((athleteSupport) => (
                        <tr key={athleteSupport.id}>
                            {hasActionButtons && (
                                <td className="sponsorship-manager-table__column-actions">
                                    {canShowActionButtons(athleteSupport) && (
                                        <button
                                            className="sponsorship-manager-table__column-actions--btn-side"
                                            type="button"
                                            onClick={(e) => activateMenu(e, athleteSupport.id)}
                                            data-testid="action-button"
                                        >
                                            <img src={IconThreeDotsVertical} alt="actions" />
                                        </button>
                                    )}
                                </td>
                            )}
                            <td>
                                <div className="sponsorship-manager-table__column-username">
                                    <Link to={getProfileUrl(athleteSupport.userRole, athleteSupport.userId)}>
                                        <img src={athleteSupport.userAvatar.main} alt="user-avatar" />
                                        {athleteSupport.userName}
                                    </Link>
                                </div>
                            </td>
                            <td className={`sponsorship-manager-table__column-status--${athleteSupport.status.toLowerCase()}`}>
                                {t(`profile.sponsorshipManager.${athleteSupport.status.toLowerCase()}`)}
                            </td>
                            {hasActionButtons && (
                                <td>
                                    {canShowActionButtons(athleteSupport) && (
                                        <div className="sponsorship-manager-table__column-actions">
                                            {actionButtons.includes(TableActionType.ACCEPT) && (
                                                <button
                                                    className="sponsorship-manager-table__column-actions--btn-accept"
                                                    type="button"
                                                    onClick={() => onClickAcceptSupport(athleteSupport.id)}
                                                >
                                                    <img src={IconAccept} alt="accept" />
                                                </button>
                                            )}
                                            {actionButtons.includes(TableActionType.REJECT) && (
                                                <button
                                                    className="sponsorship-manager-table__column-actions--btn-reject"
                                                    type="button"
                                                    onClick={() => onClickRejectSupport(athleteSupport.id)}
                                                >
                                                    <img src={IconReject} alt="reject" />
                                                </button>
                                            )}
                                            {actionButtons.includes(TableActionType.CANCEL) && (
                                                <button
                                                    className="sponsorship-manager-table__column-actions--btn-reject"
                                                    type="button"
                                                    onClick={() => onClickCancelSupport(athleteSupport.id)}
                                                >
                                                    <img src={IconReject} alt="cancel" title={t('general.cancel')} />
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </td>
                            )}
                            <td>
                                <div className="sponsorship-manager-table__column-period">
                                    <div className="sponsorship-manager-table__column-period--bought">
                                        <p>
                                            {athleteSupport.periodBought === 1
                                                ? `${athleteSupport.periodBought} ${t('profile.sponsorshipManager.month')}`
                                                : `${athleteSupport.periodBought} ${t('profile.sponsorshipManager.months')}`}
                                        </p>
                                    </div>
                                    {
                                    (athleteSupport.periodLeft.days > 0
                                        || athleteSupport.periodLeft.months > 0
                                        || athleteSupport.periodLeft.years > 0) && (
                                            <div className="sponsorship-manager-table__column-period--left">
                                                {athleteSupport.periodLeft.days > 0 && (
                                                    <p>
                                                        {athleteSupport.periodLeft.days === 1
                                                            ? `${athleteSupport.periodLeft.days} ${t('profile.sponsorshipManager.day')}`
                                                            : `${athleteSupport.periodLeft.days} ${t('profile.sponsorshipManager.days')}`}
                                                    </p>
                                                )}
                                                {athleteSupport.periodLeft.months > 0 && (
                                                    <p>
                                                        {athleteSupport.periodLeft.months === 1
                                                            ? `${athleteSupport.periodLeft.months} ${t('profile.sponsorshipManager.month')}`
                                                            : `${athleteSupport.periodLeft.months} ${t('profile.sponsorshipManager.months')}`}
                                                    </p>
                                                )}
                                                {athleteSupport.periodLeft.years > 0 && (
                                                    <p>
                                                        {athleteSupport.periodLeft.years === 1
                                                            ? `${athleteSupport.periodLeft.years} ${t('profile.sponsorshipManager.year')}`
                                                            : `${athleteSupport.periodLeft.years} ${t('profile.sponsorshipManager.years')}`}
                                                    </p>
                                                )}
                                            </div>
                                    )
                                }
                                </div>
                            </td>
                            <td className="sponsorship-manager-table__column-total">
                                {athleteSupport.totalCredits === 1
                                    ? `${athleteSupport.totalCredits} ${t('profile.sponsorshipManager.credit')}`
                                    : `${athleteSupport.totalCredits} ${t('profile.sponsorshipManager.credits')}`
                            }
                            </td>
                        </tr>
                    ))}
                </tbody>
                {(hasActionButtons && showConfettiAnimation)
                    && (
                        <Lottie
                            animationData={partyConfetti}
                            loop={false}
                            className="lottie"
                            onComplete={animationEnded}
                        />
                    )}
            </table>
            <div className="sponsorship-manager-table__pagination">
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]}
                    count={Number(totalAthletesSupport)}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                />
            </div>
            {hasActionButtons && renderActionMenu()}
            {showConfirmationModalAccept && (
                <ConfirmationModal
                    onAccept={fetchAcceptSupport}
                    onClose={() => setShowConfirmationModalAccept(false)}
                    titleTranslationKey={{ key: 'confirmationModal.acceptSupportTitle' }}
                    cancelTranslationKey="gallery.cancel"
                    supportConfirmation
                />
            )}
            {showConfirmationModalReject && (
                <ConfirmationModal
                    onAccept={fetchRejectSupport}
                    onClose={() => setShowConfirmationModalReject(false)}
                    titleTranslationKey={{ key: 'confirmationModal.rejectSupportTitle' }}
                    cancelTranslationKey="gallery.cancel"
                    supportConfirmation
                />
            )}
            {showCancelModal && (
                <ConfirmationModal
                    onAccept={fetchCancelSupport}
                    onClose={() => setShowCancelModal(false)}
                    titleTranslationKey={{
                        key: `${support !== undefined && support.status === SUPPORT_STATUS.ACTIVE
                            ? 'confirmationModal.cancelActiveSupportTitle'
                            : 'confirmationModal.cancelSupportTitle'}`,
                    }}
                    cancelTranslationKey="gallery.cancel"
                    supportConfirmation
                />
            )}
        </>
    );
};

export default withRouter(
    withSponsorContext(
        withTranslationContext(
            withAuthenticationContext(SponsorshipManagerTable),
        ),
    ),
);
