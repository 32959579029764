/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'athletes';

/**
 * Athletes URL
 * @returns {string}
 */
export const athletesURL = (): string => {
    return `${API_URL}/${api}`;
};

/**
 * Athlete URL
 * @returns {string}
 */
export const athleteURL = (id: React.Key): string => {
    return `${API_URL}/${api}/${id}`;
};

/**
 * Athletes invite URL
 * @returns {string}
 */
export const athleteInviteURL = (): string => {
    return `${API_URL}/${api}/finalize-invitation`;
};

/**
 * Athlete Media URL
 * @param {number | string} [id]
 * @returns {string}
 */
export const athleteMediaURL = (id: number | string): string => {
    return `${API_URL}/${api}/${id}/media`;
};
 
/**
 * Athlete delete Media URL
 * @param {number | string} [mediaId]
 * @returns {string}
 */
export const athleteDeleteMediaURL = (mediaId: number | string): string => {
    return `${API_URL}/${api}/media/${mediaId}`;
};

/**
 * Athlete Sponsors URL
 * @param {React.Key} id
 * @returns {string}
 */
export const athleteSponsorsURL = (id: string): string => {
    return `${API_URL}/support/athlete/${id}/badges`;
};
export const supporterAthletesURL = (id: string): string => {
    return `${API_URL}/support/supporter/${id}/badges`;
};
