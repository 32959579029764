/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from './misc';

export const ErrorCode: KeyedObject = {
    0: 'general',
    1: 'couldNotCreateFolders',
    2: 'forbidden',
    3: 'emailInUse',
    4: 'invalidPassword',
    5: 'emptyNewPassword',
    6: 'accountAlreadyActivated',
    7: 'accountNotActivated',
    8: 'userNotFound',
    9: 'invalidCredentials',
    10: 'couldNotSaveFile',
    11: 'invalidToken',
    12: 'couldNotDeleteUser',
    13: 'termsVersionAlreadyExists',
    14: 'invalidFile',
    15: 'couldNotCreateAvatar',
    16: 'acceptLastTerms',
    17: 'acceptLastTerms',
    18: 'invalidImageId',
    19: 'invalidImageId',
    20: 'invalidImageId',
    21: 'invalidSponsorEmail',
    22: 'tooManyEvents',
    23: 'cannotParseThumbnail',
    24: 'cannotLocateThumbnail',
    25: 'entityDoesNotExist',
    26: 'invalidTest',
    27: 'invalidAnswer',
    28: 'invalidUserType',
    29: 'optionNameExists',
    30: 'invalidImageSize',
    32: 'moreThan500Years',
    34: 'athleteHasNoSport',
};
