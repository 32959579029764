/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import { Store } from 'redux';

import { resetAuthenticationActionCreator } from './actions/authentication';
import { permissionsResetActionCreator } from './actions/authorization';

/**
 * intercepts requests and adds the authorization header
 * @param {Store} store
 */
const setupRequestInterceptor = (store: Store) => {
    axios.interceptors.request.use(
        reqConfig => {
            const { authentication } = store.getState();
            const { token } = authentication;

            const newConfig = reqConfig;

            if (token) {
                newConfig.headers.Authorization = `Bearer ${token}`;
            }

            return newConfig;
        },
        err => Promise.reject(err),
    );
};

/**
 * intercepts responses to update token and/or treat errors
 * @param {Store} store
 */
const setupResponseInterceptor = (store: Store) => {
    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error?.response?.status === 401) {
            store.dispatch(resetAuthenticationActionCreator());
            store.dispatch(permissionsResetActionCreator());
        }
        return Promise.reject(error);
    });
};

/**
 * sets up the necessary api configuration
 * @param {Store} store
 */
export const setupAPI = async (store: Store) => {
    axios.create({ withCredentials: true });
    setupRequestInterceptor(store);
    setupResponseInterceptor(store);
};
