/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from '../constants/misc';

/**
 * returns a concatenated full name
 * @param {string} firstName
 * @param {string} lastName
 * @returns {string}
 */
export const getFullName = (firstName: string, lastName: string | null): string => {
    const names: KeyedObject = [];

    if (firstName && firstName.trim().length) names.push(firstName);
    if (lastName && lastName.trim().length) names.push(lastName);

    return names.join(' ');
};

/**
 * returns a capitalized string
 * @param {string} str
 * @returns {string}
 */
export const capitalizeString = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * returns a readable string from an enum
 * @param {string} str
 * @returns {string}
 */
export const enumToString = (str: string): string => {
    let out = `${str}`;
    out = out.replace(/[_]/gi, ' ');
    return capitalizeString(out);
};
