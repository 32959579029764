/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { GeneralContextProvider } from './GeneralContext';
import { regionsURL, regionURL } from '../../../services/location';
import { Region } from '../../../types/country';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;

export class GeneralController extends Component<Props> {
    getRegion = async (code: string, postalCode: string): Promise<Region | null> => {
        try {
            const { data } = await axios.get(regionURL(code, postalCode));
            return data;
        } catch {
            return null;
        }
    }
    
    getRegions = async (code: string): Promise<string[]> => {
        try {
            const { data } = await axios.get(regionsURL(code));
            return data;
        } catch {
            return [];
        }
    }

    render(): React.ReactNode {
        const { children } = this.props;

        return (
            <GeneralContextProvider
                value={{
                    getRegion: this.getRegion,
                    getRegions: this.getRegions,
                }}
            >
                {children}
            </GeneralContextProvider>
        );
    }
}

export const ConnectedGeneralController = (GeneralController);
