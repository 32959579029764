/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

const api = 'wallet';

export const walletTransactionURL = (): string => {
    return `${API_URL}/${api}/transaction`;
};

export const walletTransactionListURL = (params = {}): string => {
    const queryParams = objectToParams(params);
    return `${API_URL}/${api}/transactions${queryParams}`;
};
