/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { AuthenticationState } from './types';
import { AuthenticationAction } from '../actions/authentication_types';

/**
 * @typedef {AuthenticationState} authenticationInitialState
 */
export const authenticationInitialState: AuthenticationState = {
    token: null,
    user: null,
    isAuthenticated: false,
    isFetching: false,
};

/**
 * authentication reducer
 * @param {AuthenticationState} state
 * @param {any} action
 * @returns {AuthenticationState}
 */
export default function (state = authenticationInitialState, action: any): AuthenticationState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.authentication) {
                return {
                    ...action.payload.authentication,
                    isFetching: false,
                };
            }
            return state;
        case AuthenticationAction.REGISTRATION_REQUEST:
        case AuthenticationAction.ACCOUNT_VALIDATION_REQUEST:
        case AuthenticationAction.RESEND_ACTIVATION_REQUEST:
        case AuthenticationAction.LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
            };
        case AuthenticationAction.LOGIN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
                isAuthenticated: true,
            };
        case AuthenticationAction.ACCOUNT_VALIDATION_SUCCESS:
        case AuthenticationAction.REGISTRATION_SUCCESS:
        case AuthenticationAction.REGISTRATION_FAILURE:
        case AuthenticationAction.LOGIN_FAILURE:
        case AuthenticationAction.ACCOUNT_VALIDATION_FAILURE:
        case AuthenticationAction.RESEND_ACTIVATION_SUCCESS:
        case AuthenticationAction.RESEND_ACTIVATION_FAILURE:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
            };
        case AuthenticationAction.LOGOUT_REQUEST:
            return authenticationInitialState;
        case AuthenticationAction.SET_AUTHENTICATION:
            return {
                ...state,
                ...action.payload,
            };
        case AuthenticationAction.SET_AUTHENTICATED_USER:
            return {
                ...state,
                user: action.payload,
            };
        case AuthenticationAction.RESET_AUTHENTICATION:
            return authenticationInitialState;
        default:
            return state;
    }
}
