/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconFilter: FunctionComponent = () => {
    return (
        <svg id="ico_filters" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <rect id="Rectangle_745" data-name="Rectangle 745" width="18" height="18" fill="none" />
            <g id="ic-statistics-funnel" transform="translate(0.937 1.688)">
                <path id="Path_10078" data-name="Path 10078" d="M15.312,6.938h-12a.563.563,0,0,1-.518-.344l-1.5-3.563a.563.563,0,0,1,.518-.781h15a.563.563,0,0,1,.518.781l-1.5,3.563A.563.563,0,0,1,15.312,6.938ZM3.686,5.813H14.939l1.026-2.437H2.66Z" transform="translate(-1.25 -2.25)" fill="#fff" />
                <path id="Path_10079" data-name="Path 10079" d="M15.312,6.938a.563.563,0,0,1-.518-.781l1.171-2.782H1.813a.563.563,0,0,1,0-1.125h15a.563.563,0,0,1,.518.781l-1.5,3.563A.563.563,0,0,1,15.312,6.938Z" transform="translate(-1.25 -2.25)" fill="#fff" />
                <path id="Path_10080" data-name="Path 10080" d="M13.812,14.068h-7.5a.563.563,0,0,1-.518-.344l-1.5-3.562a.563.563,0,0,1,.518-.781h10.5a.563.563,0,0,1,.518.781l-1.5,3.563A.563.563,0,0,1,13.812,14.068ZM6.686,12.943h6.753l1.026-2.437H5.66Z" transform="translate(-2 -4.033)" fill="#fff" />
                <path id="Path_10081" data-name="Path 10081" d="M12.312,21.188h-3a.563.563,0,0,1-.518-.344l-1.5-3.562a.563.563,0,0,1,.518-.781h6a.563.563,0,0,1,.518.781l-1.5,3.563A.563.563,0,0,1,12.312,21.188ZM9.686,20.063h2.253l1.026-2.437H8.66Z" transform="translate(-2.75 -5.813)" fill="#fff" />
            </g>
        </svg>

    );
};

export default IconFilter;
