/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { FunctionComponent, ReactElement } from 'react';

import {
    AuthorizationContext,
    withAuthorizationContext,
} from '../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../utils/authorization';

interface OwnProps extends AuthorizationContext {
    actions: Array<string>;
    operator?: string;
    yes?: () => ReactElement | null;
    no?: () => ReactElement | null;
}

type Props = OwnProps;

export const yesDefault = (): null => null;
export const noDefault = (): null => null;

const Can: FunctionComponent<Props> = ({
    permissions,
    actions,
    operator,
    yes = yesDefault,
    no = noDefault,
}: Props) => {
    return checkPermission(permissions, actions, operator) ? yes() : no();
};

export default withAuthorizationContext(Can);
