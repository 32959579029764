/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 * @property {Function} onClick
 */
interface OwnProps {
    onClick?: Function;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
export const IconCreditCard: FunctionComponent<Props> = (props: Props) => {
    const { onClick } = props;

    return (
        <svg className="icon-credit-card__icon" onClick={(): void => (onClick ? onClick() : {})} data-testid={'icon-credit-card'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18.42 14.123">
            <g id="credit-card" transform="translate(-1.125 -5.062)">
                <path d="M18.624,5.063H2.046a.922.922,0,0,0-.921.921v12.28a.922.922,0,0,0,.921.921H18.624a.922.922,0,0,0,.921-.921V5.983a.922.922,0,0,0-.921-.921Zm-.307,1.228V8.747H2.353V6.291ZM2.353,17.956V11.2H18.317v6.754Z" fill="#213428"/>
                <path d="M6.188,21.938H8.644v1.228H6.188Z" transform="translate(-2.3 -7.665)" fill="#213428"/>
                <path d="M12.938,21.938h2.456v1.228H12.938Z" transform="translate(-5.366 -7.665)" fill="#213428"/>
            </g>
        </svg>
    );
};
