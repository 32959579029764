/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import isURL from 'validator/lib/isURL';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../../controllers/user/UserContext';
import iconPin from '../../../assets/images/icon_pin.svg';
import badgeStar from '../../../assets/images/badge_star.svg';
import contactIcon from '../../../assets/images/icon_btn_contact_green.svg';
import defaultAvatar from '../../../assets/images/avatar_default.svg';
import DrawerGallery from '../../elements/DrawerGallery';
import Avatar from '../../elements/Avatar';
import defaultCover from '../../../assets/images/athlete_header_bg.png';
import { MatchParams, Media } from '../../../constants/misc';
import { IconWWW } from '../../assets/IconWWW';
import { fileURL } from '../../../services/files';
import ProfileError from '../../elements/ProfileError';
import { Badge, Sponsor } from '../../../types/user';
import { SponsorContext, withSponsorContext } from '../../controllers/sponsor/SponsorContext';
import SponsorProductList from '../../elements/SponsorProductList';
import Badges from '../../elements/Badges';
import { AppRoute } from '../../../constants/routes';
import SponsorshipManagerTable from '../../elements/SponsorshipManagerTable';
import { SponsorAthletesSupport } from '../../../constants/sponsor';
import { TableActionType } from '../../../utils/table';
import { WS } from '../../../utils/websocket';
import { SocketEventType } from '../../../types/websockets';
import { buildRoute } from '../../../utils/misc';
import ProfileButtons from '../../elements/ProfileButtons';

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext,
     TranslationContext, UserContext, SponsorContext {}

type Props = OwnProps;

interface OwnState {
    showGallery: boolean;
    coverPhoto: Media | null;
    linkURL: string;
    error: boolean;
    userData: Sponsor | null;
    sponsoredAthletes: Badge[];
    athletesSupportList: SponsorAthletesSupport[];
    totalAthletesSupport: number;
    paramsSponsorshipSponsor: Record<string, number>;
    isSocketConnecting: boolean;
    isScrolling: boolean;
}

type State = OwnState;

const initialState: State = {
    showGallery: false,
    coverPhoto: null,
    linkURL: '',
    error: false,
    userData: null,
    sponsoredAthletes: [],
    athletesSupportList: [],
    totalAthletesSupport: 0,
    paramsSponsorshipSponsor: {
        _limit: 10,
        _page: 0,
    },
    isSocketConnecting: false,
    isScrolling: false,
};

class SponsorScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount(): void {
        const { match, isAuthenticated } = this.props;
        const { isSocketConnecting } = this.state;

        window.scrollTo(0, 0);

        if (match && match.params && match.params.id) {
            this.prepare(match.params.id);
        } else {
            this.prepare();
        }

        if (isAuthenticated && !isSocketConnecting) {
            WS.addHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const {
            match, user, pendingAthleteSupporters, isAuthenticated,
        } = this.props;
        const { user: prevUser, pendingAthleteSupporters: prevPendingAthleteSupporters, isAuthenticated: prevIsAuthenticated } = prevProps;
        const { isSocketConnecting } = this.state;
        
        if (match && match.params && typeof match.params.id === 'string') {
            if (prevProps.match.params.id !== match.params.id) {
                this.prepare(match.params.id);
            }
        }

        if (user?.availableCredits !== prevUser?.availableCredits || pendingAthleteSupporters !== prevPendingAthleteSupporters) {
            this.fetchAllSupportData();
        }

        if (isAuthenticated && !prevIsAuthenticated && !isSocketConnecting) {
            WS.addHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
        }
    }

    componentWillUnmount(): void {
        WS.removeHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
    }

    onEditClick = () => {
        const { history } = this.props;
        
        history.push(AppRoute.SponsorProfile);
    }

    onPageChange = (evt: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        const { paramsSponsorshipSponsor } = this.state;

        this.setState(
            {
                paramsSponsorshipSponsor: { ...paramsSponsorshipSponsor, _page: page },
            },
            this.fetchAthletesSupport,
        );
    };

    onRowsPerPageChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { paramsSponsorshipSponsor } = this.state;

        this.setState(
            {
                paramsSponsorshipSponsor: { ...paramsSponsorshipSponsor, _limit: Number(evt.target.value) },
            },
            this.fetchAthletesSupport,
        );
    };

    onToggleGallery = (): void => {
        const { showGallery } = this.state;

        this.setState({
            showGallery: !showGallery,
        });
    };

    handleSupportUpdate = ({ payload: athleteSupportEvent }: {payload: Partial<SponsorAthletesSupport>}) => {
        const { athletesSupportList } = this.state;
        
        const supportElement = athletesSupportList.find((el) => el.id === athleteSupportEvent.id);
        
        if (!supportElement) {
            return;
        }

        const auxList = athletesSupportList.map((el) => {
            if (el.id === supportElement.id) return { ...el, ...athleteSupportEvent };

            return el;
        });

        this.setState({
            athletesSupportList: auxList,
        });
    }
    
    fetchAthletesSupport = () => {
        const { paramsSponsorshipSponsor } = this.state;
        const { getAthletesSupport } = this.props;

        getAthletesSupport({ ...paramsSponsorshipSponsor })
            .then(({ list, total }) => {
                this.setState({
                    athletesSupportList: list,
                    totalAthletesSupport: total,
                });
            });
    }

    fetchAllSupportData = () => {
        this.fetchAthletesSupport();
    };

    prepare = async (id?: string): Promise<void> => {
        const {
            user, getSponsor, getSponsoredAthletesBadges,
        } = this.props;
        const sponsorId = id || user?.id;

        const [userData, sponsoredAthletes] = await Promise.all([
            getSponsor(sponsorId), getSponsoredAthletesBadges(sponsorId),
        ]);

        if (!userData) {
            this.setState({ error: true });
            return;
        }

        if (user?.id === userData.id) this.fetchAthletesSupport();

        this.setState({
            userData,
            linkURL: buildRoute(AppRoute.Sponsor, { id: sponsorId }),
            sponsoredAthletes,
        });
    };

    renderTable = () => {
        const { athletesSupportList, totalAthletesSupport, paramsSponsorshipSponsor } = this.state;

        return (
            <SponsorshipManagerTable
                totalAthletesSupport={totalAthletesSupport}
                athletesSupportList={athletesSupportList}
                onPageChange={this.onPageChange}
                onRowsPerPageChange={this.onRowsPerPageChange}
                params={paramsSponsorshipSponsor}
                fetchData={this.fetchAthletesSupport}
                actionButtons={[TableActionType.CANCEL]}
            />
        );
    }

    render(): React.ReactNode {
        const {
            showGallery,
            linkURL,
            error,
            userData,
            sponsoredAthletes,
            athletesSupportList,
        } = this.state;
        const { t, user } = this.props;

        const {
            name,
            aboutUs,
            coverPhoto,
            avatar,
            biography,
            address,
            email,
            phrase,
        } = userData || {};

        const itsMe = user?.id === userData?.id;

        const currentCover = coverPhoto ? fileURL(coverPhoto.id) : defaultCover;
        const currentAvatar = avatar ? fileURL(avatar.id) : null;
        const phone = userData?.phone || '';
        const contactURL = phone.includes('http://') || phone.includes('https://') ? phone : `https://${phone}`;

        return (
            <div className="profile-screen" data-testid="sponsor-screen">
                <ProfileError show={error} close={() => this.setState({ error: false })} />
                <div className="profile-screen__body">
                    <div className="profile-screen__body__left">
                        <div className="profile-screen__body__left__header">
                            <div className="cover">
                                <div className="cover__image" style={{ backgroundImage: `url(${currentCover})` }} />
                            </div>
                            <ProfileButtons
                                onEditClick={this.onEditClick}
                                linkToShare={linkURL}
                                user={userData}
                                userOwner={itsMe}
                            />
                            <Avatar image={currentAvatar || defaultAvatar} badge={badgeStar} />
                            <button
                                type="button"
                                className="btn btn--primary-dark gallery-btn"
                                onClick={this.onToggleGallery}
                                data-testid="gallery-toggle"
                            >
                                {t('sponsors.galery')}
                            </button>
                        </div>
                        {!showGallery ? (
                            <div className="profile-screen__body__left__content">
                                <div className="profile-top profile-top--sponsor">
                                    <div className="athlete-info">
                                        <h1>{name}</h1>
                                        <h2>{t('sponsors.sponsor')}</h2>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('sponsors.us')}</h3>
                                                <h4>{phrase}</h4>
                                                <p>{biography || t('sponsors.unavailable')}</p>
                                            </div>
                                            <div className="content-box">
                                                <h3 className="title">{t('sponsors.about')}</h3>
                                                <p>{aboutUs || t('sponsors.unavailable')}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('sponsors.website')}</h3>
                                                <div className="content-box__item">
                                                    <div className="icn">
                                                        <img src={iconPin} alt="location icon" />
                                                    </div>
                                                    <p>{address}</p>
                                                </div>
                                                <div className="content-box__item">

                                                    <div className="icn icn--green icn--www">
                                                        <IconWWW />
                                                    </div>
                                                    {
                                                        isURL(phone) ? (
                                                            <a href={contactURL} target="_blank" rel="noopener noreferrer">{phone}</a>
                                                        ) : (
                                                            <span>{phone}</span>
                                                        )
                                                    }
                                                </div>
                                                <div className="content-box__item">
                                                    <div className="icn">
                                                        <img src={contactIcon} alt="email icon" className="content-box__item__contact" />
                                                    </div>
                                                    <p>{email}</p>
                                                </div>
                                            </div>
                                            <div className="content-box">
                                                <div className="content-box__with-button">
                                                    <h3 className="title">{t('athlete.supportedAthletes')}</h3>
                                                </div>
                                                <div className="content-box__item content-box__item--sponsored">
                                                    {sponsoredAthletes.map((sponsoredAthlete) => (
                                                        <Badges
                                                            userId={sponsoredAthlete.id}
                                                            userRole={sponsoredAthlete.role}
                                                            badgeId={sponsoredAthlete.badgeUrl.id}
                                                            badgeUrl={sponsoredAthlete.badgeUrl.main}
                                                            refreshScreen={this.prepare}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {userData && (
                                            <SponsorProductList
                                                sponsor={userData}
                                                showEdit={itsMe}
                                                showPlaceholders={itsMe}
                                            />
                                        )}
                                    </div>
                                    {athletesSupportList.length > 0 && (
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="content-box">
                                                    <h3 className="title">{t('profile.sponsorshipManager.mySupportedAthletes')}</h3>
                                                    {this.renderTable()}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div data-testid="mobile-gallery" className="profile-screen__body__left__gallery">
                                <DrawerGallery galleryUser={userData} canEdit={itsMe} />
                            </div>
                        )}
                    </div>
                    <div className="profile-screen__body__right">
                        <DrawerGallery galleryUser={userData} canEdit={itsMe} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(
    withSponsorContext(
        withUserContext(
            withTranslationContext(
                SponsorScreen,
            ),
        ),
    ),
);
