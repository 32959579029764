/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { ReactSVG } from 'react-svg';

import iconImageEdit from '../../assets/images/icon_image_edit.svg';
import deleteIcon from '../../assets/images/delete.svg';

interface OwnProps {
    image?: any;
    onEdit?: Function;
    onDelete?: Function;
    tooltip?: string;
}

type Props = OwnProps;

const CoverImage: FunctionComponent<Props> = (
    {
        image,
        onEdit = () => {},
        onDelete = () => {},
        tooltip = '',
    }: Props,
) => {
    return (
        <div className="cover cover--editable">
            <div className="cover__tooltip">
                <div>
                    <ReactSVG wrapper="span" src={iconImageEdit} onClick={() => onEdit()} />
                    <ReactSVG wrapper="span" src={deleteIcon} onClick={() => onDelete()} />
                </div>
                {tooltip}
            </div>
            <div className="image">
                <img src={image} alt="" />
            </div>
        </div>
    );
};

export default CoverImage;
