/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import { PendingAthleteSupporters } from '../constants/sponsor';

/**
 * SupporterAction
 * @enum {string}
 */
export enum AthleteSupporterAction {
    SET_PENDING_ATHLETE_SUPPORTERS = 'SET_PENDING_ATHLETE_SUPPORTERS',
    RESET_PENDING_ATHLETE_SUPPORTERS = 'RESET_PENDING_ATHLETE_SUPPORTERS'
}

/**
 * @typedef {Object} SetAthleteSupportersAction
 * @property {string} type
 * @property {any[]} payload
 */
export interface SetAthleteSupportersAction {
    type: typeof AthleteSupporterAction.SET_PENDING_ATHLETE_SUPPORTERS;
    payload: PendingAthleteSupporters;
}

/**
 * @typedef {Object} ResetAthleteSupportersAction
 * @property {string} type
 */
export interface ResetAthleteSupportersAction {
    type: typeof AthleteSupporterAction.RESET_PENDING_ATHLETE_SUPPORTERS;
}

export type AthleteSupporterActionTypes = SetAthleteSupportersAction | ResetAthleteSupportersAction;
