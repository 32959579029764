/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Avatar from './Avatar';
import defaultAvatar from '../../assets/images/avatar_default.svg';
import badgeStar from '../../assets/images/badge_star.svg';
import { getProfileUrl } from '../../utils/misc';
import { MatchParams } from '../../constants/misc';

interface OwnProps {
    userId: number;
    userRole: string;
    badgeId: number;
    badgeUrl: string;
    refreshScreen: (id: string) => void;
}

type Props = OwnProps & RouteComponentProps<MatchParams>;

const Badges: FunctionComponent<Props> = ({
    userId,
    userRole,
    badgeId,
    badgeUrl,
    history,
    refreshScreen,
}: Props) => {
    const onClickBadge = (role: string, id: number) => {
        history.push(getProfileUrl(role, id));
        refreshScreen(String(id));
    };

    return (
        <div className="content-box__item content-box__item--sponsored" data-testid="badges-component">
            <Avatar
                key={badgeId}
                image={badgeUrl || defaultAvatar}
                badge={badgeStar}
                onClick={() => onClickBadge(userRole, userId)}
            />
        </div>
    );
};

export default withRouter(Badges);
