/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import sportTestResultBadge from '../../assets/images/sportest-badge.svg';
import { Sportest } from '../../constants/sportest';

/**
 * @typedef {Object} OwnProps
 */
 interface OwnProps {
    sportTest: Sportest | null;
}

/**
 * @typedef {Object} Props
 */
type Props = TranslationContext & OwnProps;

/**
 * Component that shows a badger for the result of sport test
 * @param {Props} props
 * @returns {FunctionComponent<Props>}
 */
export const SportTestBadge: FunctionComponent<OwnProps> = withTranslationContext((props: Props) => {
    const {
        t, sportTest,
    } = props;
    
    return (
        <div className="profile-stats__sportest-badge">
            <span className="profile-stats__sportest-badge__title">
                { t('athlete.sportTest.score') }
            </span>
            <img src={sportTestResultBadge} alt="sportest result badge" />
            <span className="profile-stats__sportest-badge__result">{sportTest?.score || '?'}</span>
        </div>
    );
});
