/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { ReactSVG } from 'react-svg';

import defaultAvatar from '../../assets/images/avatar_default.svg';
import iconImageEdit from '../../assets/images/icon_image_edit.svg';
import deleteIcon from '../../assets/images/delete.svg';

/**
 * @typedef {Object} OwnProps
 * @property {any} [image]
 * @property {string} [badge]
 * @property {boolean} [small]
 * @property {Function} [onClick]
 * @property {Function} [onEdit]
 * @property {Function} [onDelete]
 * @property {boolean} [editable]
 */
interface OwnProps {
    image?: any;
    badge?: string;
    small?: boolean;
    onClick?: Function;
    onEdit?: Function;
    onDelete?: Function;
    editable?: boolean;
    testId?: string;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * Component that shows an avatar
 * @param {Props} props
 * @returns {FunctionComponent<Props>}
 */
const Avatar: FunctionComponent<Props> = (
    {
        image,
        badge = '',
        small = false,
        onClick = () => {},
        onEdit = () => {},
        onDelete = () => {},
        editable = false,
        testId = 'user-avatar',
    }: Props,
) => {
    return (
        <div
            className={`avatar ${small ? 'avatar--small' : ''} ${editable ? 'avatar--editable' : ''}`}
            data-testid={testId}
            onClick={editable ? () => {} : () => onClick()}
        >
            <div className="avatar--cover">
                { editable && (
                    <>
                        <ReactSVG
                            wrapper="span"
                            src={iconImageEdit}
                            onClick={() => onEdit()}
                        />
                        <ReactSVG wrapper="span" src={deleteIcon} onClick={() => onDelete()} />
                    </>
                )
            }
            </div>
            <div className="image">
                <img src={image || defaultAvatar} alt="" />
            </div>
            {badge && (
                <div className="badge">
                    <img src={badge} alt="" />
                </div>
            )}
        </div>
    );
};

export default Avatar;
