/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { DropdownOption } from './misc';

export enum SPORTEST_QUESTIONS {
    ATLHETE_BEST_POSITION = 'ATLHETE_BEST_POSITION',
    ATLHETE_BUDGET = 'ATLHETE_BUDGET',
    ATLHETE_EVENT = 'ATLHETE_EVENT',
    MODALITY = 'MODALITY',
    SPONSOR_BUDGET = 'SPONSOR_BUDGET',
    SPONSOR_PACK = 'SPONSOR_PACK',
}

export enum SPORTEST_TYPES {
    ATHLETE = 'ATHLETE',
    FAN = 'FAN',
    SPONSOR = 'SPONSOR',
}

export enum SPONSOR_TYPE {
    OTHER = 'OTHER',
    PRIVATE_COMPANY = 'PRIVATE_COMPANY',
    SPORTS_AGENCY = 'SPORTS_AGENCY',
    SPORTS_CLUB = 'SPORTS_CLUB',
    SPORTS_INSTITUTION = 'SPORTS_INSTITUTION',
    SPORTS_ORGANIZER = 'SPORTS_ORGANIZER',
}

export interface SportestCreate {
    athleteBestResultEventAnswerId: DropdownOption | null;
    athleteBestResultPositionAnswerId: DropdownOption | null;
    athleteBudgetAnswerId: DropdownOption | null;
    athleteModalityAnswerId: DropdownOption | null;
    fanInterest: string;
    fanModalityAnswerId: DropdownOption | null;
    sponsorEntityType: DropdownOption | null;
    sponsorModalityAnswerId: DropdownOption | null;
    sponsorSponsorshipBudgetAnswerId: DropdownOption | null;
    sponsorSponsorshipPackAnswerId: DropdownOption | null;
    sportestType: SPORTEST_TYPES;
}

export interface Sportest {
    lastModifiedDate: string;
    score: number;
    sportestType: SPORTEST_TYPES;
}
