import { SponsorAthletesSupport } from '../constants/sponsor';

export enum SocketEventType {
    WALLET_UPDATE = 'WALLET_UPDATE',
    SUPPORTERS_UPDATE = 'SUPPORTERS_UPDATE',
}

export type WalletUpdate = {type: SocketEventType.WALLET_UPDATE; payload: {totalCredits: number}};

export type SupportUpdate = {type: SocketEventType.SUPPORTERS_UPDATE; payload: Partial<SponsorAthletesSupport> };
