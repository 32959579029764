/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga4';

import AppRouter from './components/Router';
import { configureStore } from './store';
import { setupAPI } from './api';
import { GOOGLE_ANALYTICS_KEY } from './settings';

const { persistor, store } = configureStore();

class App extends Component {
    state = { isReady: false };

    async componentDidMount() {
        const hjid = 2076574;
        const hjsv = 6;
        await setupAPI(store);
        hotjar.initialize(hjid, hjsv);
        this.setState({ isReady: true });
        
        if (!GOOGLE_ANALYTICS_KEY) {
            throw new Error('Please configure Google Analytics');
        }
        ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
    }

    render() {
        const {
            isReady,
        } = this.state;

        if (!isReady) return null;

        return (
            <div className="app-container">
                <PersistGate persistor={persistor}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <AppRouter />
                        </BrowserRouter>
                    </Provider>
                </PersistGate>
            </div>
        );
    }
}

export default App;
