/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
import { IS_TEST_ENVIRONMENT } from '../../settings';
import { AppRoute } from '../../constants/routes';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps {
    error: Error;
}

type Props = OwnProps & TranslationContext;

const ErrorScreen: FunctionComponent<Props> = (props: Props) => {
    const { error, t } = props;

    const { resetBoundary } = useErrorBoundary();
    const history = useHistory();

    return (
        <div className="error-screen">
            <h1>{t('somethingWentWrong.title')}</h1>
            {IS_TEST_ENVIRONMENT && (
                <p>{error.message}</p>
            )}
            <div className="error-screen__buttons-group">
                <button
                    type="button"
                    onClick={() => {
                        resetBoundary();
                        history.goBack();
                    }}
                >
                    {t('somethingWentWrong.goBack')}
                </button>
                <button
                    type="button"
                    onClick={() => {
                        resetBoundary();
                        history.push(AppRoute.Index);
                    }}
                >
                    {t('somethingWentWrong.returnToHome')}
                </button>
            </div>
        </div>
    );
};

export default withTranslationContext(ErrorScreen);
