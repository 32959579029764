/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PaginationContextProvider } from './PaginationContext';
import { List } from '../../../constants/misc';
import { TranslationContext, withTranslationContext } from '../translation/TranslationContext';
   
interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps & TranslationContext;
 
interface OwnState<T> {
    currentPage: number;
    totalPages: number;
    fetchPromise: (params?: Record<string, string | number>) => Promise<List<T>>;
    limit: number;
    list: T[];
}

type State<T> = OwnState<T>;
 
const initialState: State<any> = {
    currentPage: 0,
    totalPages: 1,
    fetchPromise: async () => ({ list: [], total: 0 }),
    limit: 10,
    list: [],
};
   
export class PaginationController<T> extends Component<Props, State<T>> {
    state = { ...initialState }

    fetch = async (currentPage: number, append = false): Promise<void> => {
        const { handleAPIErrors } = this.props;
        const {
            fetchPromise, limit, list,
        } = this.state;

        try {
            const { list: newList, total } = await fetchPromise({ page: currentPage });
            this.setState({
                list: append ? [...list, ...newList] : [...newList],
                currentPage,
                totalPages: Math.ceil(total / limit),
            });
        } catch {
            handleAPIErrors({}, true);
        }
    }

    paginate = async (promise: (params?: Record<string, string | number>) => Promise<List<T>>, limitOverride?: number): Promise<T[]> => {
        const { limit } = this.state;

        try {
            const { list, total } = await promise({ limit: limitOverride || limit });
            const totalPages = Math.ceil(total / (limitOverride || limit));

            this.setState({
                list,
                totalPages,
                limit,
                currentPage: 0,
                fetchPromise: (params) => promise({ ...params, limit: limitOverride || limit }),
            });
            return list;
        } catch {
            return [];
        }
    }

    previousPage = async (): Promise<void> => {
        const { currentPage } = this.state;

        const newCurrentPage = currentPage - 1;
        
        if (newCurrentPage < 0) {
            return;
        }

        await this.fetch(newCurrentPage);
    }

    nextPage = async (): Promise<void> => {
        const { currentPage, totalPages } = this.state;

        const newCurrentPage = currentPage + 1;
        
        if (newCurrentPage >= totalPages) {
            return;
        }

        await this.fetch(newCurrentPage, true);
    }

    goToPage = async (page: number): Promise<void> => {
        const { totalPages } = this.state;
        
        if (page > totalPages) {
            return;
        }

        await this.fetch(page);
    }

    render(): React.ReactNode {
        const {
            children,
        } = this.props;

        const { totalPages, currentPage, list } = this.state;

        return (
            <PaginationContextProvider
                value={{
                    paginate: this.paginate,
                    nextPage: this.nextPage,
                    previousPage: this.previousPage,
                    goToPage: this.goToPage,
                    list,
                    totalPages,
                    currentPage,
                }}
            >
                {children}
            </PaginationContextProvider>
        );
    }
}
   
export const ConnectedPaginationController = connect()(withTranslationContext(PaginationController));
