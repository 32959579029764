/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FormEvent, FunctionComponent, useState } from 'react';
import { withTranslationContext, TranslationContext } from '../controllers/translation/TranslationContext';
import { Button } from './Button';
import IconTimes from '../assets/IconTimes';
import iconSearch from '../../assets/images/search.svg';
import FormField from './FormField';
import { KeyedObject } from '../../constants/misc';
import { validateForm, ValidationType } from '../../utils/validations';

interface OwnProps extends TranslationContext {
    onSearchSubmit(searchValue: string | null): void;
    searchValue: string | null;
    disabled?: boolean;
    isAdmin?: boolean;
}

const SearchInputComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, disabled, searchValue, isAdmin, onSearchSubmit,
    } = props;

    const [value, setValue] = useState(searchValue);
    const [errors, setErrors] = useState<KeyedObject | null>(null);
    
    const search = t('general.search');

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        
        if (isAdmin) {
            const validationErrors = validateSearchInput(value);
        
            if (validationErrors) {
                setErrors(validationErrors);
            }
        }

        onSearchSubmit(value);
    };
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        setValue(newValue);
        
        const validationErrors = validateSearchInput(newValue);
        setErrors(validationErrors);
    };
    
    const validateSearchInput = (input: string | null): KeyedObject | null => {
        return validateForm(
            { search: input },
            {
                search: {
                    validations: [ValidationType.Length],
                    length: { lowerLimit: 3, upperLimit: 200 },
                },
            },
        );
    };
    
    return (
        <form onSubmit={onSubmit} className={`search-input ${isAdmin ? 'search-input--admin' : ''}`}>
            {isAdmin ? (
                <FormField
                    cssClass={`search-input ${isAdmin ? 'search-input--admin' : ''} ${value ? 'active' : ''}`}
                    name="search"
                    value={value}
                    type="text"
                    placeholder={search}
                    onChange={handleChange}
                    disabled={disabled}
                    errors={errors}
                />
            ) : (
                <input
                    className={`search-input ${value ? 'active' : ''}`}
                    name="search"
                    value={value ?? ''}
                    type="text"
                    placeholder={search}
                    onChange={(e) => setValue(e.currentTarget.value)}
                    disabled={disabled}
                    aria-label={search}
                    maxLength={200}
                />
            )}
            <Button
                isSubmit
                extraClasses={`btn-search ${isAdmin ? 'btn-search--admin' : ''} ${value ? 'active' : ''}`}
                ariaLabel={t('search.searchBtn')}
            >
                <img src={iconSearch} alt="search-icon" />
            </Button>
            <Button
                isSubmit
                extraClasses={`btn-close ${isAdmin ? 'btn-close--admin' : ''} ${value ? 'active' : ''}`}
                onClick={() => setValue('')}
                ariaLabel={t('search.closeBtn')}
            >
                <IconTimes fill="black" />
            </Button>
        </form>
    );
};

export const SearchInput = withTranslationContext(SearchInputComponent);
