/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import deleteIcon from '../../assets/images/delete.svg';
import closeIcon from '../../assets/images/icon_close.svg';
import loader from '../../assets/images/loader.svg';
import { ErrorCode } from '../../constants/errors';
import {
    GALLERY, MatchParams, Media, MediaType,
} from '../../constants/misc';
import { AppRoute } from '../../constants/routes';
import { User, UserRoles } from '../../types/user';
import { athleteDeleteMediaURL, athleteMediaURL } from '../../services/athletes';
import { fanDeleteMediaURL, fanMediaURL } from '../../services/fans';
import { managerDeleteMediaURL, managerMediaURL } from '../../services/managers';
import { sponsorDeleteMediaURL, sponsorMediaURL } from '../../services/sponsors';
import { displayError } from '../../utils/notifications';
import IconAdd from '../assets/IconAdd';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../controllers/user/UserContext';
import ButtonShare from './ButtonShare';
import { ConfirmationModal } from './ConfirmationModal';
import { buildRoute } from '../../utils/misc';

enum Tab {
    Photos = 'PHOTOS',
    Videos = 'VIDEOS',
    All = 'ALL',
}

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext, AuthenticationContext, UserContext {
    galleryUser: User | null;
    canEdit?: boolean;
    reFetchGallery?: boolean;
    onReFetchGallery?: () => void;
}

type Props = OwnProps;

interface OwnState {
    currentTab: Tab;
    selectedMedia: Media | null;
    mediaToDelete: Media | null;
    videos: Array<Media>;
    photos: Array<Media>;
    isUploadingVideo: boolean;
    mediaId: number | null;
}

type State = OwnState;

const initialState: State = {
    currentTab: Tab.All,
    selectedMedia: null,
    mediaToDelete: null,
    videos: [],
    photos: [],
    isUploadingVideo: false,
    mediaId: null,
};

class DrawerGallery extends Component<Props, State> {
    state = initialState;

    private readonly galleryRef = React.createRef<HTMLInputElement>();

    private readonly noResultLabel = 'general.noResults';

    componentDidMount() {
        const { match } = this.props;

        this.setState({
            mediaId: match.params.mediaId ? Number(match.params.mediaId) : null,
        }, () => this.prepare());
    }

    componentDidUpdate(prevProps: OwnProps) {
        const { galleryUser, reFetchGallery, onReFetchGallery } = this.props;
        const { reFetchGallery: prevReFetchGallery } = prevProps;
        if (prevProps.galleryUser?.id !== galleryUser?.id) {
            this.prepare();
        }

        if (reFetchGallery && onReFetchGallery && !prevReFetchGallery) {
            onReFetchGallery();

            setTimeout(this.prepare, 3500);
        }
    }

    onGalleryFileDialog = () => {
        if (this.galleryRef.current) {
            this.galleryRef.current.click();
        }
    };

    onGallerySelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { t } = this.props;

        const { files } = e.target;

        if (!files) return;

        const { galleryUpdate } = this.props;

        const reader = new FileReader();
        const file = files[0];

        if (file.size > 200 * 1000 * 1000) {
            displayError({
                message: t('profile.uploadSizeError'),
            });
            return;
        }

        this.setState({
            isUploadingVideo: true,
        });

        e.target.value = '';

        reader.onloadend = () => {
            galleryUpdate(file, this.prepare, (errors: any, status: number) => {
                if (errors.errors && errors.errors.length > 0) {
                    errors.errors.forEach((error: any) => {
                        displayError({
                            message: t(`errors.${ErrorCode[error.errorCode]}`),
                        });
                    });
                } else {
                    if (status === 403) {
                        displayError({
                            message: t('profile.uploadRoleError'),
                        });
                    } else {
                        displayError({
                            message: t('profile.uploadError'),
                        });
                    }
                }

                this.setState({
                    isUploadingVideo: false,
                });
            });
        };

        reader.readAsDataURL(file);
    };

    onMediaSelected = (media: Media) => {
        const { history, galleryUser } = this.props;
        let userBaseRoute = '';

        this.setState({
            selectedMedia: media,
        });

        switch (galleryUser?.role) {
            case UserRoles.Athlete:
                userBaseRoute = AppRoute.AthleteGallery;
                break;
            case UserRoles.Fan:
                userBaseRoute = AppRoute.FanGallery;
                break;
            case UserRoles.Sponsor:
                userBaseRoute = AppRoute.SponsorGallery;
                break;
            case UserRoles.Manager:
                userBaseRoute = AppRoute.ManagerGallery;
                break;
            default:
        }
        
        history.push(buildRoute(userBaseRoute, { id: galleryUser?.id, mediaId: media.id }));
    }

    /**
     * handles media modal close
     */
    onCloseMediaModal = () => {
        const {
            history, galleryUser,
        } = this.props;

        this.setState({
            selectedMedia: null,
        });

        let baseRoute = '';

        switch (galleryUser?.role) {
            case UserRoles.Athlete:
                baseRoute = AppRoute.Athlete;
                break;
            case UserRoles.Fan:
                baseRoute = AppRoute.Fan;
                break;
            case UserRoles.Sponsor:
                baseRoute = AppRoute.Sponsor;
                break;
            case UserRoles.Manager:
                baseRoute = AppRoute.Manager;
                break;
            default:
        }

        history.push(buildRoute(baseRoute, { id: galleryUser?.id }));
    }

    onTabClick = (tab: Tab) => this.setState({
        currentTab: tab,
    });

    /**
     * prepares data for the view
     */
    prepare = async () => {
        const { galleryUser, history } = this.props;
        const { mediaId } = this.state;

        let url = '';
        switch (galleryUser?.role) {
            case UserRoles.Athlete:
                url = athleteMediaURL(galleryUser.id);
                break;
            case UserRoles.Fan:
                url = fanMediaURL(galleryUser.id);
                break;
            case UserRoles.Sponsor:
                url = sponsorMediaURL(galleryUser.id);
                break;
            case UserRoles.Manager:
                url = managerMediaURL(galleryUser.id);
                break;
            default:
        }

        if (url) {
            axios.get(url).then((media: any) => {
                const gallery = media.data as Array<Media>;

                const selectedMedia = gallery.find((item) => item.id === mediaId);

                this.setState({
                    photos: gallery.filter((gal) => gal.mediaType === MediaType.IMAGE),
                    videos: gallery.filter((gal) => gal.mediaType === MediaType.VIDEO),
                    isUploadingVideo: false,
                    selectedMedia: selectedMedia || null,
                }, () => {
                    if (mediaId && !selectedMedia) {
                        history.push(AppRoute.NotFound);
                    }
                });
            });
        }
    };

    deleteMedia = () => {
        const { mediaToDelete } = this.state;
        const { user } = this.props;

        if (mediaToDelete !== null) {
            let url = '';
            switch (user?.role) {
                case UserRoles.Athlete:
                    url = athleteDeleteMediaURL(mediaToDelete.id);
                    break;
                case UserRoles.Fan:
                    url = fanDeleteMediaURL(mediaToDelete.id);
                    break;
                case UserRoles.Sponsor:
                    url = sponsorDeleteMediaURL(mediaToDelete.id);
                    break;
                case UserRoles.Manager:
                    url = managerDeleteMediaURL(mediaToDelete.id);
                    break;
                default:
            }
            axios.delete(url).then(this.prepare);
        }
        this.setState({ mediaToDelete: null });
    };

    promptDeleteMedia = (evt: React.MouseEvent, media: Media) => {
        evt.stopPropagation();
        this.setState({ mediaToDelete: media });
    };

    renderVideosTab = (): React.ReactNode => {
        const { videos } = this.state;
        const { t, canEdit } = this.props;
        if (videos && videos.length > 0) {
            return videos.map((video) => (
                <button
                    type="button"
                    key={video.id}
                    className="gallery-drawer__content__image"
                    style={{ backgroundImage: `url(${video.thumbnail})` }}
                    onClick={(): void => this.onMediaSelected(video)}
                    aria-label={t('gallery.video')}
                >
                    {canEdit && (
                        <img src={deleteIcon} alt="delete" onClick={(evt) => this.promptDeleteMedia(evt, video)} />
                    )}
                </button>
            ));
        }

        return <p>{t(this.noResultLabel)}</p>;
    };

    renderPhotosTab = (): React.ReactNode => {
        const { photos } = this.state;
        const { t, canEdit } = this.props;
        if (photos && photos.length > 0) {
            return photos.map((photo) => (
                <button className="gallery-drawer__content__image" style={{ backgroundImage: `url(${photo.main})` }} key={photo.id} type="button" aria-label={t('gallery.photo')} onClick={(): void => this.onMediaSelected(photo)}>
                    {canEdit && (
                        <img src={deleteIcon} alt="delete" onClick={(evt) => this.promptDeleteMedia(evt, photo)} />
                    )}
                </button>
            ));
        }

        return <p>{t(this.noResultLabel)}</p>;
    };

    renderAllTab = (): React.ReactNode => {
        const { photos, videos } = this.state;
        const { t } = this.props;

        if (photos?.length === 0 && videos?.length === 0) return <p>{t(this.noResultLabel)}</p>;

        return (
            <React.Fragment>
                {photos && photos.length > 0 && this.renderPhotosTab()}
                {videos && videos.length > 0 && this.renderVideosTab()}
            </React.Fragment>
        );
    };

    renderModal = (): React.ReactNode => {
        const { history, t } = this.props;
        const { selectedMedia } = this.state;

        const linkURL = history.location.pathname;

        if (selectedMedia === null || !linkURL.includes(GALLERY)) {
            return <React.Fragment />;
        }

        return (
            <div
                className="modal"
                onClick={(): void => this.onCloseMediaModal()}
                data-testid="photo-modal"
            >
                <div className="modal__test" />
                <div className="modal__content__test test">
                    <div className="gallery-modal">
                        <div className="content">
                            <ButtonShare
                                linkURL={linkURL}
                                title={selectedMedia.mediaType === MediaType.VIDEO ? t('share.gallery.titleVideo') : t('share.gallery.titlePhoto')}
                            />
                            <div
                                className="content__close-btn"
                                onClick={(): void => this.onCloseMediaModal()}
                            >
                                <img
                                    src={closeIcon}
                                    alt="close icon"
                                />
                            </div>
                            {selectedMedia.mediaType === MediaType.IMAGE && <img src={selectedMedia?.main} alt="" />}
                            {selectedMedia.mediaType === MediaType.VIDEO && (
                                // eslint-disable-next-line jsx-a11y/media-has-caption
                                <video src={selectedMedia?.main} autoPlay controls />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderConfirmationModal = (): React.ReactNode => {
        const { mediaToDelete } = this.state;

        if (mediaToDelete === null) {
            return <React.Fragment />;
        }
        return (
            <ConfirmationModal
                titleTranslationKey="gallery.confirmation"
                cancelTranslationKey="gallery.cancel"
                deleteTranslationKey="gallery.delete"
                onClose={(): void => this.setState({ mediaToDelete: null })}
                onAccept={this.deleteMedia}
            />
        );
    };

    renderFileInput = () => {
        return (
            <input
                id="gallery-input"
                type="file"
                ref={this.galleryRef}
                onChange={this.onGallerySelected}
            />
        );
    };

    render() {
        const { canEdit, t } = this.props;
        const { currentTab, isUploadingVideo } = this.state;

        return (
            <div className="gallery-drawer" data-testid="gallery-drawer">
                {this.renderFileInput()}
                <div className="gallery-drawer__top">
                    <div className="gallery-drawer__top__left">
                        <div className="title title--alternative buttons">
                            <button
                                type="button"
                                className={`${currentTab === Tab.All ? 'active' : ''}`}
                                onClick={() => this.onTabClick(Tab.All)}
                            >
                                {t('gallery.all')}
                            </button>
                            <button
                                type="button"
                                className={`${currentTab === Tab.Photos ? 'active' : ''}`}
                                onClick={() => this.onTabClick(Tab.Photos)}
                            >
                                {t('gallery.photos')}
                            </button>
                            <button
                                type="button"
                                className={`${currentTab === Tab.Videos ? 'active' : ''}`}
                                onClick={() => this.onTabClick(Tab.Videos)}
                            >
                                {t('gallery.videos')}
                            </button>
                        </div>
                        {canEdit && (
                            <div className="icon-add">
                                <IconAdd fill="#182b20" onClick={this.onGalleryFileDialog} />
                            </div>
                        )}
                    </div>
                </div>
                {this.renderModal()}
                {this.renderConfirmationModal()}
                <div className="gallery-drawer__content">
                    {currentTab === Tab.All && this.renderAllTab()}
                    {currentTab === Tab.Videos && this.renderVideosTab()}
                    {currentTab === Tab.Photos && this.renderPhotosTab()}
                    {isUploadingVideo && (currentTab === Tab.Videos || currentTab === Tab.All) && (
                        <div
                            className="gallery-drawer__content__image gallery-drawer__content__image--loader"
                        >
                            <ReactSVG wrapper="span" src={loader} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withUserContext(withAuthenticationContext(withTranslationContext(withRouter(DrawerGallery))));
