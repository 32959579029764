/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, Route } from 'react-router-dom';

import { ErrorBoundary } from 'react-error-boundary';
import ReactGA from 'react-ga4';
import ContentArea from '../elements/ContentArea';
import { MatchParams } from '../../constants/misc';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { websocketURL } from '../../services/authentication';
import { UserContext, withUserContext } from '../controllers/user/UserContext';
import ErrorScreen from './ErrorScreen';
import { WS } from '../../utils/websocket';
import { CreditsUpdateEvent, UserRoles } from '../../types/user';
import { SocketEventType } from '../../types/websockets';
import { GACategories } from '../../utils/analytics';
import { SponsorContext, withSponsorContext } from '../controllers/sponsor/SponsorContext';

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, UserContext, SponsorContext {
    id: string;
}

type Props = OwnProps;

interface OwnState {
    isSocketConnecting: boolean;
}

const initialState: OwnState = {
    isSocketConnecting: false,
};

type State = OwnState;

class IndexScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount() {
        const {
            isAuthenticated, history, fetchAthletePendingSupports, user,
        } = this.props;
        const { isSocketConnecting } = this.state;
        
        ReactGA.send({ hitType: 'pageview', page: '/', title: 'IndexScreen' });
        
        history.listen((location) => {
            ReactGA.send({ hitType: 'pageview', page: location.pathname, title: location.pathname });
        });
        window.addEventListener('beforeunload', () => this.beforeUnloadHandler());

        if (isAuthenticated && !isSocketConnecting) {
            this.configSocket();
        }

        if (user?.role === UserRoles.Athlete) fetchAthletePendingSupports();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const { isAuthenticated, user, fetchAthletePendingSupports } = this.props;
        const { isAuthenticated: prevIsAuthenticated } = prevProps;
        const { isSocketConnecting } = this.state;

        if (isAuthenticated && !prevIsAuthenticated) {
            if (!isSocketConnecting) this.configSocket();
            if (user?.role === UserRoles.Athlete) fetchAthletePendingSupports();
        }
    }

    componentWillUnmount() {
        WS.removeHandler(SocketEventType.WALLET_UPDATE, this.handleSocketMessage);
    }

    handleSocketMessage = ({ payload: creditsData }: {payload: CreditsUpdateEvent}) => {
        const { updateUserWallet } = this.props;

        updateUserWallet(creditsData.totalCredits);
    }

    beforeUnloadHandler = () => {
        const { history } = this.props;

        ReactGA.event({
            category: GACategories.CLOSED_WINDOW,
            action: `Closed window at: ${history.location.pathname}`,
        });
    }

    configSocket = async () => {
        const { getSocketToken } = this.props;

        const socketToken = await getSocketToken();

        if (!socketToken) return;

        this.setState({ isSocketConnecting: true });

        WS.initConnection('type', { websocketURL: websocketURL(socketToken) });

        WS.addHandler(SocketEventType.WALLET_UPDATE, this.handleSocketMessage);
    }

    render() {
        const {
            match,
        } = this.props;
        const { path } = match;

        return (
            <div className="index-screen" data-testid="index-screen">
                <ErrorBoundary FallbackComponent={ErrorScreen}>
                    <Route path={path} component={ContentArea} />
                </ErrorBoundary>
            </div>
        );
    }
}

export default withSponsorContext(withUserContext(withAuthenticationContext(IndexScreen)));
