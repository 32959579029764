/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

export const supportsDefault = {
    voidId: 154184,
    qsrId: 154185,
    mbuId: 154186,
};

export enum SUPPORT_STATUS {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    REJECTED = 'REJECTED',
    EXPIRED = 'EXPIRED',
    CLOSED = 'CLOSED',
    CANCELLED = 'CANCELLED'
}
