/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { UserContextProvider } from './UserContext';
import { AppState, AuthenticationState } from '../../../reducers/types';
import {
    FanProfileFormFields,
    ProfileFormFields,
    ProfilePostData,
    SponsorProfileFormFields,
    User,
    ManagerProfileFormFields,
    ManagerProfilePostData,
    UserRoles,
    SendMessageRequest,
    SponsorProfilePostData,
    FanProfilePostData,
} from '../../../types/user';
import {
    ApiError, KeyedObject, List, ListParams, ListResult, TOTAL_ITEMS,
} from '../../../constants/misc';
import { REGEX, validateForm, ValidationType } from '../../../utils/validations';
import { avatarURL, coverURL, galleryURL } from '../../../services/files';
import { sponsorsURL, sponsorURL } from '../../../services/sponsors';
import { athletesURL, athleteURL } from '../../../services/athletes';
import { fansURL, fanURL } from '../../../services/fans';
import { setAuthenticatedUserActionCreator, setAuthentication } from '../../../actions/authentication';
import { achievementsURL, achievementURL } from '../../../services/achievements';
import {
    adminUsersSearchURL,
    adminUsersURL,
    toggleVisibilityURL,
    userSportestsURL, userURL, usersSearchURL, usersURL,
} from '../../../services/users';
import { Sportest } from '../../../constants/sportest';
import { Achievement, AchievementRequest } from '../../../constants/achievements';
import { paginatedClubsURL, clubsModalitiesURL, clubsURL } from '../../../services/clubs';
import { managersURL, managerURL } from '../../../services/managers';
import { displayError, displaySuccess } from '../../../utils/notifications';
import { TranslationContext, withTranslationContext } from '../translation/TranslationContext';
import { adminURL } from '../../../services/admins';
import { directMessageURL } from '../../../services/message';
import { AppDispatch } from '../../../store';
import { QueryParams } from '../../../types/misc';

type StateProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
    children: ReactNode;
}

type Props = StateProps & OwnProps & TranslationContext & ReturnType<typeof mapDispatchToProps>;

/**
 * provides store properties and actions access to its consumers
 * @extends {Component<Props>}
 */
export class UserController extends Component<Props> {
    /**
     * validates the profile update fields
     */
    validateProfileUpdate = (fields: ProfileFormFields): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(fields, {
            name: {
                validations: [ValidationType.NotBlank],
            },
            lastName: {
                validations: [ValidationType.NotBlank],
            },
            weight: {
                validations: [ValidationType.Regex],
                regex: REGEX.FLOAT,
            },
            height: {
                validations: [ValidationType.Regex],
                regex: REGEX.FLOAT,
            },
            phrase: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 255,
                },
            },
            biography: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 2000,
                },
            },
            objectives: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 2000,
                },
            },
            address: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 255,
                },
            },
            countryCode: {
                validations: [ValidationType.NotBlank],
            },
            fiscalCode: {
                validations: [ValidationType.NotBlank],
            },
            postalCode: {
                validations: [ValidationType.NotBlank],
            },
        });

        if (errors && Object.keys(errors).length === 0) errors = null;
        return errors;
    };

    /**
     * validates the fan profile update fields
     */
    validateFanProfileUpdate = (fields: FanProfileFormFields): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(fields, {
            name: {
                validations: [ValidationType.NotBlank],
            },
            address: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 255,
                },
            },
            countryCode: {
                validations: [ValidationType.NotBlank],
            },
            fiscalCode: {
                validations: [ValidationType.NotBlank],
            },
            postalCode: {
                validations: [ValidationType.NotBlank],
            },
            phrase: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 255,
                },
            },
            biography: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 2000,
                },
            },
        });

        if (errors && Object.keys(errors).length === 0) errors = null;
        return errors;
    };

    /**
     * validates the sponsor profile update fields
     */
    sponsorValidateProfileUpdate = (fields: SponsorProfileFormFields): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(fields, {
            name: {
                validations: [ValidationType.NotBlank],
            },
            address: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 255,
                },
            },
            countryCode: {
                validations: [ValidationType.NotBlank],
            },
            fiscalCode: {
                validations: [ValidationType.NotBlank],
            },
            postalCode: {
                validations: [ValidationType.NotBlank],
            },
            phone: {
                validations: [ValidationType.NotBlank],
            },
            phrase: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 255,
                },
            },
            biography: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 2000,
                },
            },
            aboutUs: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 2000,
                },
            },
        });

        if (errors && Object.keys(errors).length === 0) errors = null;
        return errors;
    };

    /**
     * validates the profile update fields
     */
    validateManagerProfileUpdate = (fields: ManagerProfileFormFields): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(fields, {
            name: {
                validations: [ValidationType.NotBlank],
            },
            lastName: {
                validations: [ValidationType.NotBlank],
            },
            phrase: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 255,
                },
            },
            biography: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 2000,
                },
            },
            phone: {
                validations: [ValidationType.NotBlank],
            },
            address: {
                validations: [ValidationType.Length],
                length: {
                    lowerLimit: 0,
                    upperLimit: 255,
                },
            },
            countryCode: {
                validations: [ValidationType.NotBlank],
            },
            fiscalCode: {
                validations: [ValidationType.NotBlank],
            },
            postalCode: {
                validations: [ValidationType.NotBlank],
            },
        });

        if (errors && Object.keys(errors).length === 0) errors = null;
        return errors;
    };

    /**
     * validates the achievements create fields
     */
    private validateNewAchievement = (fields: AchievementRequest): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(fields, {
            date: {
                validations: [ValidationType.NotBlank],
            },
            competitionLevel: {
                validations: [ValidationType.NotBlank],
            },
            ageGroup: {
                validations: [ValidationType.NotBlank],
            },
            club: {
                validations: [ValidationType.NotBlank],
            },
            result: {
                validations: [ValidationType.NotBlank],
            },
        });

        if (errors && Object.keys(errors).length === 0) errors = null;
        return errors;
    };

    updateUserWallet = (totalCredits: number): void => {
        const { user, dispatchSetAuthenticatedUser } = this.props;

        if (totalCredits < 0 || !user) {
            return;
        }
        
        dispatchSetAuthenticatedUser({ ...user, availableCredits: totalCredits });
    }

    /**
     * calls the profile update endpoint
     */
    profileUpdate = async (fields: ProfileFormFields, onSuccess: () => void, onError: (errors: KeyedObject) => void, id?: string): Promise<void> => {
        const { dispatchSetAuthentication } = this.props;
        const formData: ProfilePostData = {
            name: fields.name,
            lastName: fields.lastName,
            countryCode: typeof fields.countryCode === 'string' ? fields.countryCode : fields.countryCode?.value,
            postalCode: fields.postalCode,
            fiscalCode: fields.fiscalCode,
            address: fields.address,
            club: fields.club && fields.sport ? { clubCompositeKey: { clubName: fields.club.value, sport: fields.sport.value } } : null,
            weight: parseFloat(fields.weight),
            height: parseFloat(fields.height),
            dateOfBirth: fields.dateOfBirth,
            phrase: fields.phrase,
            biography: fields.biography,
            objectives: fields.objectives,
            avatarId: fields.avatarId,
            coverId: fields.coverId,
        };

        if (id) {
            await axios
                .put(athleteURL(id), formData)
                .then(() => onSuccess())
                .catch((error) => {
                    onError(error?.response?.data);
                });
            return;
        }

        await axios
            .put(athletesURL(), formData)
            .then((response) => {
                if (response.data) {
                    dispatchSetAuthentication(response.data, onSuccess);
                }
            })
            .catch((error) => {
                onError(error?.response?.data);
            });
    };

    /**
     * calls the fan profile update endpoint
     */
    fanProfileUpdate = async (fields: FanProfileFormFields, onSuccess: () => void, onError: (errors: KeyedObject) => void, id?: string): Promise<void> => {
        const { dispatchSetAuthentication } = this.props;
        const formData: FanProfilePostData = {
            name: fields.name,
            countryCode: typeof fields.countryCode === 'string' ? fields.countryCode : fields.countryCode?.value,
            postalCode: fields.postalCode,
            fiscalCode: fields.fiscalCode,
            address: fields.address,
            phrase: fields.phrase,
            biography: fields.biography,
            avatarId: fields.avatarId,
            coverId: fields.coverId,
        };

        if (id) {
            await axios
                .put(fanURL(id), formData)
                .then(() => onSuccess())
                .catch((error) => {
                    onError(error?.response?.data);
                });
            return;
        }

        await axios
            .put(fansURL(), formData)
            .then((response) => {
                if (response.data) {
                    dispatchSetAuthentication(response.data, onSuccess);
                }
            })
            .catch((error) => {
                onError(error?.response?.data);
            });
    };

    /**
     * calls the sponsor profile update endpoint
     */
    sponsorProfileUpdate = async (fields: SponsorProfileFormFields, onSuccess: () => void, onError: (errors: KeyedObject) => void, id?: string): Promise<void> => {
        const { dispatchSetAuthentication } = this.props;
        const formData: SponsorProfilePostData = {
            name: fields.name,
            phone: fields.phone,
            countryCode: typeof fields.countryCode === 'string' ? fields.countryCode : fields.countryCode?.value,
            postalCode: fields.postalCode,
            fiscalCode: fields.fiscalCode,
            address: fields.address,
            phrase: fields.phrase,
            aboutUs: fields.aboutUs,
            biography: fields.biography,
            avatarId: fields.avatarId,
            coverId: fields.coverId,
        };

        if (id) {
            await axios
                .put(sponsorURL(id), formData)
                . then(() => onSuccess())
                .catch((error) => {
                    onError(error?.response?.data);
                });
            return;
        }

        await axios
            .put(sponsorsURL(), formData)
            .then((response) => {
                if (response.data) {
                    dispatchSetAuthentication(response.data, onSuccess);
                }
            })
            .catch((error) => {
                onError(error?.response?.data);
            });
    };

    /**
     * calls the profile update endpoint
     */
    managerProfileUpdate = async (fields: ManagerProfileFormFields, onSuccess: () => void, onError: (errors: KeyedObject) => void, id?: string): Promise<void> => {
        const { dispatchSetAuthentication } = this.props;
        const formData: ManagerProfilePostData = {
            name: fields.name,
            lastName: fields.lastName,
            countryCode: typeof fields.countryCode === 'string' ? fields.countryCode : fields.countryCode?.value,
            postalCode: fields.postalCode,
            fiscalCode: fields.fiscalCode,
            address: fields.address,
            phrase: fields.phrase,
            biography: fields.biography,
            avatarId: fields.avatarId,
            coverId: fields.coverId,
            phone: fields.phone,
        };

        if (id) {
            await axios
                .put(managerURL(id), formData)
                .then(() => onSuccess())
                .catch((error) => {
                    onError(error?.response?.data);
                });
            return;
        }

        await axios
            .put(managersURL(), formData)
            .then((response) => {
                if (response.data) {
                    dispatchSetAuthentication(response.data, onSuccess);
                }
            })
            .catch((error) => {
                onError(error?.response?.data);
            });
    };

    /**
     * uploads a cover image
     */
    coverUpdate = async (file: File, onSuccess: (response: AxiosResponse) => void, onFailure: () => void): Promise<void> => {
        const formData = new FormData();
        formData.append('file', file, file.name);

        await axios
            .post(coverURL(), formData)
            .then((response) => {
                onSuccess(response);
            })
            .catch(() => {
                onFailure();
            });
    };

    /**
     * uploads an avatar image
     */
    avatarUpdate = async (file: File, onSuccess: (response: AxiosResponse) => void, onFailure: () => void): Promise<void> => {
        const formData = new FormData();
        formData.append('file', file, file.name);

        await axios
            .post(avatarURL(), formData)
            .then((response) => {
                onSuccess(response);
            })
            .catch(() => {
                onFailure();
            });
    };

    /**
     * uploads gallery media
     */
    galleryUpdate = async (file: File, onSuccess: (response: AxiosResponse) => void, onFailure: (errors: any, status: number) => void): Promise<void> => {
        const formData = new FormData();
        formData.append('file', file, file.name);

        await axios
            .post(galleryURL(), formData)
            .then((response) => {
                onSuccess(response);
            })
            .catch((error) => {
                let formErrors = {};
                if (error && error.response) {
                    formErrors = error.response.data;
                }
                onFailure(formErrors, error.response.status);
            });
    };

    /**
     * fetches athletes clubs by a sport
     */
    getClubsBySport = async (sport: string): Promise<string[]> => {
        try {
            const res = await axios.get(clubsModalitiesURL(sport));
            return res.data;
        } catch (error) {
            return [];
        }
    };
    
    getPaginatedClubs = async (): Promise<List<string>> => {
        try {
            const { data, headers } = await axios.get(paginatedClubsURL());
            
            return { list: data, total: headers[TOTAL_ITEMS] };
        } catch {
            return { list: [], total: 0 };
        }
    };

    getAllClubs = async (): Promise<List<string>> => {
        try {
            const res = await axios.get(clubsURL());
            return { list: res.data, total: res.headers[TOTAL_ITEMS] };
        } catch {
            return { list: [], total: 0 };
        }
    }

    getDropdownClubs = async (club?: string): Promise<List<string>> => {
        const clubs = club ? await this.getAllClubs() : await this.getPaginatedClubs();
        return { list: clubs.list, total: clubs.total };
    }

    /**
     * adds new achievement
     */
    addNewAchievement = async (payload: AchievementRequest, onFailure: (errors: KeyedObject) => void, onSuccess?: (payloadRes: Achievement) => void): Promise<void> => {
        const errors = this.validateNewAchievement(payload);
        if (errors) {
            onFailure({ fields: errors });
            return;
        }

        try {
            const res = await axios.post(achievementsURL(), payload);
            if (onSuccess) onSuccess(res.data);
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err?.response?.data);
        }
    };

    /**
     * deletes existing achievement
     */
    deleteAchievement = async (id: number, onFailure: (args?: ApiError) => void, onSuccess?: (key: number) => void): Promise<void> => {
        try {
            await axios.delete(achievementURL(id));
            if (onSuccess) onSuccess(id);
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err?.response?.data);
        }
    };

    /**
     * deletes existing user
     */
    deleteUser = async (user: User): Promise<void> => {
        const { t } = this.props;
        try {
            switch (user.role) {
                case UserRoles.Athlete:
                    await axios.delete(athleteURL(user.id));
                    break;
                case UserRoles.Manager:
                    await axios.delete(managerURL(user.id));
                    break;
                case UserRoles.Sponsor:
                    await axios.delete(sponsorURL(user.id));
                    break;
                case UserRoles.Fan:
                    await axios.delete(fanURL(user.id));
                    break;
                case UserRoles.Admin:
                    await axios.delete(adminURL(user.id));
                    break;
                default:
                    break;
            }

            displaySuccess({ message: t('profile.deleteSuccess') });
        } catch {
            displayError({ message: t('profile.deleteError') });
        }
    }

    /**
     * get user sportests
     */
    getSportests = async (id: React.Key): Promise<Sportest[]> => {
        try {
            const res = await axios.get(userSportestsURL(id));
            return res.data;
        } catch (error) {
            return [];
        }
    };

    /**
     * get user info
     */
    getUser = async (id: number | string): Promise<User | null> => {
        try {
            const { data } = await axios.get(userURL(id));
            return data;
        } catch {
            return null;
        }
    }

    /**
     * send email message to a specific user
     */
    sendMessage = async (payload: SendMessageRequest, onSuccess: () => void, onFailure: (args?: ApiError) => void): Promise<void> => {
        try {
            await axios.post(directMessageURL(), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err?.response?.data);
        }
    }

    searchUsers = async (filters?: QueryParams): Promise<ListResult<User>> => {
        try {
            const { data, headers } = await axios.get(usersSearchURL(filters));
            return { data, total: Number(headers[TOTAL_ITEMS] || 0) };
        } catch {
            return { data: [], total: 0 };
        }
    };
    
    searchUsersAdmin = async (filters?: QueryParams): Promise<ListResult<User>> => {
        try {
            const { data, headers } = await axios.get(adminUsersSearchURL(filters));
            return { data, total: Number(headers[TOTAL_ITEMS] || 0) };
        } catch {
            return { data: [], total: 0 };
        }
    };
    
    getUsers = async (filters?: Partial<ListParams>): Promise<ListResult<User>> => {
        try {
            const { data, headers } = await axios.get(usersURL(filters));
            return { data, total: Number(headers[TOTAL_ITEMS] || 0) };
        } catch {
            return { data: [], total: 0 };
        }
    };
    
    getUsersAdmin = async (filters?: Partial<ListParams>): Promise<ListResult<User>> => {
        try {
            const { data, headers } = await axios.get(adminUsersURL(filters));
            return { data, total: Number(headers[TOTAL_ITEMS] || 0) };
        } catch {
            return { data: [], total: 0 };
        }
    };
    
    toggleUserVisibility = async (userId: number, onSuccess: () => void, onError: (errors: KeyedObject) => void): Promise<void> => {
        try {
            await axios.put(toggleVisibilityURL(userId));
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onError(err?.response?.data);
        }
    };

    render(): React.ReactNode {
        const { children } = this.props;

        return (
            <UserContextProvider
                value={{
                    coverUpdate: this.coverUpdate,
                    avatarUpdate: this.avatarUpdate,
                    galleryUpdate: this.galleryUpdate,
                    validateProfileUpdate: this.validateProfileUpdate,
                    profileUpdate: this.profileUpdate,
                    updateUserWallet: this.updateUserWallet,
                    validateFanProfileUpdate: this.validateFanProfileUpdate,
                    fanProfileUpdate: this.fanProfileUpdate,
                    sponsorProfileUpdate: this.sponsorProfileUpdate,
                    sponsorValidateProfileUpdate: this.sponsorValidateProfileUpdate,
                    managerProfileUpdate: this.managerProfileUpdate,
                    validateManagerProfileUpdate: this.validateManagerProfileUpdate,
                    getClubsBySport: this.getClubsBySport,
                    getPaginatedClubs: this.getPaginatedClubs,
                    getAllClubs: this.getAllClubs,
                    getDropdownClubs: this.getDropdownClubs,
                    getSportests: this.getSportests,
                    addNewAchievement: this.addNewAchievement,
                    deleteAchievement: this.deleteAchievement,
                    deleteUser: this.deleteUser,
                    getUser: this.getUser,
                    getUsers: this.getUsers,
                    getUsersAdmin: this.getUsersAdmin,
                    searchUsersAdmin: this.searchUsersAdmin,
                    toggleUserVisibility: this.toggleUserVisibility,
                    sendMessage: this.sendMessage,
                    searchUsers: this.searchUsers,
                }}
            >
                {children}
            </UserContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        user: state.authentication.user,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchSetAuthentication: (payload: Partial<AuthenticationState>, onSuccess: () => void) => dispatch(setAuthentication(payload, onSuccess)),
    dispatchSetAuthenticatedUser: (payload: User) => dispatch(setAuthenticatedUserActionCreator(payload)),
});

export const ConnectedUserController = connect(mapStateToProps, mapDispatchToProps)(withTranslationContext(UserController));
