/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

const SEARCH_PARAM = 'q';

const GALLERY = 'gallery';

const PRODUCT_DESCRIPTION_MAX_LENGTH = 2000;

const BLISSCARE_PRODUCTION_ID = 349958;
const BLISSCARE_RELEASE_ID = 100052;
const BLISSCARE_TEST_ID = 153956;

const SHOW_SPONSORS_SECTION = false;

const SHOW_ATHLETE_PLANS_SECTION = false;

const TOTAL_ITEMS = 'x-total-count';

const ADD_NEW_CLUB = 'ADD_NEW_CLUB_BUTTON';

const INSTRUCTION = 'INSTRUCTION';

enum TabCode {
    ALL = 'all',
    ATHLETES = 'athletes',
    SPONSOR = 'sponsors',
}

interface KeyedObject {
    [key: string]: any;
}

interface ListResult<Entity> {
    data: Array<Entity>;
    total: number;
}

interface ListParams extends Record<string, unknown> {
    _page: number;
    _limit: number;
    _order?: Order;
    _sort?: string;
    q?: string;
}

interface ApiError {
    fields?: KeyedObject;
    errors?: KeyedObject[];
}

type DropdownOption = {
    value: any;
    label: any;
}

interface MatchParams {
    id?: string;
    name?: string;
    mediaId?: string;
    status?: string;
    q?: string;
}

interface NetworkParams {
    [SEARCH_PARAM]?: string;
}

interface Media {
    id: number;
    mediaType: MediaType;
    main: string;
    mainMimeType: string;
    thumbnail: string;
}

interface List<T> {
    list: T[];
    total: number;
}

enum Order {
    ASCENDING = 'ASC',
    DESCENDING = 'DESC',
}

enum Direction {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

enum MediaType {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}

enum Environment {
    DEV = 'development',
    TEST = 'test',
    RELEASE = 'release',
    PRODUCTION = 'production',
}

type EmptyObject = Record<string, unknown>;

export {
    Order,
    TabCode,
    Direction,
    MediaType,
    Environment,
    PRODUCT_DESCRIPTION_MAX_LENGTH,
    SEARCH_PARAM,
    GALLERY,
    BLISSCARE_PRODUCTION_ID,
    BLISSCARE_RELEASE_ID,
    BLISSCARE_TEST_ID,
    SHOW_SPONSORS_SECTION,
    SHOW_ATHLETE_PLANS_SECTION,
    TOTAL_ITEMS,
    ADD_NEW_CLUB,
    INSTRUCTION,
};

export type {
    NetworkParams,
    KeyedObject,
    ListResult,
    ListParams,
    ApiError,
    DropdownOption,
    MatchParams,
    Media,
    List,
    EmptyObject,
};
