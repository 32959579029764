/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { Key } from 'react';
import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

const api = 'sponsors';

export const sponsorsURL = (): string => {
    return `${API_URL}/${api}`;
};

export const sponsorURL = (id: Key): string => {
    return `${API_URL}/${api}/${id}`;
};

export const sponsorMediaURL = (id: number | string): string => {
    return `${API_URL}/${api}/${id}/media`;
};

export const sponsorInviteURL = (): string => {
    return `${API_URL}/${api}/finalize-invitation`;
};

export const sponsorDeleteMediaURL = (mediaId: number | string): string => {
    return `${API_URL}/${api}/media/${mediaId}`;
};

export const sponsorSupportedAthletesURL = (id: number | string): string => {
    return `${API_URL}/${api}/${id}/sponsored_athletes`;
};

export const sponsorProductsURL = (params?: Record<string, unknown>): string => {
    return `${API_URL}/sponsor/product${objectToParams(params || {})}`;
};

export const sponsorProductURL = (id: number): string => {
    return `${API_URL}/sponsor/product/${id}`;
};

export const athletesSupportsURL = (params?: Record<string, unknown>): string => {
    return `${API_URL}/support/athletes${objectToParams(params || {})}`;
};

export const supportAthleteURL = (id: number): string => {
    return `${API_URL}/support/${id}`;
};

export const supportsAthleteURL = (params?: Record<string, unknown>): string => {
    return `${API_URL}/support/supporters${objectToParams(params || {})}`;
};

export const acceptSupportURL = (supportId: number): string => {
    return `${API_URL}/support/${supportId}/accept`;
};

export const rejectSupportURL = (supportId: number): string => {
    return `${API_URL}/support/${supportId}/reject`;
};

export const cancelSupportURL = (supportId: number, params?: Record<string, unknown>): string => {
    return `${API_URL}/support/${supportId}/cancel${objectToParams(params || {})}`;
};
