/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'terms';

/**
 * Terms URL
 * @returns {string}
 */
export const termsURL = (): string => {
    return `${API_URL}/${api}`;
};
