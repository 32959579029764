/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

// Login

import axios, { AxiosError } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { SportestAction, SportestActionTypes } from './sportest_types';
import { sportestsURL } from '../services/sportests';
import { SportestCreate } from '../constants/sportest';

// Pending Sportests

export const addSportestActionCreator = (sportest: SportestCreate): SportestActionTypes => {
    return {
        type: SportestAction.ADD_SPORTEST,
        payload: sportest,
    };
};

export const clearSportestsActionCreator = (): SportestActionTypes => {
    return {
        type: SportestAction.CLEAR_SPORTESTS,
    };
};

// Create Sportest

export const newSportestRequestActionCreator = (): SportestActionTypes => {
    return {
        type: SportestAction.CREATE_SPORTEST_REQUEST,
    };
};

export const newSportestSuccessActionCreator = (): SportestActionTypes => {
    return {
        type: SportestAction.CREATE_SPORTEST_SUCCESS,
    };
};

export const newSportestFailureActionCreator = (): SportestActionTypes => {
    return {
        type: SportestAction.CREATE_SPORTEST_FAILURE,
    };
};

export const requestCreateSportest = (payload: FormData, onSuccess?: Function, onFailure?: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(newSportestRequestActionCreator());
        try {
            const { status, data } = await axios.post(sportestsURL(), payload);

            if (status === 200) {
                dispatch(newSportestSuccessActionCreator());

                if (onSuccess) onSuccess(data.score, data.id === 0);
            }
        } catch (error) {
            let formErrors = {};
            const err = error as AxiosError;
            if (err && err.response) {
                formErrors = err.response.data;
            }
            dispatch(newSportestFailureActionCreator());
            if (onFailure) onFailure(formErrors);
        }
    };
};
