/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { AppRoute } from '../../constants/routes';
import { UserRoles } from '../../types/user';

interface OwnProps {
    rolesAllowed?: UserRoles[];
}

type Props = OwnProps & RouteProps & AuthenticationContext;

const AuthenticatedRoute: React.FC<Props> = (props: Props) => {
    const {
        isAuthenticated, user, rolesAllowed, location,
    } = props;

    if (!isAuthenticated || (rolesAllowed && user?.role && !rolesAllowed.includes(user.role))) {
        const renderComponent = () => (
            <Redirect
                to={{
                    pathname: AppRoute.Index,
                    state: {
                        from: location,
                    },
                }}
            />
        );

        return <Route {...props} component={renderComponent} render={undefined} />;
    }

    return (
        <>
            <Route {...props} />
        </>
    );
};

export default withAuthenticationContext(AuthenticatedRoute);
