/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import {
    RouteComponentProps, Switch, Route, Redirect,
} from 'react-router-dom';

import HomeScreen from '../screens/HomeScreen';
import AboutScreen from '../screens/AboutScreen';
import ValidateAccountScreen from '../screens/ValidateAccountScreen';
import ProfileScreen from '../screens/ProfileScreen';
import AthleteScreen from '../screens/AthleteScreen';
import AuthenticatedRoute from '../containers/AuthenticatedRoute';
import TestScreen from '../screens/TestScreen';
import { AppRoute } from '../../constants/routes';
import { MatchParams } from '../../constants/misc';
import FanScreen from '../screens/fans/FanScreen';
import FanProfileScreen from '../screens/fans/FanProfileScreen';
import SponsorScreen from '../screens/sponsors/SponsorScreen';
import SponsorProfileScreen from '../screens/sponsors/SponsorProfileScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import { SportrackNetwork } from '../screens/SportrackNetwork';
import UsersScreen from '../screens/UsersScreen';
import ManagerProfileScreen from '../screens/managers/ManagerProfileScreen';
import ManagerScreen from '../screens/managers/ManagerScreen';
import { UserRoles } from '../../types/user';
import MarketScreen from '../screens/MarketScreen';
import ProductScreen from '../screens/ProductScreen';

type OwnProps = RouteComponentProps<MatchParams>;

type Props = OwnProps;

const ContentArea: FunctionComponent<Props> = () => {
    return (
        <div className="content-area" data-testid="content-area">
            <Switch>
                <Route exact path={AppRoute.Index} component={HomeScreen} />
                <Route exact path={AppRoute.Invite} component={HomeScreen} />
                <Route exact path={AppRoute.About} component={AboutScreen} />
                <Route exact path={AppRoute.ValidateAcount} component={ValidateAccountScreen} />
                <Route exact path={AppRoute.Product} component={ProductScreen} />
                <Route path={[AppRoute.SportNetworkSearch, AppRoute.SportNetwork]} component={SportrackNetwork} />
                <Route path={AppRoute.Credit} component={MarketScreen} />
                <Route path={[AppRoute.AthleteGallery, AppRoute.Athlete]} component={AthleteScreen} />
                <Route path={[AppRoute.FanGallery, AppRoute.Fan]} component={FanScreen} />
                <Route path={[AppRoute.SponsorGallery, AppRoute.Sponsor]} component={SponsorScreen} />
                <Route path={[AppRoute.ManagerGallery, AppRoute.Manager]} component={ManagerScreen} />
                <Route exact path={AppRoute.Sportest} component={TestScreen} />
                <Route exact path={AppRoute.ResetPassword} component={ResetPasswordScreen} />
                <AuthenticatedRoute exact path={AppRoute.AthleteProfile} component={ProfileScreen} />
                <AuthenticatedRoute exact path={AppRoute.AthleteProfileSupport} component={ProfileScreen} />
                <AuthenticatedRoute exact path={AppRoute.FanProfile} component={FanProfileScreen} />
                <AuthenticatedRoute exact path={AppRoute.SponsorProfile} component={SponsorProfileScreen} />
                <AuthenticatedRoute exact path={AppRoute.ManagerProfile} component={ManagerProfileScreen} />
                <AuthenticatedRoute exact path={AppRoute.PaymentSuccess} component={MarketScreen} />
                <AuthenticatedRoute rolesAllowed={[UserRoles.Admin]} exact path={AppRoute.AthleteProfileAdmin} component={ProfileScreen} />
                <AuthenticatedRoute rolesAllowed={[UserRoles.Admin]} exact path={AppRoute.FanProfileAdmin} component={FanProfileScreen} />
                <AuthenticatedRoute rolesAllowed={[UserRoles.Admin]} exact path={AppRoute.SponsorProfileAdmin} component={SponsorProfileScreen} />
                <AuthenticatedRoute rolesAllowed={[UserRoles.Admin]} exact path={AppRoute.ManagerProfileAdmin} component={ManagerProfileScreen} />
                <AuthenticatedRoute rolesAllowed={[UserRoles.Admin]} exact path={AppRoute.Admin} component={UsersScreen} />

                <Redirect to={AppRoute.NotFound} />
            </Switch>
        </div>
    );
};

export default ContentArea;
