/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { withTranslationContext, TranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps {
    name: string;
    value: unknown;
    label: string;
    status?: string;
    onChange?: (name: string, value: boolean) => void;
}

type Props = OwnProps & TranslationContext;

const Switch: FunctionComponent<Props> = (props: Props) => {
    const {
        onChange, name, value, label, status, t,
    } = props;

    const toggleValue = (): void => {
        if (onChange) onChange(name, !value);
    };

    const getStatus = (): string => {
        if (status) {
            return t(`${status}.${Boolean(value)}`);
        }
        return '';
    };

    return (
        <div className="switch" data-testid="switch">
            <div className="switch__toggle">
                <input id={name} name={name} checked={Boolean(value)} onChange={toggleValue} type="checkbox" hidden />
                <span className="switch__toggle__slider" onClick={toggleValue} />
            </div>
            <div className="switch__label">
                <label htmlFor={name}>{label}</label>
                <span>{getStatus()}</span>
            </div>
        </div>
    );
};

export default withTranslationContext(Switch);
