/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 */
interface OwnProps {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
const IconPrevious: FunctionComponent<Props> = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="10" viewBox="0 0 5 10">
            <path d="M.354 5.354a.5.5 0 010-.707L4.146.854A.5.5 0 015 1.207v7.586a.5.5 0 01-.854.354z" fill="#757575" />
        </svg>
    );
};

export default IconPrevious;
