/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

export const IconGlobe: FunctionComponent<Props> = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm6.355-6.048v-.1c0-.922,0-1.343-.652-1.716q-.314-.178-.645-.325a1.836,1.836,0,0,1-.938-.756q-.059-.085-.116-.172a1.28,1.28,0,0,0-1.542-.753c-1.865.3-2,.624-2.085,1.178l-.013.091c-.121.81-.143,1.082.195,1.437a12.3,12.3,0,0,1,2.253,2.844,3.334,3.334,0,0,1,.2,1.918,8.185,8.185,0,0,0,3.151-2.237,4.959,4.959,0,0,0,.19-1.4ZM12,3.833A8.145,8.145,0,0,0,6.1,6.349a1.263,1.263,0,0,1,.437.534,3.184,3.184,0,0,1,.2,1.345,2.179,2.179,0,0,0,.105.865c.144.308.766.44,1.315.554.2.042.4.084.583.135a2.743,2.743,0,0,1,1.211.96,2.788,2.788,0,0,0,.42.43,1.017,1.017,0,0,0,.29-.5.586.586,0,0,0-.045-.52,2.922,2.922,0,0,1-.356-2.4c.272-.739,1.122-.684,1.744-.644a3.573,3.573,0,0,0,.614.009c.622-.078.814-1.025.949-1.21a8.889,8.889,0,0,1,1.74-1.375A8.138,8.138,0,0,0,12,3.833Z" transform="translate(-2 -2)" fill="#278982"/>
        </svg>
    );
};
