/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from '../constants/misc';

export interface FormValidator {
    validations?: string[];
    regex?: any;
    length?: FormValidatorLength;
    min?: number;
    max?: number;
}

export interface FormValidatorLength {
    lowerLimit: number;
    upperLimit: number;
}

export interface FormValidatorError {
    errorCode?: number;
    typeOfViolation?: string;
    size?: number;
    min?: number;
    max?: number;
}

export enum FormValidatorErrorType {
    NotBlank = 'NotBlank',
    NotEmpty = 'NotEmpty',
    SizeExact = 'SizeExact',
    Size = 'Size',
    Max = 'Max',
    Min = 'Min',
    Pattern = 'Pattern',
    PasswordsDontMatch = 'PasswordsDontMatch',
    PasswordStrength = 'PasswordStrength',
    Number = 'Number',
    FileSize = 'FileSize',
    MaxDigits = 'MaxDigits',
}

export enum ValidationType {
    NotBlank = 'NOT_BLANK',
    NotEmpty = 'NOT_EMPTY',
    Length = 'LENGTH',
    Max = 'MAX',
    Regex = 'REGEX',
    Number = 'NUMBER',
    FileSize = 'FILE_SIZE',
    MaxDigits = 'MAX_DIGITS',
    PasswordStrength = 'PASSWORD_STRENGTH',
}

export const REGEX = {
    FLOAT: /^-?\d*\.?\d*$/,
    PHONE_NUMBER: /9[1236]\d{7}|2\d{8}/,
    POSTAL_CODE: /^\s*\d{4}-\d{3}\s*$/,
    PASSWORD_STRENGTH: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,100}$/,
    TIN: /^[A-Z0-9]+$/,
    EMAIL: /^[_A-Za-z0-9]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/,
};

export const validateField = (fieldName: string, fieldValue: any, validator: FormValidator) => {
    let errors: FormValidatorError[] | null = null;
    let isFilled = true;

    if (!validator) return errors;

    const {
        validations,
        length,
        regex,
        max,
    } = validator;

    if (!validations) return errors;

    if (validations.includes(ValidationType.NotBlank)) {
        if (
            fieldValue === null
            || fieldValue === undefined
            || fieldValue.toString().trim() === ''
        ) {
            errors = [{ typeOfViolation: FormValidatorErrorType.NotBlank }];
            isFilled = false;
        }
    } else if (validations.includes(ValidationType.NotEmpty)) {
        if (Array.isArray(fieldValue) && fieldValue.length === 0) {
            errors = [{ typeOfViolation: FormValidatorErrorType.NotEmpty }];
            isFilled = false;
        }
    }

    if (isFilled) {
        if (validations.includes(ValidationType.Length)) {
            if (length) {
                const { lowerLimit, upperLimit } = length;
                if (fieldValue) {
                    const parsedValue = String(fieldValue);
                    if (lowerLimit === upperLimit) {
                        const exactLimit = lowerLimit;
                        if (parsedValue.length !== exactLimit) {
                            errors = [
                                { typeOfViolation: FormValidatorErrorType.SizeExact, size: exactLimit },
                            ];
                        }
                    } else {
                        if (
                            parsedValue.length < lowerLimit
                            || parsedValue.length > upperLimit
                        ) {
                            errors = [
                                {
                                    typeOfViolation: FormValidatorErrorType.Size,
                                    min: lowerLimit,
                                    max: upperLimit,
                                },
                            ];
                        }
                    }
                }
            }
        }

        if (validations.includes(ValidationType.Max)) {
            if (max) {
                if (fieldValue && fieldValue > max) {
                    errors = [
                        {
                            typeOfViolation: FormValidatorErrorType.Max,
                            max,
                        },
                    ];
                }
            }
        }

        if (validations.includes(ValidationType.Regex)) {
            if (regex) {
                if (!regex.test(fieldValue)) {
                    errors = [{ typeOfViolation: FormValidatorErrorType.Pattern }];
                }
            }
        }

        if (validations.includes(ValidationType.Number)) {
            if (isNaN(fieldValue)) {
                errors = [
                    {
                        typeOfViolation: FormValidatorErrorType.Number,
                    },
                ];
            }
        }

        if (validations.includes(ValidationType.FileSize)) {
            if (fieldValue && typeof fieldValue === 'object') {
                const fileSize = fieldValue.size;

                if (max && Number(fileSize) > max * Math.pow(10, 6)) {
                    errors = [{
                        typeOfViolation: FormValidatorErrorType.FileSize,
                        max,
                    }];
                }
            }
        }

        if (validations.includes(ValidationType.MaxDigits)) {
            if (fieldValue) {
                if (max && String(fieldValue).length > max) {
                    errors = [{
                        typeOfViolation: FormValidatorErrorType.MaxDigits,
                        max,
                    }];
                }
            }
        }

        if (validations.includes(ValidationType.PasswordStrength)) {
            const passwordStrengthRegex = REGEX.PASSWORD_STRENGTH;
            if (!passwordStrengthRegex.test(fieldValue)) {
                errors = [
                    {
                        typeOfViolation: FormValidatorErrorType.PasswordStrength,
                    },
                ];
            }
        }
    }

    return errors;
};

export const validateForm = (data: KeyedObject, validators: KeyedObject): KeyedObject | null => {
    let errors: KeyedObject | null = {};

    Object.keys(validators).forEach((field) => {
        const { [field]: fieldValue } = data;

        if (errors) {
            if (validators[field]) {
                errors[field] = validateField(
                    field,
                    fieldValue,
                    validators[field],
                );
                if (!errors[field]) delete errors[field];
            }
        }
    });

    if (Object.keys(errors).length === 0) errors = null;
    return errors;
};

export const hasAnyErrors = (errors: FormValidatorError[] | null | undefined): boolean => {
    return (errors !== null && errors !== undefined && errors.length > 0);
};

export const getErrorsForField = (field: string, errors: any): FormValidatorError[] => {
    if (errors !== null && errors !== undefined && Object.keys(errors).length > 0) {
        if (hasAnyErrors(errors[field])) {
            return errors[field];
        }
    }

    return [];
};
