/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import Select from 'react-select';

import InputError from './FormFieldError';
import IconDropdown from '../assets/IconDropdown';
import { KeyedObject, DropdownOption } from '../../constants/misc';
import { getErrorsForField, hasAnyErrors } from '../../utils/validations';

/**
 * @typedef {Object} OwnProps
 * @property {string} [label]
 * @property {string} [placeholder]
 * @property {string} name
 * @property {string} [tabIndex]
 * @property {string} [disabled]
 * @property {DropdownOption[]} options
 * @property {DropdownOption | null} value
 * @property {Function} onChange
 * @property {KeyedObject} [errors]
 */
interface OwnProps {
    label?: string;
    placeholder?: string;
    name: string;
    tabIndex?: string;
    disabled?: boolean;
    options: DropdownOption[];
    customFilter?: (option: DropdownOption, rawInput: string) => boolean;
    value: DropdownOption | null;
    onChange: Function;
    onInputChange?: Function;
    errors?: KeyedObject | null;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * Component that shows a dropdown list with options
 * @param {Props} props
 * @returns {FunctionComponent<Props>}
 */
const Dropdown: FunctionComponent<Props> = ({
    tabIndex,
    options,
    value,
    onChange,
    name,
    errors,
    disabled,
    label = '',
    placeholder = '',
    onInputChange,
    customFilter,
}: Props) => {
    const fieldErrors = getErrorsForField(name, errors);
    const hasErrors = hasAnyErrors(fieldErrors);
    
    return (
        <div className="form-field">
            <div className={`input-container ${hasErrors ? 'has-error' : ''}`}>
                <Select
                    tabIndex={tabIndex}
                    components={{
                        DropdownIndicator: () => (
                            <div className={`react-select__dropdown-indicator ${disabled ? ' react-select__dropdown-indicator--hidden' : ''}`}>
                                <IconDropdown />
                            </div>
                        ),
                    }}
                    onInputChange={onInputChange ? (newValue) => onInputChange(newValue) : () => {}}
                    placeholder={placeholder}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={options}
                    value={value}
                    onChange={(option) => onChange(option, name)}
                    isDisabled={disabled}
                    name={name}
                    filterOption={customFilter || undefined}
                />
            </div>
            {label.length > 0 && <label htmlFor={name}>{label}</label>}
            {hasErrors && <InputError errors={fieldErrors} field={name} />}
        </div>
    );
};

export default Dropdown;
