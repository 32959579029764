/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/logo_white.svg';
import runningImage from '../../assets/images/running.png';
import modalSlant from '../../assets/images/modal_slant.svg';
import FormField from '../elements/FormField';
import { LoginFormFields, RecoverPasswordFormFields } from '../../constants/authentication';
import { KeyedObject, MatchParams } from '../../constants/misc';
import { displayError, displaySuccess } from '../../utils/notifications';
import { ErrorCode } from '../../constants/errors';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/authorization/AuthorizationContext';

interface OwnProps extends TranslationContext, RouteComponentProps<MatchParams>, AuthorizationContext {
    isFetching: boolean;
    close: Function;
    submit: Function;
    validate: Function;
}

type Props = OwnProps;

interface OwnState {
    fields: LoginFormFields;
    errors: KeyedObject | null;
}

const initialState: OwnState = {
    fields: {
        email: '',
        password: '',
    },
    errors: null,
};

type State = OwnState;

class ResetPasswordScreen extends Component<Props, State> {
    state = initialState;

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { submit, validate } = this.props;
        const { fields } = this.state;

        const errors = validate(fields);

        this.setState(
            {
                ...this.state,
                errors,
            },
            () => {
                if (!errors) {
                    const formData: RecoverPasswordFormFields = {
                        email: fields.email,
                    };

                    submit(formData, this.onSuccess, this.onFailure);
                }
            },
        );
    };

    onSuccess = () => {
        const { close, t } = this.props;

        this.setState(
            {
                ...this.state,
                fields: initialState.fields,
                errors: null,
            },
            () => {
                close();
                displaySuccess({
                    message: t('recover.message'),
                });
            },
        );
    };

    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors.fields) {
            this.setState({
                ...this.state,
                errors: errors.fields,
            });
        }

        if (errors.errors) {
            errors.errors.forEach((error: any) => {
                displayError({
                    message: t(`errors.${ErrorCode[error.errorCode]}`),
                });
            });
        }
    };

    render() {
        const { close, isFetching, t } = this.props;
        const { fields, errors } = this.state;

        return (
            <div className="modal registration-screen" data-testid="recover-password-screen">
                <div className="modal__backdrop" />
                <div className="modal__content">
                    <button type="button" className="modal__content__close" onClick={() => close()} aria-label={t('general.close')}>
                        <IconTimes />
                    </button>
                    <div className="modal__content__top">
                        <div className="modal__content__top__header">
                            <img src={logo} className="logo" alt="" />
                            <img src={runningImage} className="running" alt="" />
                            <img src={modalSlant} className="slant" alt="" />
                        </div>
                        <div className="modal__content__top__subheader">
                            <h1>{t('recover.title')}</h1>
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-xs-10 offset-xs-1">
                                <FormField
                                    label={t('recover.email')}
                                    type="text"
                                    name="email"
                                    value={fields.email}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                    disabled={isFetching}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-xs-10 offset-xs-1">
                                <button
                                    type="submit"
                                    className="btn btn--primary-inverse btn--block"
                                    disabled={isFetching}
                                >
                                    {t('recover.submit')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withAuthorizationContext(withTranslationContext(withRouter(ResetPasswordScreen)));
