/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import FormField from './FormField';
import { InvoiceInfo } from '../../types/invoice';
import { DropdownOption, KeyedObject } from '../../constants/misc';
import { CountryCode, CountryType } from '../../types/country';
import Dropdown from './Dropdown';

interface OwnProps extends TranslationContext {
    invoice?: InvoiceInfo;
    onChangeDropdown: (field: keyof InvoiceInfo) => (selectedOption: DropdownOption | null) => void;
    onChangeInput: (field: keyof InvoiceInfo) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    inputErrors: KeyedObject;
}

const InvoiceForm: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, onChangeDropdown, onChangeInput, invoice, inputErrors,
    } = props;

    const countryOptions: DropdownOption[] = Object.values(CountryCode).map((code) => ({
        value: code,
        label: t(`countries.${code}`),
    }));

    const mapCountryToDropdownOption = (country?: CountryType): DropdownOption | null => {
        return country ? { value: country.code, label: country.name } : null;
    };

    return (
        <React.Fragment>
            <div className="payment-modal__content__body__form">
                <FormField
                    label={t('invoice.name')}
                    name="name"
                    value={invoice?.name}
                    placeholder=""
                    onChange={onChangeInput('name')}
                    errors={inputErrors}
                />
            </div>
            <div className="payment-modal__content__body__form">
                <FormField
                    label={t('invoice.email')}
                    name="email"
                    value={invoice?.email}
                    placeholder=""
                    onChange={onChangeInput('email')}
                    errors={inputErrors}
                />
            </div>
            <div className="payment-modal__content__body__form">
                <FormField
                    label={t('invoice.tin')}
                    name="tin"
                    value={invoice?.tin}
                    placeholder=""
                    onChange={onChangeInput('tin')}
                    errors={inputErrors}
                />
            </div>
            <div className="payment-modal__content__body__form">
                <Dropdown
                    label={t('invoice.country')}
                    name="country"
                    value={mapCountryToDropdownOption(invoice?.country)}
                    placeholder=""
                    onChange={(selectedOption: DropdownOption | null) => onChangeDropdown('country')(selectedOption)}
                    options={countryOptions}
                    errors={inputErrors}
                />
            </div>
            <div className="payment-modal__content__body__form">
                <FormField
                    label={t('invoice.city')}
                    name="city"
                    value={invoice?.city}
                    placeholder=""
                    onChange={onChangeInput('city')}
                    errors={inputErrors}
                />
            </div>
            <div className="payment-modal__content__body__form">
                <FormField
                    label={t('invoice.address')}
                    name="address"
                    value={invoice?.address}
                    placeholder=""
                    onChange={onChangeInput('address')}
                    errors={inputErrors}
                />
            </div>
            <div className="payment-modal__content__body__form">
                <FormField
                    label={t('invoice.postalCode')}
                    name="postalCode"
                    value={invoice?.postalCode}
                    placeholder=""
                    onChange={onChangeInput('postalCode')}
                    errors={inputErrors}
                />
            </div>
        </React.Fragment>
        
    );
};

export default withTranslationContext(InvoiceForm);
