/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

export const IconBank: FunctionComponent<Props> = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path d="M2,20H22v2H2Zm2-8H6v7H4Zm5,0h2v7H9Zm4,0h2v7H13Zm5,0h2v7H18ZM2,7,12,2,22,7v4H2ZM4,8.236V9H20V8.236l-8-4ZM12,8a1,1,0,1,1,1-1A1,1,0,0,1,12,8Z" transform="translate(-2 -2)" fill="#278982"/>
        </svg>      
    );
};
