/**
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconMBWay: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143.2 69.57">
            <path
                fill="red"
                d="M7.07,61.84l-.24,1.88a1.54,1.54,0,0,0,1.35,1.72H69.29a1.56,1.56,0,0,0,1.58-1.54,1.15,1.15,0,0,0,0-.19l-.25-1.88A2.68,2.68,0,0,1,73,58.9a2.64,2.64,0,0,1,2.91,2.34v0l.24,1.83c.47,4.07-1.84,7.65-6,7.65H7.51c-4.12,0-6.42-3.58-5.95-7.65l.24-1.83A2.62,2.62,0,0,1,4.68,58.9h0a2.69,2.69,0,0,1,2.38,2.94"
                transform="translate(-1.5 -1.16)"
            />
            <path
                fill="#1d1d1"
                d="M63.37,47.71A5,5,0,0,0,68.63,43a2.35,2.35,0,0,0,0-.26c-.06-2.91-2.71-4.79-5.66-4.8H57a2.48,2.48,0,0,1,0-5h4c2.69-.11,4.76-1.74,4.89-4.27.13-2.73-2.21-4.77-5.06-4.77H51.15l0,23.77H63.37m7.33-19a7.84,7.84,0,0,1-2.33,5.61l-.15.17.2.12a9.74,9.74,0,0,1,5,8.14,10,10,0,0,1-9.8,10.13h-15a2.63,2.63,0,0,1-2.59-2.65h0V21.66A2.62,2.62,0,0,1,48.68,19h0l12.15,0a9.61,9.61,0,0,1,9.87,9.33v.33"
                transform="translate(-1.5 -1.16)"
            />
            <path
                fill="#1d1d1"
                d="M23.26,43.08l.07.2.07-.2c.68-1.88,1.51-4,2.38-6.23s1.8-4.67,2.69-6.85,1.76-4.18,2.58-5.9a19.91,19.91,0,0,1,2-3.61A4,4,0,0,1,36.26,19h.61a2.91,2.91,0,0,1,1.92.62A2.15,2.15,0,0,1,39.55,21l3.81,29.5a2.47,2.47,0,0,1-.65,1.79,2.6,2.6,0,0,1-1.85.6,3,3,0,0,1-1.92-.56,2.07,2.07,0,0,1-.89-1.48c-.13-1-.24-2.07-.36-3.27s-.76-6.33-.93-7.64-1.22-9.66-1.59-12.69l0-.26-1.22,2.56c-.41.88-.86,1.93-1.35,3.16s-1,2.53-1.47,3.91-2.89,8.06-2.89,8.06c-.22.61-.64,1.84-1,3s-.73,2.15-.82,2.34a3.42,3.42,0,0,1-4.6,1.49A3.46,3.46,0,0,1,20.29,50c-.1-.19-.44-1.21-.83-2.34s-.77-2.35-1-3c0,0-2.35-6.74-2.88-8.06s-1-2.67-1.47-3.91-.95-2.28-1.35-3.16L11.53,27l0,.26c-.37,3-1.43,11.36-1.6,12.69S9.14,46.36,9,47.55s-.25,2.29-.37,3.27a2.07,2.07,0,0,1-.89,1.48,3,3,0,0,1-1.91.56A2.57,2.57,0,0,1,4,52.26a2.47,2.47,0,0,1-.65-1.79L7.11,21a2.16,2.16,0,0,1,.77-1.32A2.88,2.88,0,0,1,9.8,19h.61a4,4,0,0,1,3.19,1.46,19.33,19.33,0,0,1,2,3.61q1.23,2.58,2.58,5.9t2.7,6.85c.87,2.26,1.69,4.35,2.37,6.23"
                transform="translate(-1.5 -1.16)"
            />
            <path
                fill="red"
                d="M15.8,1.16H62.06c4.36,0,6.53,3.27,7,7.59l.2,1.38a2.72,2.72,0,0,1-2.39,3A2.67,2.67,0,0,1,64,10.71v0L63.8,9.38c-.19-1.64-.88-2.91-2.55-2.91H16.62c-1.67,0-2.36,1.27-2.56,2.91l-.18,1.31A2.66,2.66,0,0,1,11,13.1h0a2.71,2.71,0,0,1-2.39-3l.19-1.38c.52-4.31,2.68-7.59,7-7.59"
                transform="translate(-1.5 -1.16)"
            />
            <path
                fill="#1d1d1"
                d="M99,32.26c-.32,1.23-.65,2.55-1,4s-.7,2.75-1,4-.65,2.39-1,3.36a10.89,10.89,0,0,1-.76,2,2,2,0,0,1-1.89.94,4.09,4.09,0,0,1-1-.15,1.63,1.63,0,0,1-1-.86,12.06,12.06,0,0,1-.76-2.08c-.3-1-.62-2.22-1-3.57s-.67-2.77-1-4.28-.65-2.91-.91-4.2-.5-2.4-.68-3.3-.28-1.45-.31-1.64a1.6,1.6,0,0,1,0-.23v-.13a1.13,1.13,0,0,1,.44-.93,1.63,1.63,0,0,1,1.08-.35,1.76,1.76,0,0,1,1,.26,1.39,1.39,0,0,1,.54.89s.06.37.18,1,.29,1.38.48,2.31.41,2,.64,3.17.48,2.36.75,3.56.52,2.35.78,3.48.49,2.09.72,2.9c.22-.76.47-1.63.74-2.61s.55-2,.82-3,.52-2.09.77-3.13.48-2,.7-2.92.39-1.69.55-2.39.28-1.21.37-1.55a1.9,1.9,0,0,1,.64-1A1.78,1.78,0,0,1,99,25.35a1.84,1.84,0,0,1,1.22.39,1.71,1.71,0,0,1,.6,1c.27,1.09.53,2.33.82,3.69s.6,2.73.91,4.12.65,2.76,1,4.1.67,2.52,1,3.55c.22-.81.47-1.77.73-2.89s.51-2.28.78-3.48.54-2.36.78-3.53.48-2.22.68-3.15.37-1.69.48-2.27.19-.9.19-.92a1.49,1.49,0,0,1,.54-.88,1.72,1.72,0,0,1,1-.26,1.69,1.69,0,0,1,1.09.35,1.16,1.16,0,0,1,.44.93v.13a2,2,0,0,1,0,.24c0,.18-.13.72-.32,1.64s-.42,2-.69,3.29-.58,2.69-.91,4.18-.68,2.91-1,4.26-.64,2.54-1,3.56a11.57,11.57,0,0,1-.76,2.06,1.77,1.77,0,0,1-1,.9,3.45,3.45,0,0,1-1,.18,2.83,2.83,0,0,1-.41,0,3.75,3.75,0,0,1-.58-.13,2.31,2.31,0,0,1-.6-.32,1.49,1.49,0,0,1-.48-.6,15.11,15.11,0,0,1-.72-2.12c-.29-1-.59-2.1-.92-3.34s-.64-2.56-1-3.92-.61-2.63-.88-3.81"
                transform="translate(-1.5 -1.16)"
            />
            <path
                fill="#1d1d1"
                d="M116.69,40.3c-.34,1.08-.64,2.08-.89,3s-.51,1.67-.73,2.26a1.51,1.51,0,0,1-3-.4,1.31,1.31,0,0,1,.07-.44l.42-1.39c.24-.78.55-1.75.93-2.93s.81-2.44,1.27-3.83.94-2.77,1.43-4.13,1-2.63,1.46-3.8A23.07,23.07,0,0,1,119,25.78a1.56,1.56,0,0,1,.73-.77,3.11,3.11,0,0,1,1.24-.2,3.25,3.25,0,0,1,1.27.23,1.4,1.4,0,0,1,.72.81c.32.67.7,1.58,1.13,2.71s.91,2.36,1.39,3.68,1,2.66,1.44,4,.91,2.64,1.3,3.82.73,2.19,1,3,.46,1.37.52,1.62a1.31,1.31,0,0,1,.07.44,1.26,1.26,0,0,1-.41,1,1.56,1.56,0,0,1-1.17.39,1.24,1.24,0,0,1-.87-.25,1.66,1.66,0,0,1-.45-.72c-.23-.59-.49-1.34-.8-2.26s-.63-1.92-1-3h-8.45m7.5-2.93c-.48-1.46-.92-2.8-1.35-4S122,31,121.52,29.86c-.11-.25-.23-.53-.35-.87s-.2-.51-.22-.57a2.55,2.55,0,0,0-.22.54c-.13.36-.24.65-.36.9-.45,1.1-.88,2.26-1.3,3.49s-.86,2.56-1.33,4Z"
                transform="translate(-1.5 -1.16)"
            />
            <path
                fill="#1d1d1"
                d="M135.65,38.05a2.92,2.92,0,0,1-.32-.38l-.33-.46c-.32-.45-.65-1-1-1.64s-.75-1.32-1.12-2-.73-1.45-1.07-2.18-.68-1.41-.95-2-.53-1.18-.73-1.64a6.56,6.56,0,0,1-.37-1,1.34,1.34,0,0,1-.09-.26s0-.13,0-.25a1.38,1.38,0,0,1,.42-1,1.58,1.58,0,0,1,1.17-.41,1.24,1.24,0,0,1,1,.34,2.2,2.2,0,0,1,.41.67l.33.74c.17.38.38.85.62,1.41s.53,1.18.85,1.86.63,1.33,1,2l.95,1.87a14.31,14.31,0,0,0,.86,1.46,24.85,24.85,0,0,0,1.39-2.47c.49-1,1-1.95,1.41-2.92s.84-1.82,1.18-2.55l.59-1.39a2.23,2.23,0,0,1,.42-.67,1.16,1.16,0,0,1,1-.34,1.56,1.56,0,0,1,1.17.41,1.31,1.31,0,0,1,.42,1,1,1,0,0,1,0,.25l-.08.26-.39,1c-.19.47-.43,1-.72,1.64s-.59,1.31-.93,2-.72,1.45-1.09,2.18-.74,1.4-1.11,2-.72,1.21-1,1.65a5.38,5.38,0,0,1-.65.78v7a1.49,1.49,0,0,1-.42,1.11,1.53,1.53,0,0,1-2.15,0,1.55,1.55,0,0,1-.47-1.15v-7"
                transform="translate(-1.5 -1.16)"
            />
        </svg>
    );
};

export default IconMBWay;
