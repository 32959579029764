/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType, createContext } from 'react';
import { ConnectedPaginationController } from './PaginationController';
import { List } from '../../../constants/misc';

export interface PaginationContext<T> {
    paginate: (promise: (params?: Record<string, string | number>) => Promise<List<T>>, limit?: number) => Promise<T[]>;
    nextPage: () => Promise<void>;
    previousPage: () => Promise<void>;
    goToPage: (page: number) => Promise<void>;
    list: T[];
    totalPages: number;
    currentPage: number;
}
   
// Create the context
export const paginationContextDefaultValue = {
    paginate: async <T extends unknown>(): Promise<T[]> => [],
    nextPage: async (): Promise<void> => {},
    previousPage: async (): Promise<void> => {},
    goToPage: async (): Promise<void> => {},
    totalPages: 0,
    currentPage: 0,
    list: [],
};
   
const PaginationContext = createContext<PaginationContext<any> | null>(paginationContextDefaultValue);
   
// Export Provider and Consumer
export const PaginationContextProvider = PaginationContext.Provider;
export const PaginationContextConsumer = PaginationContext.Consumer;

export const withPaginationContext = <T, P extends Record<symbol, unknown>>(
    Component: ComponentType<P>,
): React.FC<Omit<P, keyof PaginationContext<T>>> => (props) => (
    <ConnectedPaginationController>
        <PaginationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </PaginationContextConsumer>
    </ConnectedPaginationController>
    );
