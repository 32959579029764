/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import IconTimes from '../assets/IconTimes';

interface OwnProps {
    children?: JSX.Element | string;
    onClose: () => void;
    image?: string;
    title?: string;
    secondaryTitle?: string;
}

type Props = OwnProps;

const Modal: FunctionComponent<Props> = ({
    children,
    onClose,
    title,
    secondaryTitle,
    image,
}: Props) => {
    return (
        <div className="modal" data-testid="modal">
            <div className="modal__backdrop" />
            <div className="modal--secondary modal__content">
                <div className="modal--secondary__header">
                    <div className="modal--secondary__header__title">
                        {image && (<img src={image} alt="logo" />)}
                        {title && (<h3>{title}</h3>)}
                    </div>
                    <button type="button" onClick={onClose} aria-label="close modal button">
                        <IconTimes fill="#278982" />
                    </button>
                </div>
                {secondaryTitle && (
                    <div className="modal--secondary__title">
                        <span>{secondaryTitle}</span>
                        <i className="title title--alternative" />
                    </div>
                )}
                <div className="modal--secondary__body">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
