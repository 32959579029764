/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'files';

/**
 * Avatar URL
 * @param {any} id
 * @returns {string}
 */
export const fileURL = (id: any): string => {
    return `${API_URL}/${api}/${id}`;
};

/**
 * Avatar URL
 * @returns {string}
 */
export const avatarURL = (): string => {
    return `${API_URL}/${api}/avatar`;
};

/**
 * Cover URL
 * @returns {string}
 */
export const coverURL = (): string => {
    return `${API_URL}/${api}/cover`;
};

/**
 * Gallery Media URL
 * @returns {string}
 */
export const galleryURL = (): string => {
    return `${API_URL}/${api}/gallery-media`;
};
