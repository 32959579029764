/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';

interface Props {
    children: (isScrolling: boolean) => React.ReactNode;
}

type OwnProps = Props;

const ScrollWrapper: FunctionComponent<OwnProps> = ({ children }) => {
    const [isScrolling, setIsScrolling] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 185) {
            setIsScrolling(true);
        } else {
            setIsScrolling(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array to add the effect only once

    return <>{children(isScrolling)}</>;
};

export default ScrollWrapper;
