/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { SportestCreate } from '../constants/sportest';

export enum SportestAction {
    ADD_SPORTEST = 'ADD_SPORTEST',
    CLEAR_SPORTESTS = 'CLEAR_SPORTESTS',
    CREATE_SPORTEST_REQUEST = 'CREATE_SPORTEST_REQUEST',
    CREATE_SPORTEST_SUCCESS = 'CREATE_SPORTEST_SUCCESS',
    CREATE_SPORTEST_FAILURE = 'CREATE_SPORTEST_FAILURE',
}

// Pending Sportests

export interface AddSportestActionType {
    type: typeof SportestAction.ADD_SPORTEST;
    payload: SportestCreate;
}

export interface ClearSportestsActionType {
    type: typeof SportestAction.CLEAR_SPORTESTS;
}

// Create Sportest

export interface NewSportestRequestActionType {
    type: typeof SportestAction.CREATE_SPORTEST_REQUEST;
}

export interface NewSportestSuccessActionType {
    type: typeof SportestAction.CREATE_SPORTEST_SUCCESS;
}

export interface NewSportestFailureActionType {
    type: typeof SportestAction.CREATE_SPORTEST_FAILURE;
}

export type SportestActionTypes =
    | AddSportestActionType
    | ClearSportestsActionType
    | NewSportestRequestActionType
    | NewSportestSuccessActionType
    | NewSportestFailureActionType;
