/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { User } from '../types/user';

/**
 * AuthenticationAction
 * @enum {string}
 */
export enum AuthenticationAction {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    LOGOUT_REQUEST = 'LOGOUT_REQUEST',
    SET_AUTHENTICATION = 'SET_AUTHENTICATION',
    SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER',
    RESET_AUTHENTICATION = 'RESET_AUTHENTICATION',
    REGISTRATION_REQUEST = 'REGISTRATION_REQUEST',
    REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
    REGISTRATION_FAILURE = 'REGISTRATION_FAILURE',
    ACCOUNT_VALIDATION_REQUEST = 'ACCOUNT_VALIDATION_REQUEST',
    ACCOUNT_VALIDATION_SUCCESS = 'ACCOUNT_VALIDATION_SUCCESS',
    ACCOUNT_VALIDATION_FAILURE = 'ACCOUNT_VALIDATION_FAILURE',
    RESEND_ACTIVATION_REQUEST = 'RESEND_ACTIVATION_REQUEST',
    RESEND_ACTIVATION_SUCCESS = 'RESEND_ACTIVATION_SUCCESS',
    RESEND_ACTIVATION_FAILURE = 'RESEND_ACTIVATION_FAILURE',
}

/**
 * @typedef {Object} LoginRequestAction
 * @property {string} type
 */
export interface LoginRequestAction {
    type: typeof AuthenticationAction.LOGIN_REQUEST;
}

/**
 * @typedef {Object} LoginSuccessAction
 * @property {string} type
 * @property {object} payload
 */
export interface LoginSuccessAction {
    type: typeof AuthenticationAction.LOGIN_SUCCESS;
    payload: object;
}

/**
 * @typedef {Object} LoginFailureAction
 * @property {string} type
 */
export interface LoginFailureAction {
    type: typeof AuthenticationAction.LOGIN_FAILURE;
}

/**
 * @typedef {Object} LogoutFailureAction
 * @property {string} type
 */
export interface LogoutFailureAction {
    type: typeof AuthenticationAction.LOGOUT_REQUEST;
}

/**
 * @typedef {Object} SetAuthenticationAction
 * @property {string} type
 * @property {object} payload
 */
export interface SetAuthenticationAction {
    type: typeof AuthenticationAction.SET_AUTHENTICATION;
    payload: object;
}

export interface SetAuthenticatedUserAction {
    type: typeof AuthenticationAction.SET_AUTHENTICATED_USER;
    payload: User;
}

/**
 * @typedef {Object} ResetAuthenticationAction
 * @property {string} type
 */
export interface ResetAuthenticationAction {
    type: typeof AuthenticationAction.RESET_AUTHENTICATION;
}

/**
 * @typedef {Object} RegistrationRequestAction
 * @property {string} type
 */
export interface RegistrationRequestAction {
    type: typeof AuthenticationAction.REGISTRATION_REQUEST;
}

/**
 * @typedef {Object} RegistrationSuccessAction
 * @property {string} type
 * @property {object} payload
 */
export interface RegistrationSuccessAction {
    type: typeof AuthenticationAction.REGISTRATION_SUCCESS;
    payload: object;
}

/**
 * @typedef {Object} RegistrationFailureAction
 * @property {string} type
 */
export interface RegistrationFailureAction {
    type: typeof AuthenticationAction.REGISTRATION_FAILURE;
}

/**
 * @typedef {Object} AccountValidationRequestAction
 * @property {string} type
 */
export interface AccountValidationRequestAction {
    type: typeof AuthenticationAction.ACCOUNT_VALIDATION_REQUEST;
}

/**
 * @typedef {Object} AccountValidationSuccessAction
 * @property {string} type
 * @property {object} payload
 */
export interface AccountValidationSuccessAction {
    type: typeof AuthenticationAction.ACCOUNT_VALIDATION_SUCCESS;
}

/**
 * @typedef {Object} AccountValidationFailureAction
 * @property {string} type
 */
export interface AccountValidationFailureAction {
    type: typeof AuthenticationAction.ACCOUNT_VALIDATION_FAILURE;
}

/**
 * @typedef {Object} ResendActivationRequestAction
 * @property {string} type
 */
export interface ResendActivationRequestAction {
    type: typeof AuthenticationAction.RESEND_ACTIVATION_REQUEST;
}

/**
 * @typedef {Object} ResendActivationSuccessAction
 * @property {string} type
 */
export interface ResendActivationSuccessAction {
    type: typeof AuthenticationAction.RESEND_ACTIVATION_SUCCESS;
}

/**
 * @typedef {Object} ResendActivationFailureAction
 * @property {string} type
 */
export interface ResendActivationFailureAction {
    type: typeof AuthenticationAction.RESEND_ACTIVATION_FAILURE;
}

export type AuthenticationActionTypes = LoginRequestAction
    | LoginSuccessAction
    | LoginFailureAction
    | LogoutFailureAction
    | SetAuthenticationAction
    | SetAuthenticatedUserAction
    | ResetAuthenticationAction
    | RegistrationRequestAction
    | RegistrationSuccessAction
    | RegistrationFailureAction
    | AccountValidationRequestAction
    | AccountValidationSuccessAction
    | AccountValidationFailureAction
    | ResendActivationRequestAction
    | ResendActivationSuccessAction
    | ResendActivationFailureAction;
