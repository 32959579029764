/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { SponsorProduct } from '../../constants/sponsor';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import optionsIcon from '../../assets/images/options_icon.svg';
import OptionsMenu from './OptionsMenu';
import IconAdd from '../assets/IconAdd';

interface OwnProps {
    product?: SponsorProduct;
    isOptionOpen?: boolean;
    onEdit?: (product: SponsorProduct | null) => void;
    onOpen?: (product: SponsorProduct) => void;
    onDelete?: (product: SponsorProduct) => void;
    openOption?: () => void;
    forwardedRef?: React.ForwardedRef<HTMLDivElement>;
}

type Props = OwnProps & TranslationContext;

const ProductCard: FunctionComponent<OwnProps> = withTranslationContext(
    (props: Props) => {
        const {
            product, t, onEdit, onDelete, openOption, isOptionOpen, onOpen, forwardedRef,
        } = props;

        const renderProduct = (): React.ReactNode => {
            if (!product) return null;

            return (
                <React.Fragment>
                    <div className="product-card__header">
                        <div className="product-card__header__left">
                            <span>{product.title}</span>
                        </div>
                        <div className="product-card__header__right">
                            {
                                onEdit && onDelete && (
                                    <React.Fragment>
                                        <button type="button" onClick={openOption}>
                                            <img className="product-card__header__right__image" src={optionsIcon} alt="options" />
                                        </button>
                                        <OptionsMenu
                                            open={isOptionOpen || false}
                                            options={[
                                                { label: t('sponsors.product.edit'), onClick: (): void => onEdit(product) },
                                                { label: t('sponsors.product.delete'), onClick: (): void => onDelete(product) },
                                            ]}
                                        />
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                    <button type="button" onClick={() => onOpen?.(product)}>
                        <img src={product.imagePath.thumbnail} alt="thumbnail" />
                    </button>
                    <button type="button" onClick={() => onOpen?.(product)}>
                        <span>{product.description}</span>
                    </button>
                    <button className="btn btn--primary-inverse" type="button" onClick={() => onOpen?.(product)}>
                        {t('sponsors.product.price1')}&nbsp;<strong>{t('sponsors.product.price2', { price: product.credits })}</strong>
                    </button>
                </React.Fragment>
            );
        };

        const renderPlaceholder = (): React.ReactNode => {
            return (
                <React.Fragment>
                    <div
                        className="product-card--placeholder__container"
                        onClick={() => onEdit?.(null)}
                    >
                        <i className="product-card--placeholder__container__icon">
                            <IconAdd fill="#fff" />
                        </i>
                        <div className="product-card--placeholder__container__description">
                            <span>{t('sponsors.product.placeholder1')}</span>
                            <span>{t('sponsors.product.placeholder2')}&nbsp;<strong>+</strong>&nbsp;{t('sponsors.product.placeholder3')}</span>
                        </div>
                    </div>
                </React.Fragment>
            );
        };

        return (
            <div
                data-testid="product-card"
                ref={forwardedRef}
                className={`product-card
                    ${!product?.active ? 'product-card--inactive' : ''}
                    ${!product ? 'product-card--placeholder' : ''}
                `}
            >
                { product ? renderProduct() : renderPlaceholder() }
            </div>
        );
    },
);

ProductCard.defaultProps = {
    product: undefined,
    isOptionOpen: false,
    onEdit: undefined,
    onDelete: undefined,
    openOption: undefined,
    forwardedRef: undefined,
};

export default React.forwardRef<HTMLDivElement, OwnProps>((props, ref) => <ProductCard {...props} forwardedRef={ref} />);
