/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedWalletController, WalletController } from './WalletController';

export interface WalletContext extends Omit<InstanceType<typeof WalletController>, keyof React.Component> {
}

const WalletContextInstance = createContext<WalletContext | null>(null);

export const WalletContextProvider = WalletContextInstance.Provider;
export const WalletContextConsumer = WalletContextInstance.Consumer;

export const withWalletContext = <P, >(Component: ComponentType<P>): FC<Omit<P, keyof WalletContext>> => (props) => (
    <ConnectedWalletController>
        <WalletContextConsumer>{(ctx) => <Component {...(props as P)} {...ctx} />}</WalletContextConsumer>
    </ConnectedWalletController>
);
