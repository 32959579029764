/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode } from 'react';
import reactGa from 'react-ga4';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import Avatar from './Avatar';
import logoIcon from '../../assets/images/logo_icon_dark.svg';
import logoText from '../../assets/images/nav_logo.svg';
import marketLogoIcon from '../../assets/images/logo_icon_market.svg';
import marketLogoText from '../../assets/images/market_logo.png';
import { MatchParams } from '../../constants/misc';
import { AppRoute } from '../../constants/routes';
import { getFullName } from '../../utils/format';
import { logoutURL } from '../../services/authentication';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { UserRoles } from '../../types/user';
import close from '../../assets/images/icon_close_green.svg';
import iconBtnBurger from '../../assets/images/icon_btn_burger.svg';
import test from '../../assets/images/test.svg';
import testDark from '../../assets/images/sportest_dark.svg';
import ButtonShare, { StyleVariant } from './ButtonShare';
import { GACategories } from '../../utils/analytics';
import { SearchInput } from './SearchInput';
import { buildRoute } from '../../utils/misc';

type Props = RouteComponentProps<MatchParams> & AuthenticationContext & TranslationContext;

interface OwnState {
    showBurgerMenu: boolean;
    searchValue: string | null;
}

type State = OwnState;

const initialState: State = {
    showBurgerMenu: false,
    searchValue: null,
};

class TopBar extends Component<Props, State> {
    state = initialState;

    componentDidMount() {
        window.addEventListener('click', this.closeMenu);
    }

    componentWillUnmount(): void {
        window.removeEventListener('click', this.closeMenu);
    }

    onLogoClick = () => {
        const { history } = this.props;
        history.push(AppRoute.Index);
    };

    onBurgerMenuClick = () => {
        const { showBurgerMenu } = this.state;
        setTimeout(
            () => this.setState({
                showBurgerMenu: !showBurgerMenu,
            }),
            10,
        );
    };

    onKnowMoreClick = () => {
        const { history } = this.props;
        history.push(AppRoute.About);
    };

    onLoginClick = () => {
        const { openLogin } = this.props;
        reactGa.event({
            category: GACategories.BUTTON_CLICK,
            action: 'Login click',
        });
        openLogin();
    };

    onSignUpClick = () => {
        const { openRegistration } = this.props;
        openRegistration();
    };

    onProfileClick = () => {
        const { history } = this.props;
        history.push(this.getProfileLink(false));
    };

    onSportestClick = () => {
        const { openTestInfo } = this.props;
        openTestInfo();
    };

    onAllTeam = () => {
        const { history } = this.props;
        history.push(AppRoute.SportNetwork);
    };

    onSearchSubmit = (searchValue: string | null) => {
        this.setState({ searchValue });

        const { history } = this.props;

        history.push(buildRoute(AppRoute.SportNetworkSearch, { q: encodeURIComponent(searchValue ?? '') }));
    };

    closeMenu = () => {
        this.setState({ showBurgerMenu: false });
    };

    logout = async () => {
        const { history, cleanStore } = this.props;
        reactGa.event({
            category: GACategories.USER_LOGOUT,
            action: `logout page: ${history.location.pathname}`,
        });
        await axios.get(logoutURL());
        cleanStore(() => {
            history.push(AppRoute.Index);
        });
    };

    getProfileLink = (excludeAdmin: boolean) => {
        const { user } = this.props;
    
        switch (user?.role) {
            case UserRoles.Admin:
                return excludeAdmin ? '' : AppRoute.Admin;
            case UserRoles.Fan:
                return buildRoute(AppRoute.Fan, { id: user.id });
            case UserRoles.Sponsor:
                return buildRoute(AppRoute.Sponsor, { id: user.id });
            case UserRoles.Manager:
                return buildRoute(AppRoute.Manager, { id: user.id });
            default:
                return buildRoute(AppRoute.Athlete, { id: user?.id });
        }
    };
    
    renderNavButtons = (isSidebar = false) => {
        const { t, isAuthenticated, user } = this.props;

        const prefix = isSidebar ? 'sidebar' : 'topbar__right';

        const buttons = (
            <>
                {!isAuthenticated && (
                    <button
                        type="button"
                        className={`btn btn--primary-dark ${prefix}__sportest--before`}
                        onClick={this.onSportestClick}
                    >
                        <img src={isSidebar ? testDark : test} alt="" />
                        &nbsp;&nbsp;{t('nav.sportest')}
                    </button>
                )}
                {isAuthenticated && isSidebar && (
                    <button
                        type="button"
                        className={`btn btn--white-border ${prefix}__about`}
                        onClick={this.onProfileClick}
                    >
                        {user?.role === UserRoles.Admin ? t('nav.admin') : t('nav.profile')}
                    </button>
                )}
                <button
                    type="button"
                    className={`btn btn--white-border ${prefix}__about`}
                    onClick={this.onKnowMoreClick}
                >
                    {t('nav.about')}
                </button>
                <button type="button" className={`btn btn--white-border ${prefix}__network`} onClick={this.onAllTeam}>
                    {t('nav.network')}
                </button>
                {!isAuthenticated && (
                    <>
                        <button
                            type="button"
                            className={`btn btn--white-border ${prefix}__signup`}
                            onClick={this.onSignUpClick}
                        >
                            {t('nav.signup')}
                        </button>
                        <button
                            type="button"
                            className={`btn btn--white-border ${prefix}__login`}
                            onClick={this.onLoginClick}
                        >
                            {t('nav.login')}
                        </button>
                        <button
                            type="button"
                            className={`btn btn--primary-dark ${prefix}__sportest`}
                            onClick={this.onSportestClick}
                        >
                            <img src={isSidebar ? testDark : test} alt="" />
                            &nbsp;&nbsp;{t('nav.sportest')}
                        </button>
                    </>
                )}
                {isAuthenticated && isSidebar && (
                    <div className="small-buttons-container">
                        <ButtonShare variant={StyleVariant.WHITE} linkURL={this.getProfileLink(true)} />
                    </div>
                )}
            </>
        );

        if (isSidebar) {
            return (
                <>
                    <div />
                    <div className="sidebar__buttons__middle">{buttons}</div>
                    {isAuthenticated && (
                        <span className="sidebar__logout" onClick={this.logout}>
                            {t('nav.logout')}
                        </span>
                    )}
                </>
            );
        }

        return buttons;
    };

    renderUser = (): ReactNode => {
        const { user, isAuthenticated } = this.props;

        if (!isAuthenticated || !user) return null;

        const fullName = getFullName(user.name, user.lastName);

        return (
            <div className="user" onClick={this.onBurgerMenuClick}>
                <span>{fullName}</span>
                <Avatar image={user?.avatar?.main} small testId="user-avatar" />
            </div>
        );
    };

    renderSidebarUser = (): ReactNode => {
        const { user, isAuthenticated } = this.props;

        if (!isAuthenticated || !user) return null;

        const fullName = getFullName(user?.name, user?.lastName);

        return (
            <div className="sidebar__user">
                <Avatar image={user?.avatar?.main} testId="user-avatar" />
                <span>{fullName}</span>
            </div>
        );
    };

    renderSideBar = (): ReactNode => {
        const { showBurgerMenu } = this.state;
        const { isAuthenticated, t } = this.props;

        const classes = ['sidebar'];
        if (showBurgerMenu) {
            classes.push('sidebar--open');
        }
        if (isAuthenticated) {
            classes.push('sidebar--auth');
        }

        return (
            <div aria-label={t('nav.sidebar')} className={classes.join(' ')}>
                <img alt="" src={close} className="sidebar__close" onClick={this.onBurgerMenuClick} />
                {this.renderSidebarUser()}
                <div className={`sidebar__buttons ${isAuthenticated ? '' : 'logout'}`}>{this.renderNavButtons(true)}</div>
            </div>
        );
    };

    render() {
        const {
            isAuthenticated, history, t,
        } = this.props;

        const { searchValue } = this.state;

        const isInMarketScreen = history.location.pathname.startsWith('/market');

        return (
            <div className="topbar">
                <button type="button" data-testid="home-button" onClick={this.onLogoClick}>
                    <img src={isInMarketScreen ? marketLogoIcon : logoIcon} alt="" className="logo-short" />
                    <img src={isInMarketScreen ? marketLogoText : logoText} alt="" className="logo" />
                </button>
                <div aria-label={t('search.searchBox')} className="topbar__search">
                    <SearchInput
                        onSearchSubmit={this.onSearchSubmit}
                        searchValue={searchValue}
                    />
                </div>
                <div className="topbar__right">
                    {this.renderNavButtons()}
                    <button
                        type="button"
                        className={`topbar__right__burger${isAuthenticated ? ' topbar__right__burger--auth' : ''}`}
                        onClick={this.onBurgerMenuClick}
                        data-testid="burger-menu-toggle"
                    >
                        <img src={iconBtnBurger} alt="" />
                    </button>
                    {this.renderSideBar()}
                    {this.renderUser()}
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withRouter(TopBar)));
