/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

interface OwnProps {
    fill: string;
}

type Props = OwnProps;

export const IconRightArrow: FunctionComponent<Props> = (props: Props) => {
    const { fill } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 0 30 30"
            width="20"
        >
            <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" fill={fill} /><path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
};
