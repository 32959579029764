/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

/**
 * @typedef {Object} OwnProps
 * @property {string} [fill]
 */
interface OwnProps {
    fill?: string;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * icon svg
 * @param {Props} props
 * @extends {FunctionComponent<Props>}
 */
export const IconWWW: FunctionComponent<Props> = (props: Props) => {
    const { fill } = props;

    return (
        <svg fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 209.281 209.281">
            <path d="M203.456 139.065c3.768-10.786 5.824-22.369 5.824-34.425s-2.056-23.639-5.824-34.425a7.535 7.535 0 00-.333-.944C188.589 28.926 149.932 0 104.641 0S20.692 28.926 6.159 69.271c-.132.305-.242.62-.333.944C2.058 81.001.002 92.584.002 104.64s2.056 23.639 5.824 34.425c.092.324.201.64.333.944 14.534 40.346 53.191 69.271 98.482 69.271s83.948-28.926 98.482-69.271a7.48 7.48 0 00.333-.944zm-98.815 55.216c-3.985 0-10.41-7.212-15.78-23.324-2.592-7.775-4.667-16.713-6.179-26.436H126.6c-1.512 9.723-3.587 18.66-6.178 26.436-5.371 16.112-11.796 23.324-15.781 23.324zm-23.779-64.76c-.721-7.998-1.102-16.342-1.102-24.881s.381-16.883 1.102-24.881h47.557c.721 7.998 1.102 16.342 1.102 24.881s-.381 16.883-1.102 24.881H80.862zm-65.861-24.88c0-8.63 1.229-16.978 3.516-24.881h47.3c-.701 8.163-1.057 16.529-1.057 24.881s.355 16.718 1.057 24.881h-47.3a89.386 89.386 0 01-3.516-24.881zM104.641 15c3.985 0 10.411 7.212 15.781 23.324 2.591 7.775 4.667 16.713 6.178 26.435H82.681c1.512-9.723 3.587-18.66 6.179-26.435C94.231 22.212 100.656 15 104.641 15zm38.823 64.76h47.3c2.287 7.903 3.516 16.251 3.516 24.881s-1.229 16.978-3.516 24.881h-47.3c.701-8.163 1.057-16.529 1.057-24.881s-.356-16.718-1.057-24.881zm41.439-15h-43.16c-2.668-18.397-7.245-34.902-13.666-46.644 24.895 6.749 45.52 23.98 56.826 46.644zM81.204 18.115C74.783 29.857 70.206 46.362 67.538 64.76h-43.16c11.307-22.664 31.931-39.895 56.826-46.645zM24.378 144.521h43.16c2.668 18.397 7.245 34.902 13.666 46.645-24.895-6.75-45.519-23.98-56.826-46.645zm103.699 46.645c6.421-11.742 10.998-28.247 13.666-46.645h43.16c-11.306 22.665-31.931 39.895-56.826 46.645z" />
        </svg>

    );
};
