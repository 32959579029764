/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { Sponsor, User } from '../../types/user';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import ScrollWrapper from './ScrollWrapper';
import ButtonContact from './ButtonContact';
import ButtonShare from './ButtonShare';
import UserWallet from './UserWallet';

interface OwnProps extends TranslationContext {
    onEditClick: () => void;
    linkToShare: string;
    user: User | Sponsor | null;
    userOwner: boolean;
}

const ProfileButtons: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, onEditClick, linkToShare, user, userOwner,
    } = props;
    
    return (
        <ScrollWrapper>
            {(isScrolling) => (
                <div className={`buttons ${isScrolling ? ' buttons--scroll' : ''}`} data-testid={isScrolling ? ' buttons-scroll' : 'buttons'}>
                    { userOwner && (
                        <>
                            <button type="button" className="btn btn--primary-inverse" onClick={onEditClick}>
                                {t('nav.editProfile')}
                            </button>
                            <UserWallet userPageOwner={user} />
                        </>
                    )}
                    <ButtonContact userPageOwner={user} />
                    <ButtonShare linkURL={linkToShare} />
                </div>
            )}
        </ScrollWrapper>
    );
};

export default withTranslationContext(ProfileButtons);
