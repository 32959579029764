/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

import {
    TranslationContext,
    withTranslationContext,
} from '../controllers/translation/TranslationContext';
import { ErrorCode } from '../../constants/errors';
import { FormValidatorError, FormValidatorErrorType } from '../../utils/validations';

enum InputErrorType {
    Field = 'FIELD',
    Logic = 'LOGIC',
}

interface OwnProps extends TranslationContext {
    type?: InputErrorType;
    field?: string;
    errors: FormValidatorError[] | null | undefined;
    showAll?: boolean;
    extraClass?: string;
}

type Props = OwnProps;

const FormFieldError: FunctionComponent<Props> = (
    {
        t,
        errors = [],
        field = '',
        type = InputErrorType.Field,
        showAll = false,
        extraClass = '',
    }: Props,
) => {
    /**
     * returns an error message for the error code
     * @param {FormValidatorError} error
     */
    const getGeneralErrorMessage = (error: FormValidatorError) => {
        const {
            errorCode,
        } = error;

        let message = '';
        if (errorCode !== null && errorCode !== undefined) {
            message = t(`errors.${ErrorCode[errorCode]}`);
        }

        return message;
    };

    /**
     * return an error message for the type of violation
     * @param {FormValidatorError} error
     */
    const getFieldErrorMessage = (error: FormValidatorError) => {
        const {
            min,
            max,
            size,
            typeOfViolation,
        } = error;

        let message = '';
        switch (typeOfViolation) {
            case FormValidatorErrorType.Size:
                if (min && max) {
                    message = t('errors.Size', { min, max });
                } else if (min) {
                    message = t('errors.SizeMin', { min });
                } else if (max) {
                    message = t('errors.SizeMax', { max });
                }
                break;
            case FormValidatorErrorType.SizeExact:
                if (size) {
                    message = t('errors.SizeExact', { size });
                }
                break;
            case FormValidatorErrorType.Max:
                if (max) {
                    message = t('errors.Max', { max });
                }
                break;
            case FormValidatorErrorType.Min:
                if (min) {
                    message = t('errors.Min', { min });
                }
                break;
            default:
                message = t(`errors.${typeOfViolation}`, { max, min });
                break;
        }
        return message;
    };

    /**
     * returns an error message
     * @param {FormValidatorError} error
     */
    const getErrorMessage = (error: FormValidatorError) => {
        let message = '';

        switch (type) {
            case InputErrorType.Field:
                message = getFieldErrorMessage(error);
                break;
            case InputErrorType.Logic:
                message = getGeneralErrorMessage(error);
                break;
            default:
                break;
        }

        return message;
    };

    if (!errors || errors.length < 1) return null;
    if (!showAll) {
        const [error] = errors;
        return (
            <span
                data-field={field}
                className={`field-error ${extraClass}`}
            >
                {getErrorMessage(error)}
            </span>
        );
    }

    return (
        <div>
            {
                errors.map((error) => {
                    return (
                        <span
                            data-field={field}
                            className={`field-error ${extraClass}`}
                            key={`${error.errorCode} - ${error.size} - ${error.typeOfViolation}`}
                        >
                            {getErrorMessage(error)}
                        </span>
                    );
                })
            }
        </div>
    );
};

export default withTranslationContext(FormFieldError);
