/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

import iconEnvelope from '../../assets/images/icon_btn_contact.svg';
import iconEnvelopeGreen from '../../assets/images/icon_btn_contact_green.svg';
import { User } from '../../types/user';
import { withTranslationContext, TranslationContext } from '../controllers/translation/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import ContactModal from './ContactModal';
import { displayError } from '../../utils/notifications';

interface OwnProps {
    white?: boolean;
    userPageOwner: User | null;
    hideText?: boolean;
}

type Props = OwnProps & TranslationContext & AuthenticationContext;

const ButtonContact: FunctionComponent<Props> = ({
    white,
    user,
    isAuthenticated,
    userPageOwner,
    t,
    hideText,
}: Props) => {
    const [showContactModal, setShowContactModal] = React.useState(false);

    /**
     * handles button click
     * @param {React.MouseEvent} e
     */
    const onButtonClick = (e: React.MouseEvent) => {
        if (isAuthenticated) {
            setShowContactModal(true);
        } else {
            displayError({
                message: t('sendMessage.mustBeLogged'),
            });
        }
    };

    return (
        <>
            {user?.id !== userPageOwner?.id && (
                <button type="button" className={`contact${white ? ' contact--white' : ''}`} onClick={onButtonClick} data-testid="contact-button">
                    <img src={white ? iconEnvelopeGreen : iconEnvelope} alt="" />
                    {!hideText && <p>{t('sendMessage.label')}</p>}
                </button>
            )}
            {showContactModal && (
                <ContactModal
                    userPageOwner={userPageOwner}
                    onClose={(): void => setShowContactModal(false)}
                />
            )}
        </>
    );
};

export default withAuthenticationContext(withTranslationContext(ButtonContact));

ButtonContact.defaultProps = {
    white: undefined,
};
