/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { Preferences } from '../constants/preferences';

/**
 * PreferencesAction
 * @enum {string}
 */
export enum PreferencesAction {
    SET_LANGUAGE = 'SET_LANGUAGE',
    SET_THEME = 'SET_THEME',
    PREFERENCES_REQUEST = 'PREFERENCES_REQUEST',
    PREFERENCES_SUCCESS = 'PREFERENCES_SUCCESS',
    PREFERENCES_FAILURE = 'PREFERENCES_FAILURE',
    PREFERENCES_CHANGE_REQUEST = 'PREFERENCES_CHANGE_REQUEST',
    PREFERENCES_CHANGE_SUCCESS = 'PREFERENCES_CHANGE_SUCCESS',
    PREFERENCES_CHANGE_FAILURE = 'PREFERENCES_CHANGE_FAILURE',
}

/**
 * @typedef {Object} SetLanguageAction
 * @property {string} type
 * @property {string} payload
 */
export interface SetLanguageAction {
    type: typeof PreferencesAction.SET_LANGUAGE;
    payload: string;
}

/**
 * @typedef {Object} SetThemeAction
 * @property {string} type
 * @property {string} payload
 */
export interface SetThemeAction {
    type: typeof PreferencesAction.SET_THEME;
    payload: string;
}

/**
 * @typedef {Object} PreferencesRequestAction
 * @property {string} type
 */
export interface PreferencesRequestAction {
    type: typeof PreferencesAction.PREFERENCES_REQUEST;
}

/**
 * @typedef {Object} PreferencesSuccessAction
 * @property {string} type
 * @property {Preferences} payload
 */
export interface PreferencesSuccessAction {
    type: typeof PreferencesAction.PREFERENCES_SUCCESS;
    payload: Preferences;
}

/**
 * @typedef {Object} PreferencesFailureAction
 * @property {string} type
 * @property {object} payload
 */
export interface PreferencesFailureAction {
    type: typeof PreferencesAction.PREFERENCES_FAILURE;
    payload: object;
}

/**
 * @typedef {Object} PreferencesChangeRequestAction
 * @property {string} type
 */
export interface PreferencesChangeRequestAction {
    type: typeof PreferencesAction.PREFERENCES_CHANGE_REQUEST;
}

/**
 * @typedef {Object} PreferencesChangeSuccessAction
 * @property {string} type
 * @property {UserPreferencesStatePrefs} payload
 */
export interface PreferencesChangeSuccessAction {
    type: typeof PreferencesAction.PREFERENCES_CHANGE_SUCCESS;
    payload: Preferences;
}

/**
 * @typedef {Object} PreferencesChangeFailureAction
 * @property {string} type
 * @property {object} payload
 */
export interface PreferencesChangeFailureAction {
    type: typeof PreferencesAction.PREFERENCES_CHANGE_FAILURE;
    payload: object;
}

export type PreferencesActionTypes = SetLanguageAction
    | SetThemeAction
    | PreferencesRequestAction
    | PreferencesSuccessAction
    | PreferencesFailureAction
    | PreferencesChangeRequestAction
    | PreferencesChangeSuccessAction
    | PreferencesChangeFailureAction;
