/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */
import { WSClient } from '@void/utils/websockets/index';
import { SupportUpdate, WalletUpdate } from '../types/websockets';

export type Receive = SupportUpdate | WalletUpdate;
export type Send = SupportUpdate | WalletUpdate;

export const WS = WSClient<Receive, Send, 'type'>();

export type websocketType = typeof WS;
