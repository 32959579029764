/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
import { SportestState } from './types';
import { SportestAction } from '../actions/sportest_types';

export const sportestInitialState: SportestState = {
    pendingSportests: [],
    isFetching: false,
};

export default function (state = sportestInitialState, action: any): SportestState {
    switch (action.type) {
        case SportestAction.ADD_SPORTEST:
            return {
                ...state,
                pendingSportests: [...state.pendingSportests, action.payload],
            };
        case SportestAction.CLEAR_SPORTESTS:
            return {
                ...state,
                pendingSportests: [],
            };
        case SportestAction.CREATE_SPORTEST_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case SportestAction.CREATE_SPORTEST_SUCCESS:
        case SportestAction.CREATE_SPORTEST_FAILURE:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
}
